
import { getLBData, getLBCrewData } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_LEADERLIST = 'Crew/LeaderList'
const ENDPOINT_CREWAPPLYINFO = 'Crew/CrewApplyInfo'
const ENDPOINT_CREWLOG = 'SetCrewLog'

export default {
  namespaced: true,
  state: {
    log: '',
    crewLeaderList: null
  },
  actions: {
    // 리더목록 조회
    async getLeaderList ({ state }, crewStartDate) {
      const resp = await getLBData(ENDPOINT_LEADERLIST, 'get', {
        crewStartDate: crewStartDate
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log(resp.data + state.log)
        state.crewLeaderList = resp.data.crewLeaderList
      }
    },
    // 크루 신청 페이지 데이터 조회
    async getCrewApplyInfo ({ state }, crewStartDate) {
      const resp = await getLBData(ENDPOINT_CREWAPPLYINFO, 'post', {
        crewStartDate: crewStartDate,
        uId: cookies.get('memberCummunityUID')
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log(resp.data + state.log)
        state.crewLeaderList = resp.data.crewLeaderList
      }

      return resp.data
    },
    // 크루 모집 오픈 알림 신청
    async sendUserInfoForCrewOpenNoti ({ state }, mobileNo) {
      const resp = await getLBCrewData(ENDPOINT_CREWLOG, 'post', {
        UIDList: cookies.get('memberCummunityUID'),
        LogCode: 'CrewFilterNotificationClick',
        MarketingLogData: JSON.stringify({
          UserSelectedCodeArrayString: '',
          LoginID: mobileNo,
          UID: cookies.get('memberCummunityUID'),
          Location: 'crewapplyapp',
          UserNumber: 'empty'
        })
      })
      if (resp.data !== undefined && resp.data !== null) {
        console.log(resp.data + state.log)
      }

      return resp.data
    },
  }
}
