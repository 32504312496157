// config for local dev

import alphaConfig from './alpha'

export default {
  ...alphaConfig,
  paycoAuthorizeUrl: 'https://id.payco.com/oauth2.0/authorize',
  paycoAuthorizeJoinUrl: 'https://id.payco.com/join.nhn',
  paycoClientIdForIOS: 'tr0j040Ns2LlTvmS39rY',
  paycoClientIdForAOS: 'PkRa9CYXfTlEZfB_avZe',
  paycoClientIdForWEB: 'OxwGnaYSbcaRgC2PWLCN',
  paycoCertifyLogin: 'https://id.payco.com/certifyLogin.nhn',
  paycoMyinfo: 'https://id.payco.com/myinfo.nhn',
  paycoChangPwd: 'https://id.payco.com/myInfoChangePwd.nhn',
  oldPingdamall: 'http://old.buywow.co.kr',
  oldPdmall: 'http://old.pdmall.buywow.co.kr',
  //  paycoAuthorizeUrl: 'https://alpha-id.payco.com/oauth2.0/authorize',
  //  paycoAuthorizeJoinUrl: 'https://alpha-id.payco.com/join.nhn',
  //  paycoClientIdForIOS: 'ALPY5IpR2zDA6ILOgiLg',
  //  paycoClientIdForAOS: 'ALP2lVFWwNLUjamvfrN_',
  //  paycoClientIdForWEB: 'ALPGnaYSbcaRgC2PWLCN',
  //  paycoCertifyLogin: 'https://alpha-id.payco.com/certifyLogin.nhn',
  //  paycoMyinfo: 'https://alpha-id.payco.com/myinfo.nhn',
  //  paycoChangPwd: 'https://alpha-id.payco.com/myInfoChangePwd.nhn',
  //  oldPingdamall: 'http://alpha-old.buywow.co.kr',
  //  oldPdmall: 'http://alpha-old.pdmall.buywow.co.kr',
  // payPlusJs: 'https://testpay.kcp.co.kr/plugin/payplus_web.jsp', --KCP
  homeBannerCode: '000',
  homeMagicBannerCode: '027',
  homeSectionNo: '20680',
  todaySectionNo: '12',
  //  homeSectionNo: '470',
  //  todaySectionNo: '39',
  brandBannerCode: '003',
  eventTabBannerCode: '004',
  categoryBannerCode: '002',
  myPageTopBannerCode: '005',
  myPageBottomBannerCode: '006',
  faqBoardNo: '57044',
  //  faqBoardNo: '1559',
  kakaoKey: '73a5703cca8f219fd86af33088e00b44',
  navCagetories: ['79761', '79762', '79769', '79770']
  //  kakaoKey: 'bb41bb7c8befb11a9422c4085764df1e',
  //  navCagetories: ['27703', '27728', '27729', '27713']
}
