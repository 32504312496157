import store, { loadNCPSearchScript } from '@/store'
import { isPCDevice, logoutRemoveCookie, removeClass, hasAccessToken, hasLastAccessToken, getAccessToken, scriptLoader, saveUserBaseInfo } from '@/utils/utils'
import cookies from 'js-cookie'
/* global NCPSearch */

export function appLogin (to, from, next) {
  if (process.env.BUILD_TARGET === 'webview') {
    if (!hasAccessToken()) {
      logoutRemoveCookie()
      next()
    } else {
      if (cookies.get('ncpAccessToken') === undefined || cookies.get('ncpAccessToken').length === 0) {
        const nxturl = cookies.get('nxturl')
        let url = '/'
        if (nxturl && nxturl.length > 0) {
          url = decodeURIComponent(nxturl)
        }
        cookies.remove('nxturl')
        store.dispatch('paycoTokenLoginForAPP', { nxturl: url })
      } else {
        if (to.name === 'Login' || to.name === 'TokenError') {
          next()
        } else if (getAccessToken() === cookies.get('oldAccessToken')) {
          next()
        } else {
          store.dispatch('authentication/paycoToken2AccessToken', { accessToken: getAccessToken() }).then(() => {
            store.dispatch('profile/memberFetch').then(() => {
              store.dispatch('authentication/fetchShopAgreement').then(() => {
                next()
              })
            })
          })
        }
      }
    }
  } else {
    next()
  }
}

export function setTrackingKey (to, from, next) {
  if (to.query.trackingKey) {
    cookies.set('trackingKey', to.query.trackingKey)
  }
  next()
}

export function auth (to, from, next) {
  // query string 기본 정보 저장
  saveUserBaseInfo(to)
  if (to.matched.some(record => record.meta.needAuth)) {
    if (!hasAccessToken() && hasLastAccessToken() && !to.query.uId) {
      store.dispatch('lbAuthentication/renewalMallToken', {
        mallAccessToken: cookies.get('ncpLastAccessToken'),
        uId: cookies.get('memberCummunityUID'),
        appType: cookies.get('appType'),
        appVersion: cookies.get('appVersion')
      }).then(async (response) => {
        if (response.status === 200) {
          next()
        } else {
          next({
            path: '/member/login',
            query: {
              redirect: to.fullPath
            }
          })
        }
      }).catch(e => {
        console.log('' + e)
        next({
          path: '/member/login',
          query: {
            redirect: to.fullPath
          }
        })
      })
    } else if (!hasAccessToken()) {
      if (to.query.uId) {
        store.dispatch('lbAuthentication/getNCPAccessToken', {
          uId: to.query.uId,
          appType: to.query.appType,
          appVersion: to.query.appVersion
        }).then(async (response) => {
          if (response.status === 200) {
            // 여기 로그인 로그
            await store.dispatch('profile/memberFetch')
            next()

            // 로그인 로그
            let logCode = 'MarketLogin'
            let marketingLogData = JSON.stringify({
                marketLogCode: logCode,
                marketLogValue: {
                  uId: to.query.uId,
                  appType: to.query.appType,
                  appVersion: to.query.appVersion
                }
            })
            store.dispatch('lbMarketingLog/setMarketingLog', { marketingLogData: marketingLogData, logCode: logCode })
          } else {
            next({
              path: '/member/login',
              query: {
                redirect: to.fullPath
              }
            })
          }
        }).catch(e => {
          console.log('' + e)
          next({
            path: '/member/login',
            query: {
              redirect: to.fullPath
            }
          })
        })
      } else {
        next({
          path: '/member/login',
          query: {
            redirect: to.fullPath
          }
        })
      }
    } else if (!cookies.get('memberStatus')) {
      store.dispatch('profile/memberFetch').then(() => {
        if (cookies.get('memberStatus') !== 'ACTIVE') {
          next({
            path: '/member/agreement',
            query: {
              nxturl: to.fullPath
            }
          })
        } else {
          next()
        }
      })
    } else if (cookies.get('memberStatus') && cookies.get('memberStatus') !== 'ACTIVE') {
      next({
        path: '/member/agreement',
        query: {
          nxturl: to.fullPath
        }
      })
    } else {
      next()
    }
  } else {
    next()
  }
}

export function dispatchAction (to, from, next) {
  next()
  if (document.getElementById('loader')) {
    document.getElementById('loader').style.display = 'none'
    removeClass(document.body, 'no_scroll')
    removeClass(document.body, 'floating_bottom')
  }
  if (to.meta.action) {
    store.dispatch(`beforeEnter${to.name}`, { to, from })
  }
}

export function resetSearchWordInfo (to, from, next) {
  const showLog = ['MainHome', 'Cart', 'Event', 'LikeProducts']
  if (showLog.some(item => item === to.name)) {
    loadNCPSearchScript().then(() => {
      NCPSearch.resetSearchWordInfo()
    })
  }
}

export function mobilewebCheck (to, from, next) {
  if (process.env.BUILD_TARGET === 'webview' && !cookies.get('appVersion')) {
    alert('모바일 전용페이지로 이동합니다')
    if (process.env.BUILD_ENV === 'dev' || process.env.BUILD_ENV === 'alpha') {
      window.location.href = `${location.protocol}//alpha-m.buywow.co.kr`
    } else {
      window.location.href = `${location.protocol}//m.buywow.co.kr`
    }
  } else {
    next()
  }
}

export function PCwebCheck (to, from, next) {
  const pcalert = ['/', '/member/login', '/member/register/complete']
  if (isPCDevice()) {
    if (pcalert.some(item => item === location.pathname)) {
      alert('모바일 전용쇼핑몰이므로, PC버전은 지원하지 않습니다.')
      next()
    } else {
      next()
    }
  } else {
    next()
  }
}

export function fetchProfile (to, from) {
  if (cookies.get('ncpAccessToken') && cookies.get('ncpAccessToken').length > 0) {
    store.dispatch('profile/memberFetch', { isNeedCheckExist: true })
  }
}

export function loadTGScript (to, from) {
  if(to.name === 'MainHome'||to.name === 'Login'){
    window.wptg_tagscript_vars = window.wptg_tagscript_vars || []
    if (window.wptg_tagscript_vars && window.wptg_tagscript_vars.length === 0) {
      window.wptg_tagscript_vars.push(function () {
        if (to.name === 'MainHome') {
          return {
            wp_hcuid: '', /* 고객넘버 등 Unique ID (ex. 로그인  ID, 고객넘버 등 )를 암호화하여 대입.  *주의 : 로그인 하지 않은 사용자는 어떠한 값도 대입하지 않습니다. */
            ti: '46686', /* 광고주 코드 */
            ty: 'Home', /* 트래킹태그 타입 */
            device: 'mobile' /* 디바이스 종류  (web 또는  mobile) */
          }
        }
        if (to.name === 'Login') {
          return {
            wp_hcuid: '', /* 고객넘버 등 Unique ID (ex. 로그인  ID, 고객넘버 등 )를 암호화하여 대입.  *주의 : 로그인 하지 않은 사용자는 어떠한 값도 대입하지 않습니다. */
            ti: '46686', /* 광고주 코드 */
            ty: 'Login', /* 트래킹태그 타입 */
            device: 'mobile', /* 디바이스 종류  (web 또는  mobile) */
            items: [{
              i: '로그인',
              t: '로그인',
              p: '1',
              q: '1'
            }]
          }
        }
      })
    }
    scriptLoader('//cdn-aitg.widerplanet.com/js/wp_astg_4.0.js').then(() => {
      if (window.wptg_tagscript_history) {
        window.wptg_tagscript_history.history_peak = {}
        window.wptg_tagscript_history.history_ty = {}
      }
      if (window.wptg_tagscript) {
        window.wptg_tagscript.exec()
      }
    })
  }
}

// export function loadMoreScript () {
//   scriptLoader('//cro.myshp.us/resources/common/js/more-common.js').then(() => {
//     var _croID = '5d5a2903e4b06f573b3d63c0';
//     window.getMoreRecommend = function getMoreRecommend(e,n){"undefined"!=typeof globalCRO?new globalCRO.MoreRecommendData(e,n): ("undefined"==typeof gCroRCData&&(window.gCroRCData=new Array),gCroRCData.push({config:e,callback:n}))}
//     window.createMoreRCView = function createMoreRCView(e){"undefined"!=typeof globalCRO?new globalCRO.MoreRecommendView(e): ("undefined"==typeof gCroRCV&&(window.gCroRCV=new Array),gCroRCV.push({config:e}))}
//     window.mcroPushEvent = function mcroPushEvent(n){"undefined"!=typeof globalCRO?globalCRO.sendEvent(n): ("undefined"==typeof gCro&&(window.gCro=new Array),gCro.push(n))}
//     window._cro_initialize = function _cro_initialize(){window.globalCRO=new MCro,globalCRO.jsInit(_croID)}
//   })
// }

// export function appendMeta (to, from, next) {
//   if (to.meta.content) {
//     var meta = document.createElement('meta')
//     meta.setAttribute('name', 'more_page_type')
//     meta.setAttribute('content', to.meta.content)
//     var moreFlag = false
//     var oldMeta = null
//     var metas = document.getElementsByTagName('meta')
//     for (var i = 0; i < metas.length; i++) {
//       if (metas[i].getAttribute('name') === 'more_page_type') {
//         moreFlag = true
//         oldMeta = metas[i]
//       }
//       if (metas[i].getAttribute('name') === 'more_detail_pid') {
//         document.getElementsByTagName('head')[0].removeChild(metas[i])
//       }
//     }
//     if (moreFlag) {
//       document.getElementsByTagName('head')[0].replaceChild(meta, oldMeta)
//     } else {
//       document.getElementsByTagName('head')[0].appendChild(meta)
//     }
    
//     if (to.meta.content === 'detail') {
//       var meta2 = document.createElement('meta')
//       meta2.setAttribute('name', 'more_detail_pid')
//       meta2.setAttribute('content', to.params.productId)
//       document.getElementsByTagName('head')[0].appendChild(meta2)
//     }
//   }
//   next()
// }