import { createNcpApiStore, getLBData } from '@/api'
import { formatCurrency, getshowOptions } from '@/utils/StringUtils'
import qs from 'qs'
import router from '@/router'
import cookies from 'js-cookie'
import config from '@/config'
import { loadTGScript, commonAppCall } from '@/utils/utils'
import { calBuyConfirmReward } from '@/utils/reward'

const apiStore = createNcpApiStore([
  {
    action: '_getCart',
    property: 'cart',
    path: 'cart'
  },
  {
    action: '_getGuestCart',
    property: 'cart',
    path: 'guest/cart',
    method: 'post'
  },
  {
    action: '_updateCart',
    property: 'result',
    path: 'cart',
    method: 'put'
  },
  {
    action: '_addCart',
    property: 'addcount',
    path: 'cart',
    method: 'post'
  },
  {
    action: '_countCart',
    property: 'count',
    path: 'cart/count'
  },
  {
    action: '_deleteCart',
    property: 'delcount',
    path: 'cart',
    method: 'delete',
    requestConfig: {
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
    }
  },
  {
    action: '_calculate',
    property: 'calculate',
    path: 'cart/calculate',
    method: 'get',
    requestConfig: {
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
    }
  },
  {
    action: '_calcGuestCart',
    property: 'calculate',
    path: 'guest/cart',
    method: 'post'
  }
])

export default {
  namespaced: true,
  state: {
    totalItemPrice: 0,
    totalDiscountAmount: 0,
    totalShippingCost: 0,
    paymentAmount: 0,
    checkOptions: [],
    invalidCount: 0,
    initFlg: true
  },
  mixins: [apiStore],
  actions: {
    async addToCart ({ state, dispatch, commit, rootState, rootGetters }, carts) {
      let ret = true
      if (rootGetters.isLogined && cookies.get('memberStatus') !== 'WAITING') {
        await dispatch('_addCart', { data: carts }).catch(e => {
          if (e.data.code === 'PPE0001') {
            ret = false
            router.go(0)
          }
        })
      } else {
        const preItems = JSON.parse(window.localStorage.cartInfo || '[]')
        preItems.push(...carts)
        window.localStorage.cartInfo = JSON.stringify(preItems)
      }
      await dispatch('fetchCartCount')
      return ret
    },
    async fetchCart ({ state, commit, dispatch, rootState, rootGetters }) {
      if (rootGetters.isLogined && cookies.get('memberStatus') !== 'WAITING') {
        await dispatch('_getCart').then((res) => {
          let cartArry = []
          state.cart.deliveryGroups.forEach((deliveryItem) => {
            deliveryItem.orderProducts.forEach((productItem) => {
              cartArry.push(productItem)
            })
            cartArry.push()
          })
          loadTGScript(cartArry, 'cart')

          dispatch('updateCartInfoToDB')
        })
      } else {
        const items = JSON.parse(window.localStorage.cartInfo || '[]')
        if (items.length > 0) {
          await dispatch('_getGuestCart', { data: items }).then((res) => {
            let cartArry = []
            state.cart.deliveryGroups.forEach((deliveryItem) => {
              deliveryItem.orderProducts.forEach((productItem) => {
                cartArry.push(productItem)
              })
              cartArry.push()
            })
            loadTGScript(cartArry, 'cart')
          })
        } else {
          state.cart = {}
        }
      }
      await commit('FORMAT_DATAS', { isLogined: (rootGetters.isLogined && cookies.get('memberStatus') !== 'WAITING') })
      if (state.initFlg) {
        state.initFlg = false
        await commit('CHECK_ALL', true)
      }
      await dispatch('calculate')
    },
    fetchCartCount ({ state, commit, dispatch, rootGetters, rootState }) {
      if (rootGetters.isLogined && cookies.get('memberStatus') !== 'WAITING') {
        dispatch('_countCart').then(async () => {
          // 앱에 장바구니 숫자 전달
          commit('LANDING_UPDATE_CART')
        })
      } else {
        const items = JSON.parse(window.localStorage.cartInfo || '[]')
        if (items.length > 0) {
          state.count = { count: items.length }
        } else {
          state.count = 0
        }
      }
    },
    async deleteCartByOptions ({ state, dispatch, commit, rootState, rootGetters }, options) {
      if (rootGetters.isLogined && cookies.get('memberStatus') !== 'WAITING') {
        const cartNo = options.map(option => option.cartNo)
        await dispatch('_deleteCart', { params: { cartNo } })
      } else {
        const items = JSON.parse(window.localStorage.cartInfo || '[]')
        window.localStorage.cartInfo = JSON.stringify(
          items.filter(item => {
            return !options.some(option => option.cartNo === item.cartNo)
          })
        )
      }
      options.forEach(option => {
        option.checked = false
        commit('CHECK_OPTION', option)
      })
      dispatch('fetchCart')
      dispatch('fetchCartCount')
    },
    async deleteCartByCartNos ({ state, dispatch, commit, rootState, rootGetters }, cartNo) {
      if (rootGetters.isLogined && cookies.get('memberStatus') !== 'WAITING') {
        await dispatch('_deleteCart', { params: { cartNo } })
      } else {
        const items = JSON.parse(window.localStorage.cartInfo || '[]')
        window.localStorage.cartInfo = JSON.stringify(
          items.filter(item => {
            return !cartNo.some(cartNo => cartNo === item.cartNo)
          })
        )
      }
      dispatch('fetchCart')
      dispatch('fetchCartCount')
    },
    async putCarts ({ state, dispatch, commit, rootState, rootGetters }, carts) {
      if (rootGetters.isLogined && cookies.get('memberStatus') !== 'WAITING') {
        await dispatch('_updateCart', { data: carts })
      } else {
        const items = JSON.parse(window.localStorage.cartInfo || '[]')
        items.forEach(item => {
          carts.forEach(option => {
            if (item.cartNo === option.cartNo) {
              item.orderCnt = option.orderCnt
            }
          })
        })

        window.localStorage.cartInfo = JSON.stringify(items)
      }
      await dispatch('fetchCart')
    },
    async calculate ({ state, commit, dispatch, rootState, rootGetters }) {
      if (state.checkOptions.length > 0) {
        if (rootGetters.isLogined && cookies.get('memberStatus') !== 'WAITING') {
          const cartNo = state.checkOptions.map(option => option.cartNo)
          await dispatch('_calculate', { params: { cartNo } })
          if (state.calculate) {
            state.totalItemPrice = state.calculate.standardAmt
            state.totalDiscountAmount = state.calculate.discountAmt
            state.totalShippingCost = state.calculate.totalPrePaidDeliveryAmt
            state.paymentAmount = state.calculate.totalAmt
          }
        } else {
          await dispatch('_calcGuestCart', { data: state.checkOptions })
          if (state.calculate) {
            state.totalItemPrice = state.calculate.price.standardAmt
            state.totalDiscountAmount = state.calculate.price.discountAmt
            state.totalShippingCost = state.calculate.price.totalPrePaidDeliveryAmt
            state.paymentAmount = state.calculate.price.totalAmt
          }
        }
      } else {
        state.totalItemPrice = 0
        state.totalDiscountAmount = 0
        state.totalShippingCost = 0
        state.paymentAmount = 0
      }
    },
    async updateCartInfoToDB ({ state }) {

      // 장바구니에 담긴 상품 총 금액
      let totalOrderPrice = 0
      // 총 구매확정 적립금
      let totalReward = 0
      // 장바구니에 담긴 상품 종류 갯수
      let cartProductCnt = 0

      // 구매확정 정립금 계산
      if (state.cart?.deliveryGroups?.length > 0) {
        /*
          * 상품판매가 / 즉시할인가 / 추가상품가 / 추가할인가는 상품단위로 값이 있음 (단, 해당 가격들은 갯수가 포함되지 않은 가격이므로 )
          * 배송비제외된 가격임
          상품갯수 : deliveryGroup.orderProducts[x].orderProducts[y].orderProductOptions[z].orderCnt
          상품판매가 : deliveryGroup.orderProducts[x].orderProducts[y].orderProductOptions[z].price.salePrice
          즉시할인가 : deliveryGroup.orderProducts[x].orderProducts[y].orderProductOptions[z].price.immediateDiscountAmt
          추가상품가(개별 옵션별로 달라지는 추가금액) : deliveryGroup.orderProducts[x].orderProducts[y].orderProductOptions[z].price.addPrice
          추가할인가(추가할인 프로모션으로 인한 할인금액) : deliveryGroup.orderProducts[x].orderProducts[y].orderProductOptions[z].price.additionalDiscountAmt
        */
        
        // 적립률 기본 1%이고, 23년 9월 30일 이벤트 기간까지는 7%로 한다.
        let nowDate = new Date()

        // 상품별 구매확정 적립금
        let reward = 0

        // 주문의 총 합산 금액 SUM(상품별((상품판매가 + 추가상품가) - (즉시할인가 + 추가할인가)) * 상품갯수)
        state.cart.deliveryGroups.forEach((deliveryGroup) => {
          deliveryGroup.orderProducts?.forEach((orderProduct) => {
            cartProductCnt++
            orderProduct.orderProductOptions?.forEach((option) => {
              totalOrderPrice += ((option.price.salePrice + option.price.addPrice) - (option.price.immediateDiscountAmt + option.price.additionalDiscountAmt)) * option.orderCnt
            })
          })
        })

        state.cart.deliveryGroups.forEach((deliveryGroup) => {
          deliveryGroup.orderProducts?.forEach((orderProduct) => {
            orderProduct.orderProductOptions?.forEach((option) => {
              // 적립금 계산
              reward = calBuyConfirmReward(nowDate, totalOrderPrice, option.orderCnt, { 
                salePrice: option.price.salePrice,
                addPrice: option.price.addPrice,
                immediateDiscountAmt: option.price.immediateDiscountAmt,
                additionalDiscountAmt: option.price.additionalDiscountAmt 
              },{ 
                productCouponAmt: 0,
                cartCouponAmt: 0,
                accumulationAmt: 0
              }, option.productNo)

              totalReward += reward
            })
          })
        })
      }

      let payload = {
        cartInfo: {
          uid: cookies.get('memberCummunityUID'),
          cartProductCount: cartProductCnt,
          cartAmt: totalOrderPrice,
          cartAccumuateAmt: totalReward,
        },
        mallAccessToken: cookies.get('ncpAccessToken'),
        memberNo: cookies.get('userMemberNo'),
        appType: cookies.get('appType'),
        appVersion: cookies.get('appVersion'),
        languageCode: "ko",
        countryCode: "82",
        whereToUseCode: "MARKET",
        logCode: "",
        utcInterval: -540
      }

      getLBData('Order/SetUserCartInfo', 'post', payload)
    }
  },
  getters: {
    allChecked (state) {
      let allChecked = false
      if (state.count) {
        if ((state.count.count - state.invalidCount) === state.checkOptions.length) {
          allChecked = true
        }
      }
      return allChecked
    },
    havePayOnDelivery (state) {
      let have = false
      if (state.checkOptions) {
        have = state.checkOptions.some(item => item.payOnDelivery)
      }
      return have
    }
  },
  mutations: {
    CHECK_OPTION (state, option) {
      if (option.checked) {
        state.checkOptions.push(option)
      } else {
        state.checkOptions = state.checkOptions.filter(item => item.cartNo !== option.cartNo)
      }
    },
    CHECK_ALL (state, flg) {
      state.checkOptions = []
      if (flg && state.cart && state.cart.deliveryGroups) {
        // deliveryGroups
        state.cart.deliveryGroups.forEach(deliveryGroup => {
          // orderProducts
          deliveryGroup.orderProducts.forEach(orderProduct => {
            // orderProductOptions
            orderProduct.orderProductOptions.forEach(option => {
              const checkOption = {
                cartNo: option.cartNo,
                productNo: option.productNo,
                optionNo: option.optionNo,
                orderCnt: option.orderCnt,
                optionInputs: option.optionInputs,
                payOnDelivery: option.payOnDelivery
              }
              state.checkOptions.push(checkOption)
            })
          })
        })
      }
    },
    FORMAT_DATAS (state, { isLogined }) {
      state.invalidCount = 0
      if (state.cart && state.cart.deliveryGroups && state.cart.deliveryGroups.length > 0) {
        // deliveryGroups
        state.cart.deliveryGroups.forEach((deliveryGroup) => {
          // orderProducts
          deliveryGroup.orderProducts.forEach((orderProduct, productIdx) => {
            // orderProductOptions
            orderProduct.orderProductOptions.forEach((orderProductOption, optionIdx) => {
              orderProductOption['idx'] = 'check_' + orderProductOption.cartNo

              // orderProductOption
              orderProductOption.brandName = orderProduct.brandName
              orderProductOption.productName = orderProduct.productName

              // 옵션선택정보
              let price = orderProductOption.price
              orderProductOption.showOptions = getshowOptions(orderProduct.optionUsed, orderProductOption.optionType, orderProductOption.optionName, orderProductOption.optionValue, orderProductOption.optionInputs, price.addPrice)

              // 옵션 상품 주문금액
              orderProductOption.showPrice = formatCurrency(price.buyAmt)

              // 배송비
              if (orderProduct.deliverable) {
                if (deliveryGroup.deliveryAmt === 0) {
                  orderProductOption.showShippingFee = '배송비 무료'
                } else {
                  if (deliveryGroup.deliveryPayType === 'PAY_ON_DELIVERY') {
                    orderProductOption.payOnDelivery = true
                    orderProductOption.showShippingFee = formatCurrency(deliveryGroup.deliveryAmt) + '원착불'
                  } else {
                    orderProductOption.showShippingFee = '배송비 ' + formatCurrency(deliveryGroup.deliveryAmt) + '원'
                  }
                }
                if (orderProduct.orderProductOptions.length > 1 || deliveryGroup.orderProducts.length > 1) {
                  orderProductOption.bundledFlg = true
                } else {
                  orderProductOption.bundledFlg = false
                }
                if (productIdx === 0 && optionIdx === 0) {
                  orderProductOption.bundledFirst = true
                } else {
                  orderProductOption.bundledFirst = false
                }
              }

              // 상품금액
              orderProductOption.showStandardAmt = formatCurrency(price.standardAmt)
              // 할인금액
              orderProductOption.showDiscountAmt = formatCurrency((price.immediateDiscountAmt + price.additionalDiscountAmt) * orderProductOption.orderCnt)

              // 환불불가
              orderProductOption.refundable = orderProduct.refundable
            })
          })
        })
      }

      if (state.cart && state.cart.invalidProducts && state.cart.invalidProducts.length > 0) {
        // product
        state.cart.invalidProducts.forEach(product => {
          // orderProductOptions
          product.orderProductOptions.forEach(option => {
            state.invalidCount += 1
            option['idx'] = 'check_' + option.cartNo

            // orderProductOption
            option.brandName = product.brandName
            option.productName = product.productName

            // 옵션선택정보
            option.showOptions = getshowOptions(product.optionUsed, option.optionType, option.optionName, option.optionValue, option.optionInputs, option.price.addPrice)
          })
        })
      }
    },
    SET_INIT (state) {
      state.initFlg = true
      // 장바구니 옵션 초기화
      // 40000원짜리 상품을 장바구니에 담고 장바구니를 들어왔다가 20000원짜리 상품을 장바구니에 담고 장바구니를 보면 간헐적으로 40000원만 표시되는 현상으로인해 수정
      state.checkOptions = []
    },
    LANDING_UPDATE_CART (state) {
      if (config.isLocalDevelopment) return

      let data = {
        'ViewCode': 1,
        'RendingCode': 'LEARNBODY_CART_UPDATE',
        'RendingData': state.count.count + ''
      }
    
      commonAppCall(data, true)
    }
  }
}
