import { getLBData } from '@/api'
import cookies from 'js-cookie'
import ncpApi from '@/api/ncpApi'
import { getDateFromIssueYmdt } from '@/utils/dateUtils'

const ENDPOINT_INBODYAPPCHECK = 'Auth/InBodyAppCheck'
const ENDPOINT_RENEWALMALLTOKEN = 'Auth/RenewalMallToken'
const ENDPOINT_INVITATIONCHECK = 'Auth/InvitationCheck'

export default {
  namespaced: true,
  actions: {
    async getNCPAccessToken ({ state, commit, dispatch }, { uId, appType, appVersion }) {
      // 토큰 조회
      const resp = await getLBData(ENDPOINT_INBODYAPPCHECK, 'get', { uId: uId, appType: appType, appVersion: appVersion })

      // resp.data.mallAccessToken
      const d = getDateFromIssueYmdt(resp.data.mallTokenExpireDateYmdt + '')

      // LastAccessToken 만료시간 10일 후 설정
      const expLastToken = new Date()
      expLastToken.setTime(expLastToken.getTime() + 1000 * 60 * 60 * 24 * 10)

      cookies.set('ncpAccessToken', resp.data.mallAccessToken, { 'expires': d })
      cookies.set('ncpLastAccessToken', resp.data.mallAccessToken, { 'expires': expLastToken })
      ncpApi.defaults.headers.accessToken = resp.data.mallAccessToken

      // App 정보는 만료시간 360일 후 설정
      const expAppInfo = new Date()
      expAppInfo.setTime(expAppInfo.getTime() + 1000 * 60 * 60 * 24 * 30 * 12)
      cookies.set('appType', appType, { 'expires': expAppInfo })
      cookies.set('appVersion', appVersion, { 'expires': expAppInfo })
      cookies.set('memberInBodyAppLogin', true, { 'expires': expAppInfo })

      cookies.set('userMemberNo', resp.data.memberNo, { 'expires': expAppInfo })
      cookies.set('memberCummunityUID', resp.data.uid, { 'expires': expAppInfo })
      cookies.set('memberCummunityNickName', resp.data.memberNickName, { 'expires': expAppInfo })
      cookies.set('memberCummunityImageUrl', resp.data.memberImageUrl, { 'expires': expAppInfo })
      cookies.set('memberCummunityLevel', resp.data.memberLevel, { 'expires': expAppInfo })
      cookies.set('marketAgreement', resp.data.isAgree, { 'expires': expAppInfo })
      cookies.set('isShowHotDeal', resp.data.isShowHotDeal, { 'expires': expAppInfo })
      cookies.set('isShowNew', resp.data.isShowNew, { 'expires': expAppInfo })
      cookies.set('hotDealCount', resp.data.hotDealCount, { 'expires': expAppInfo })
      cookies.set('newCount', resp.data.newCount, { 'expires': expAppInfo })

      // 성인인증
      let adultCertification = resp.data.adultCertification
      if (adultCertification !== null) {
        let birthday = adultCertification.birthday
        let lastAdultCertificationDatetime = adultCertification.lastAdultCertificationDatetime

        cookies.set('birthday', birthday, { 'expires': expAppInfo })
        cookies.set('lastAdultCertDate', lastAdultCertificationDatetime, { 'expires': expAppInfo })
      } else {
        cookies.remove('birthday')
        cookies.remove('lastAdultCertDate')
      }

      // 친구초대
      cookies.set('isAllowInvitationMenu', resp.data.isAllowInvitationMenu, { 'expires': expAppInfo }) // 친구초대메뉴 표시여부
      cookies.set('isAllowInvitationToolTip', resp.data.isAllowInvitationToolTip, { 'expires': expAppInfo }) // 친구초대툴팁 표시여부

      return resp
    },
    async renewalMallToken ({ state, commit, dispatch }, { mallAccessToken, uId, appType, appVersion }) {
      // 토큰 갱신
      const resp = await getLBData(ENDPOINT_RENEWALMALLTOKEN, 'get', { mallAcessToken: mallAccessToken, uId: uId, appType: appType, appVersion: appVersion })

      const d = getDateFromIssueYmdt(resp.data.mallTokenExpireDateYmdt + '')

      // LastAccessToken 만료시간 10일 후 설정
      const expLastToken = new Date()
      expLastToken.setTime(expLastToken.getTime() + 1000 * 60 * 60 * 24 * 10)

      cookies.set('ncpAccessToken', resp.data.mallAccessToken, { 'expires': d })
      cookies.set('ncpLastAccessToken', resp.data.mallAccessToken, { 'expires': expLastToken })
      ncpApi.defaults.headers.accessToken = resp.data.mallAccessToken

      // App 정보는 만료시간 360일 후 설정
      const expAppInfo = new Date()
      expAppInfo.setTime(expAppInfo.getTime() + 1000 * 60 * 60 * 24 * 30 * 12)
      cookies.set('memberInBodyAppLogin', true, { 'expires': expAppInfo })

      cookies.set('userMemberNo', resp.data.memberNo, { 'expires': expAppInfo })
      cookies.set('memberCummunityNickName', resp.data.memberNickName, { 'expires': expAppInfo })
      cookies.set('memberCummunityImageUrl', resp.data.memberImageUrl, { 'expires': expAppInfo })
      cookies.set('memberCummunityLevel', resp.data.memberLevel, { 'expires': expAppInfo })
      cookies.set('marketAgreement', resp.data.isAgree, { 'expires': expAppInfo })
      cookies.set('isShowHotDeal', resp.data.isShowHotDeal, { 'expires': expAppInfo })
      cookies.set('isShowNew', resp.data.isShowNew, { 'expires': expAppInfo })
      cookies.set('hotDealCount', resp.data.hotDealCount, { 'expires': expAppInfo })
      cookies.set('newCount', resp.data.newCount, { 'expires': expAppInfo })

      // 성인인증
      let adultCertification = resp.data.adultCertification
      if (adultCertification !== null) {
        let birthday = adultCertification.birthday
        let lastAdultCertificationDatetime = adultCertification.lastAdultCertificationDatetime

        cookies.set('birthday', birthday, { 'expires': expAppInfo })
        cookies.set('lastAdultCertDate', lastAdultCertificationDatetime, { 'expires': expAppInfo })
      } else {
        cookies.remove('birthday')
        cookies.remove('lastAdultCertDate')
      }

      // 친구초대
      cookies.set('isAllowInvitationMenu', resp.data.isAllowInvitationMenu, { 'expires': expAppInfo }) // 친구초대메뉴 표시여부
      cookies.set('isAllowInvitationToolTip', resp.data.isAllowInvitationToolTip, { 'expires': expAppInfo }) // 친구초대툴팁 표시여부

      return resp
    },
    // 친구초대 링크에서 쿠폰받기를 눌러서 마켓에 진입한 경우
    async getNCPAccessTokenWithInvitation ({ state, commit, dispatch }, { uId, appType, appVersion, recommendMemberNo }) {
      // 토큰 조회
      const resp = await getLBData(ENDPOINT_INVITATIONCHECK, 'get', { uId: uId, appType: appType, appVersion: appVersion, recommendMemberNo: recommendMemberNo })

      // resp.data.mallAccessToken
      const d = getDateFromIssueYmdt(resp.data.mallTokenExpireDateYmdt + '')

      // LastAccessToken 만료시간 10일 후 설정
      const expLastToken = new Date()
      expLastToken.setTime(expLastToken.getTime() + 1000 * 60 * 60 * 24 * 10)

      cookies.set('ncpAccessToken', resp.data.mallAccessToken, { 'expires': d })
      cookies.set('ncpLastAccessToken', resp.data.mallAccessToken, { 'expires': expLastToken })
      ncpApi.defaults.headers.accessToken = resp.data.mallAccessToken

      // App 정보는 만료시간 360일 후 설정
      const expAppInfo = new Date()
      expAppInfo.setTime(expAppInfo.getTime() + 1000 * 60 * 60 * 24 * 30 * 12)
      cookies.set('appType', appType, { 'expires': expAppInfo })
      cookies.set('appVersion', appVersion, { 'expires': expAppInfo })
      cookies.set('memberInBodyAppLogin', true, { 'expires': expAppInfo })

      cookies.set('userMemberNo', resp.data.memberNo, { 'expires': expAppInfo })
      cookies.set('memberCummunityUID', resp.data.uid, { 'expires': expAppInfo })
      cookies.set('memberCummunityNickName', resp.data.memberNickName, { 'expires': expAppInfo })
      cookies.set('memberCummunityImageUrl', resp.data.memberImageUrl, { 'expires': expAppInfo })
      cookies.set('memberCummunityLevel', resp.data.memberLevel, { 'expires': expAppInfo })
      cookies.set('marketAgreement', resp.data.isAgree, { 'expires': expAppInfo })
      cookies.set('isShowHotDeal', resp.data.isShowHotDeal, { 'expires': expAppInfo })
      cookies.set('isShowNew', resp.data.isShowNew, { 'expires': expAppInfo })
      cookies.set('hotDealCount', resp.data.hotDealCount, { 'expires': expAppInfo })
      cookies.set('newCount', resp.data.newCount, { 'expires': expAppInfo })

      // 성인인증
      let adultCertification = resp.data.adultCertification
      if (adultCertification !== null) {
        let birthday = adultCertification.birthday
        let lastAdultCertificationDatetime = adultCertification.lastAdultCertificationDatetime

        cookies.set('birthday', birthday, { 'expires': expAppInfo })
        cookies.set('lastAdultCertDate', lastAdultCertificationDatetime, { 'expires': expAppInfo })
      } else {
        cookies.remove('birthday')
        cookies.remove('lastAdultCertDate')
      }

      // 친구초대
      cookies.set('isAllowInvitationMenu', resp.data.isAllowInvitationMenu, { 'expires': expAppInfo }) // 친구초대메뉴 표시여부
      cookies.set('isAllowInvitationToolTip', resp.data.isAllowInvitationToolTip, { 'expires': expAppInfo }) // 친구초대툴팁 표시여부
      // isAllowInvitationMenu가 true인 경우는 구매이력이 이미 있다는 것을 의미하므로 이 경우 초대쿠폰을 받지 못한다는 팝업 표시
      // recommendMemberNo 0 구매확정까지 진행된 사용자, -1 결제완료~구매확정 전까지 진행된 사용자, null 마켓 가입 후 결제 이력이 없는 사람
      if (resp.data.recommendMemberNo === 0 || resp.data.recommendMemberNo === -1) {
        cookies.set('isShowInvitationDenyPopup', 'true', { 'expires': expAppInfo })
      } else {
        cookies.remove('isShowInvitationDenyPopup')
      }

      return resp
    }
  }
}
