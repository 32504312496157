import cookies from 'js-cookie'
let config = {}

if (process.env.BUILD_ENV === 'prod') {
  config = require('./prod').default
}
if (process.env.BUILD_ENV === 'alpha') {
  // config = require('./alpha').default
  config = require('./dev').default
}
if (process.env.BUILD_ENV === 'dev') {
  config = require('./dev').default
}
if (process.env.BUILD_ENV === 'slot') {
  config = require('./slot').default
}
config.platform = function () {
  if (process.env.BUILD_TARGET === 'mobileweb') {
    return 'MOBILE_WEB'
  }
  const ostype = cookies.get('osType')
  if (ostype === 'aos') {
    return 'AOS'
  }
  return 'IOS'
}

config.paycoClientId = function () {
  const platform = config.platform()

  if (platform === 'AOS') {
    return config.paycoClientIdForAOS
  } else if (platform === 'IOS') {
    return config.paycoClientIdForIOS
  } else {
    return config.paycoClientIdForWEB
  }
}

config.paycoLoginUrl = function (nextUrl) {
  const params = {
    response_type: 'code',
    client_id: config.paycoClientId(),
    state: 'null',
    serviceProviderCode: 'NEMALL',
    // redirect_uri: `${location.protocol}//${location.host}/logincallback?nxturl=${nextUrl}`,
    redirect_uri: location.protocol + '//' + location.host + '/logincallback?nxturl=' + encodeURIComponent(nextUrl),
    termsYN: 'Y'
  }
  const queryStr = Object.keys(params)
    .map(key => `${key}=${encodeURIComponent(params[key])}`)
    .join('&')
  return `${config.paycoAuthorizeUrl}?${queryStr}`
}

config.paycoJoinUrl = function (nextUrl) {
  const params = {
    serviceProviderCode: 'NEMALL',
    svcType: 'APP',
    serviceOfferYn: 'Y',
    migrationCode: '',
    smsAuthInfoYn: '',
    client_id: config.paycoClientId(),
    serviceClientId: config.paycoClientId(),
    nextURL: '',
    response_type: 'code',
    state: 'null',
    // redirect_uri: `${location.protocol}//${location.host}/logincallback?nxturl=${nextUrl}`,
    redirect_uri: location.protocol + '//' + location.host + '/logincallback?nxturl=' + encodeURIComponent(nextUrl),
    loginUrl: config.paycoLoginUrl(nextUrl)
  }
  const queryStr = Object.keys(params)
    .map(key => `${key}=${encodeURIComponent(params[key])}`)
    .join('&')
  return `${config.paycoAuthorizeJoinUrl}?${queryStr}`
}

config.paycoCertifyUrl = function (nextUrl) {
  const params = {
    serviceProviderCode: 'NEMALL',
    m: 'certifyIndividual',
    nextURL: nextUrl,
    usageCode: 'EASY_PAYMENT'
  }
  const queryStr = Object.keys(params)
    .map(key => `${key}=${encodeURIComponent(params[key])}`)
    .join('&')
  return `${config.paycoCertifyLogin}?${queryStr}`
}

config.paycoMyinfoUrl = function () {
  const svcType = config.platform() === 'MOBILE_WEB' ? 'WEB' : 'APP'
  const viewType = config.platform() === 'MOBILE_WEB' ? 'payco_web_mobile' : 'mobile_app'
  const params = {
    serviceProviderCode: 'NEMALL',
    nextURL: '',
    svcType: svcType,
    viewType: viewType
  }
  const queryStr = Object.keys(params)
    .map(key => `${key}=${encodeURIComponent(params[key])}`)
    .join('&')
  return `${config.paycoMyinfo}?${queryStr}`
}

config.paycoChangPwdUrl = function () {
  const svcType = config.platform() === 'MOBILE_WEB' ? 'WEB' : 'APP'
  const params = {
    popUp: 'true',
    serviceProviderCode: 'NEMALL',
    retUrl: '',
    svcType: svcType
  }
  const queryStr = Object.keys(params)
    .map(key => `${key}=${encodeURIComponent(params[key])}`)
    .join('&')
  return `${config.paycoChangPwd}?${queryStr}`
}

/** 운영기간일 경우 주석 아래값 false로 변경 */
// 서비스 점검 유무
config.isServiceMaintenance = false
// 앱으로 랜딩 요청 필요여부
config.isNeedCallAppLanding = false
// Local 개발시 플래그 활성화
config.isLocalDevelopment = false;
export default config
