import { getLBData } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_COMMENTLIST = 'Feed/GetCommentList'
const ENDPOINT_SET_COMMENTLIST = 'Feed/SetCommentReg'
const ENDPOINT_REMOVE_COMMENTLIST = 'Feed/SetCommentDelete'
const ENDPOINT_SET_HIGHFIVE = 'Feed/SetCommentHighFive'

const defaultParams = {
  appType: cookies.get('appType'),
  appVersion: cookies.get('appVersion'),
  languageCode: 'ko',
  countryCode: '82',
  whereToUseCode: 'FEED',
  cuid: 0,
  challengeId: 0,
  utcInterval: -540,
  logCode: '',
}

export default {
  namespaced: true,
  state: {
    feedCommentList: [],
  },
  actions: {
    async getFeedCommentList({ state }, payload) {
      // 식단에 댓글을 작성하는 경우 MEAL, 운동에 댓글을 작성하는 경우 WORKOUT을 넣어주세요.
      payload = {
        ...payload,
        ...defaultParams,
      }

      const resp = await getLBData(ENDPOINT_COMMENTLIST, 'post', payload)
      state.feedCommentList = resp.data
      return resp.data
    },
    async addFeedComment({ state }, payload) {
      payload = {
        ...payload,
        ...defaultParams,
      }

      const resp = await getLBData(ENDPOINT_SET_COMMENTLIST, 'post', payload)
      return resp.data
    },
    // addStateComment({ state }, payload) {
    //   if (payload.replyCommentID !== 0) {
    //     const savedFeedCommentList = state.feedCommentList
    //     const inx = savedFeedCommentList.findIndex((item) => item.commentID === payload.rootReplyCommentID)
    //     savedFeedCommentList[inx].replyCommentList.push(payload)
    //   } else {
    //     state.feedCommentList = [
    //       ...state.feedCommentList,
    //       {
    //         ...payload,
    //         replyCommentList: [],
    //       },
    //     ]
    //   }
    // },
    async removeFeedComment({ state }, payload) {
      payload = {
        ...payload,
        ...defaultParams,
      }

      const resp = await getLBData(ENDPOINT_REMOVE_COMMENTLIST, 'post', payload)
      return resp.data
    },
    async setCommentHighFive({ state }, payload) {
      payload = {
        ...payload,
        ...defaultParams,
      }

      const resp = await getLBData(ENDPOINT_SET_HIGHFIVE, 'post', payload)
      return resp.data
    },
  },
}
