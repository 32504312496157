import { hasAccessToken } from '@/utils/utils'
import cookies from 'js-cookie'
export default {
  isLogined (state) {
    if (process.env.BUILD_TARGET !== 'webview') {
      return cookies.get('ncpAccessToken') && cookies.get('ncpAccessToken').length > 0
    } else {
      return hasAccessToken() && cookies.get('ncpAccessToken') && cookies.get('ncpAccessToken').length > 0
    }
  }
}
