import { getLBData } from '@/api'
import cookies from 'js-cookie'

export default {
  namespaced: true,
  state: {
    productReviewInfo: null
  },
  actions: {
    async getProductReviewInfo ({ state, commit, dispatch }, payload) {
      // 토큰 조회
      payload.mallAccessToken = cookies.get('ncpAccessToken')
      payload.appType = cookies.get('appType')
      payload.appVersion = cookies.get('appVersion')

      const resp = await getLBData('Product/ProductReviewInfo', 'get', payload)
      state.productReviewInfo = resp.data

      // console.log(resp.data)
      return resp.data
    },
    async postProductReviewAdd ({ state, commit, dispatch }, payload) {
      // 토큰 조회
      payload.mallAccessToken = cookies.get('ncpAccessToken')
      payload.appType = cookies.get('appType')
      payload.appVersion = cookies.get('appVersion')

      const resp = await getLBData('Product/ProductReviewAdd', 'post', payload)
      // state.productReviewInfo = resp.data

      // console.log(resp.data)
      return resp.data
    }
  }
}
