import { getLBDiaryData } from '@/api'
import { request, requetCommunity } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_ISFIRSTMEALRECORD = 'V1/IsDiaryFirstMealRecord'

const defaultParams = {
  uId: cookies.get('memberCummunityUID'),
  appType: cookies.get('appType'),
  appVersion: cookies.get('appVersion'),
  languageCode: 'ko',
  countryCode: '82',
  whereToUseCode: 'FEED',
  cuid: 0,
  challengeId: 0,
  utcInterval: -540,
  logCode: ''
}

export default {
  namespaced: true,
  state: {
    isFirstMealRecord: false
  },
  actions: {
  // 메인 상단 배너 이미지 조회
    async getIsFirstMealRecord ({ state }) {
      const resp = await getLBDiaryData(ENDPOINT_ISFIRSTMEALRECORD, 'post', defaultParams)
      
      if (resp.data !== undefined && resp.data !== null) {
        console.log('' + state.log)
        state.isFirstMealRecord = resp.data
      }
    },
  }
}

