import { getLBData } from '@/api'
import cookies from 'js-cookie'

export default {
  namespaced: true,
  state: {
    postResultSolution: null,
    postResultBible: null,
    postResultLetter: null,
    postResultStory: null,
    postResultDiary: null,
    postResultHelp: null,
    postResultDietMeal: null
  },
  actions: {
    async getPostResultSolution ({ state, commit, dispatch }, { searchText, location }) {
        const resp = await getLBData('Search/PostResult', 'post', {
            searchText: searchText,
            callLocation: location,
            categoryCode: 'solution',
            communityMenuCode: '',
            orderType: '',
            MallAccessToken: cookies.get('ncpAccessToken'),
            appType: cookies.get('appType'),
            appVersion: cookies.get('appVersion'),
            pageNum: 1,
            pageSize: this.pageSize,
            gender: 'A'
        })
        state.postResultSolution = resp.data
    },
    async getPostResultBible ({ state, commit, dispatch }, { searchText, location }) {
        const resp = await getLBData('Search/PostResult', 'post', {
            searchText: searchText,
            callLocation: location,
            categoryCode: 'bible',
            communityMenuCode: '',
            MallAccessToken: cookies.get('ncpAccessToken'),
            appType: cookies.get('appType'),
            appVersion: cookies.get('appVersion'),
            pageNum: 1,
            pageSize: 5,
            gender: 'A'
        })
        state.postResultBible = resp.data
    },
    async getPostResultLetter ({ state, commit, dispatch }, { searchText, location }) {
        const resp = await getLBData('Search/PostResult', 'post', {
            searchText: searchText,
            callLocation: location,
            categoryCode: 'letter',
            communityMenuCode: '',
            MallAccessToken: cookies.get('ncpAccessToken'),
            appType: cookies.get('appType'),
            appVersion: cookies.get('appVersion'),
            pageNum: 1,
            pageSize: 3,
            gender: 'A'
        })
        state.postResultLetter = resp.data
    },
    async getPostResultStory ({ state, commit, dispatch }, { searchText, location }) {
        const resp = await getLBData('Search/PostResult', 'post', {
            searchText: searchText,
            callLocation: location,
            categoryCode: 'story',
            communityMenuCode: '',
            MallAccessToken: cookies.get('ncpAccessToken'),
            appType: cookies.get('appType'),
            appVersion: cookies.get('appVersion'),
            pageNum: 1,
            pageSize: 4,
            gender: 'A'
        })
        state.postResultStory = resp.data
    },
    async getPostResultDiary ({ state, commit, dispatch }, { searchText, location }) {
        const resp = await getLBData('/Search/PostResult', 'post', {
            searchText: searchText,
            callLocation: location,
            categoryCode: 'diary',
            communityMenuCode: '',
            MallAccessToken: cookies.get('ncpAccessToken'),
            appType: cookies.get('appType'),
            appVersion: cookies.get('appVersion'),
            pageNum: 1,
            pageSize: 3,
            gender: 'A'
        })
        state.postResultDiary = resp.data
    },
    async getPostResultHelp ({ state, commit, dispatch }, { searchText, location }) {
        const resp = await getLBData('/Search/PostResult', 'post', {
            searchText: searchText,
            callLocation: location,
            categoryCode: 'help',
            communityMenuCode: '',
            MallAccessToken: cookies.get('ncpAccessToken'),
            appType: cookies.get('appType'),
            appVersion: cookies.get('appVersion'),
            pageNum: 1,
            pageSize: 3,
            gender: 'A'
        })
        state.postResultHelp = resp.data
    },
    async getPostResultDietMeal ({ state, commit, dispatch }, { searchText, location }) {
        const resp = await getLBData('/Search/DietMealResult', 'post', {
            searchText: searchText,
            callLocation: location,
            cookingDifficultyCodeArrayString: '',
            popularIDXArrayString: '',
            situationCodeArrayString: '',
            mealPropertyCodeArrayString: '',
            MallAccessToken: cookies.get('ncpAccessToken'),
            appType: cookies.get('appType'),
            appVersion: cookies.get('appVersion'),
            pageSize: 3
        })
        state.postResultDietMeal = resp.data
    }
  },
  mutations: {
    INIT_ENTER_SUBSTITUTE_FOOD (state) {
      state.enterSubstituteFood = false
    },
    SET_ENTER_SUBSTITUTE_FOOD (state) {
      state.enterSubstituteFood = true
    }
  }
}
