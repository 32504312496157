import { createNcpApiStore } from '@/api'
import { formatBanners } from '@/utils/utils'
import config from '@/config'

const apiStore = createNcpApiStore([
  {
    action: 'fetchBanners',
    property: 'banners',
    path: `display/banners/${config.homeBannerCode}`
  },
  {
    action: 'fetchMagicBanners',
    property: 'magicBanners',
    path: `display/banners/${config.homeMagicBannerCode}`
  },
  {
    action: 'brandBanner',
    property: 'brandBanner',
    path: `display/banners/${config.brandBannerCode}`
  },
  {
    action: 'eventTabBanner',
    property: 'eventTabBanner',
    path: `display/banners/${config.eventTabBannerCode}`
  },
  {
    action: 'categoryBanner',
    property: 'categoryBanner',
    path: `display/banners/${config.categoryBannerCode}`
  },
  {
    action: 'myPageTopBanner',
    property: 'myPageTopBanner',
    path: `display/banners/${config.myPageTopBannerCode}`
  },
  {
    action: 'myPageBottomBanner',
    property: 'myPageBottomBanner',
    path: `display/banners/${config.myPageBottomBannerCode}`
  }
])

export default {
  namespaced: true,
  mixins: [apiStore],
  actions: {
    fetchMainAll ({ state, commit, dispatch }) {
      if (state.banners && state.magicBanners) {
        return
      }
      dispatch('fetchBanners')
      dispatch('fetchMagicBanners')
    },
    fetchMyPageBanners ({ state, commit, dispatch }) {
      dispatch('myPageTopBanner')
      dispatch('myPageBottomBanner')
    }
  },
  getters: {
    mainTopBanners (state) {
      if (!state.banners) {
        return
      }

      const oldbanners = formatBanners(state.banners[0] && state.banners[0].accounts[0].banners)
      if (!oldbanners) {
        return
      }

      const newArr = oldbanners.slice(0)
      newArr.sort(() => 0.5 - Math.random())

      let showBanners = []
      oldbanners.forEach((item, index) => {
        if (item.name === newArr[0].name) {
          const firstBanners = oldbanners.slice(index)
          const endBanners = oldbanners.slice(0, index)
          showBanners = firstBanners.concat(endBanners)
        }
      })
      return showBanners
    },
    mainMagicBanners (state) {
      if (!state.magicBanners) {
        return
      }
      return formatBanners(state.magicBanners[0].accounts[0].banners)
    },
    showBrandBanner (state) {
      if (!state.brandBanner) {
        return
      }
      return formatBanners(state.brandBanner[0].accounts[0].banners)
    },
    eventBanners (state) {
      if (!state.eventTabBanner) {
        return
      }
      return formatBanners(state.eventTabBanner[0].accounts[0].banners)
    },
    categoryBanner (state) {
      if (state.categoryBanner && state.categoryBanner.length && state.categoryBanner[0].accounts && state.categoryBanner[0].accounts.length) {
        state.categoryBanner[0].accounts.forEach(account => {
          const newArr = account.banners.slice(0)
          newArr.sort(() => 0.5 - Math.random())

          let showBanners = []
          account.banners.forEach((item, index) => {
            if (item.name === newArr[0].name) {
              const firstBanners = account.banners.slice(index)
              const endBanners = account.banners.slice(0, index)
              showBanners = firstBanners.concat(endBanners)
            }
          })
          account.banners = formatBanners(showBanners)
        })
        return state.categoryBanner[0].accounts
      }
    },
    myPageTopBanners (state) {
      return state.myPageTopBanner && state.myPageTopBanner.length && state.myPageTopBanner[0].accounts.length && state.myPageTopBanner[0].accounts[0] && formatBanners(state.myPageTopBanner[0].accounts[0].banners)
    },
    myPageBottomBanners (state) {
      return state.myPageBottomBanner && state.myPageBottomBanner.length && state.myPageBottomBanner[0].accounts.length && state.myPageBottomBanner[0].accounts[0] && formatBanners(state.myPageBottomBanner[0].accounts[0].banners)
    }
  }
}
