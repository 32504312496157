import axios from 'axios'
import cookies from 'js-cookie'
import config from '@/config'
import applyUrlTemplate from './applyUrlTemplate'
import applyDefaultParams from './applyDefaultParams'
import {
  applyCacheResuest,
  applyCacheResponse
} from './cache'
import normalizeAxiosError, {
  SERVER_ERROR,
  TIMEOUT_ERROR,
  NETWORK_ERROR,
  CLIENT_ERROR
} from './normalizeAxiosError'
import router from '@/router'
import {
  logoutRemoveCookie, showAlertWithOneButton
} from '@/utils/utils'
import {
  compareAppVersion
} from '@/utils/StringUtils'

const API_TIMEOUT = 30000 // 30s

const ncpApi = axios.create({
  baseURL: getBaseUrl(),
  timeout: API_TIMEOUT,
  headers: {
    'accessToken': cookies.get('ncpAccessToken') || '',
    'clientId': config.ncpClientId,
    'Version': '1.0',
    'platform': config.platform(),
    'guestToken': cookies.get('guestToken') || ''
  }
})

function getBaseUrl () {
  if (process.env.BUILD_TARGET === 'webview') {
    return config.ncpApiBaseUrlWebview
  } else {
    return config.ncpApiBaseUrl
  }
}
ncpApi.interceptors.request.use(applyCacheResuest)
ncpApi.interceptors.request.use(applyUrlTemplate)
ncpApi.interceptors.request.use(applyDefaultParams)

ncpApi.interceptors.response.use(applyCacheResponse)
ncpApi.interceptors.response.use(null, normalizeAxiosError)
ncpApi.interceptors.response.use(null, function (err) {
  if (err.code === TIMEOUT_ERROR || err.code === NETWORK_ERROR) {
    // soter.commit('error...', err)
  }
  if (err.code === SERVER_ERROR) {
    // soter.commit('error...', err)
    if (router.currentRoute._value.query.isDebug !== 'true') {
      if (!window.location.href.includes('/etc/dataerror')) {
        window.location.href = '/etc/dataerror'
      }
    }
  }
  if (err.code === CLIENT_ERROR) {
    const noalert = ['E0005', 'M0013', 'O0009', 'E9001', 'CL904', 'M0021', 'M0017']
    const customCode = {
      'E0009': '선택하신 상품은 찜하기 불가능한 상품입니다.',
      'O3336': '본인 인증을 한 PAYCO ID를 통해서만 구매가 가능합니다. [확인] 클릭 시 PAYCO 본인인증 페이지로 이동'
    }
    if (router.currentRoute._value.name === 'LBLogin') {
      noalert.push('M0019')
      noalert.push('M1003')
    }
    if (router.currentRoute._value.name === 'MbrModify') {
      noalert.push('M0019')
    }
    if (!noalert.some(item => item === err.data.code)) {
      if (customCode[err.data.code]) {
        showAlertWithOneButton(this, customCode[err.data.code])
      } else {
        // 여기서 에러메세지 팝업을 띄울수 없으므로 추석처리 후 호출부 catch에서 처리
        // showAlertWithOneButton(this, err.data.message)
      }
    }
    const certify = `${location.protocol}//${location.host}/paycocertify?nxturl=${router.currentRoute._value.fullPath}`
    switch (err.data.code) {
      case 'E0007':
        router.push({
          path: '/member/login',
          query: {
            redirect: `${location.protocol}//${location.host}${router.currentRoute._value.fullPath}`
          }
        })
        break
      case 'E0008': {
        const ncpCertificated = cookies.get('ncpCertificated')
        const ncpAdultCertificated = cookies.get('ncpAdultCertificated')
        if (ncpCertificated !== 'true') {
          window.location.href = config.paycoCertifyUrl(certify)
        } else if (ncpCertificated === 'true' && ncpAdultCertificated === 'false') {
          router.push({
            path: '/'
          })
        } else {
          router.push({
            path: '/'
          })
        }
        break
      }
      case 'M0013':
        logoutRemoveCookie()
        if (config.platform() === 'IOS' && compareAppVersion('4.2')) {
          router.push({
            path: '/tokenerror'
          })
        } else {
          window.location.href = '/'
        }
        break
      case 'O3336':
        if (process.env.BUILD_TARGET === 'webview') {
          window.location.href = config.paycoCertifyUrl(certify)
        } else {
          window.location.href = config.paycoCertifyUrl(certify)
        }
        break
      case 'M0030':
        router.push({
          path: '/member/agreement',
          query: {
            nxturl: `${location.protocol}//${location.host}${router.currentRoute._value.fullPath}`
          }
        })
        break
      case 'PNR001':
        window.location.href = '/'
        break
      case 'E9001':
        if (router.currentRoute._value.name !== 'PwSearch') {
          router.push({
            path: '/pagenotfound'
          })
        }
        break
      case 'M0021':
        if (cookies.get('fromPayco')) {
          location.href = 'http://m.buywow.co.kr/member/bridge?redirect=/'
        } else {
          if (opener) {
            opener.location.href = '/member/login'
            self.close()
          } else {
            location.href = '/member/login'
          }
        }
        break
      case 'M0017':
        if (router.currentRoute._value.name === 'Findidpw') {
          router.push({
            path: '/member/findconfirm'
          })
        }
        break
      default:
        break
    }
  }
  return Promise.reject(err)
})

export default ncpApi
