import { getNcpData, getLBDataWithCatch, getLBData } from '@/api'
import cookies from 'js-cookie'
import { getTodayTime } from '@/utils/dateUtils'
import { checkIsAdult } from '@/utils/utils'

const ENDPOINT_MALLPRODUCTADULTCHECK = 'Product/MallProductAdultCheck'
const ENDPOINT_FORM = 'kcp/id-verification/form'
const ENDPOINT_RESPONSE = 'kcp/id-verification/response'
const ENDPOINT_SENDMEMBERADULTCERTIFICATION = 'Member/SendMemberAdultCertification'

export default {
  namespaced: true,
  state: {
    certificationForm: '',
    isAdultProduct: false
  },
  actions: {
    async getMallProductAdultCheck ({ state, commit, dispatch }, mallProductNo) {
      state.isAdultProduct = false
      const ncrResponse = await getLBDataWithCatch(ENDPOINT_MALLPRODUCTADULTCHECK, 'get', {mallProductNo: mallProductNo})
      let status = ncrResponse.status
      if (status === 200) {
        state.isAdultProduct = ncrResponse.data.result
      } else {
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      }
      return status
    },
    async getCertificationForm ({ state, commit, dispatch }, returnUrl) {
      // state.certificationForm = '<form name="form_auth" id="form_auth" method="post" action="https://cert.kcp.co.kr/kcp_cert/cert_view.jsp" target="auth_popup">' + '<input type="hidden" name="site_cd" value="A7JGM"/>' + '<input type="hidden" name="ordr_idxx" value="1_20220315164454878645_tdRu"/>' + '<input type="hidden" name="req_tx" value="cert"/>' + '<input type="hidden" name="cert_method" value="01"/>' + '<input type="hidden" name="up_hash" value="75B7186888F87984098ABDA93D765A8409322200"/>' + '<input type="hidden" name="cert_otp_use" value="Y"/>' + '<input type="hidden" name="cert_able_yn" value=""/>' + '<input type="hidden" name="web_siteid" value=""/>' + '<input type="hidden" name="param_opt_1" value="' + returnUrl + '"/>' + '<input type="hidden" name="param_opt_2" value="1"/>' + '<input type="hidden" name="Ret_URL" value="https://alpha-shop-api.e-ncp.com/kcp/id-verification/callback"/>' + '<input type="hidden" name="web_siteid_hashYN" value=""/>' + '<input type="hidden" name="cert_enc_use_ext" value="Y"/>' + '<input type="hidden" name="kcp_cert_lib_ver" value="CTCLI_J_1_0_6"/>' + '<input type="hidden" name="user_name" value=""/>' + '<input type="hidden" name="year" value=""/>' + '<input type="hidden" name="month" value=""/>' + '<input type="hidden" name="day" value=""/>' + '<input type="hidden" name="sex_code" value=""/>' + '<input type="hidden" name="local_code" value="01"/>' + '</form>'
      const ncrResponse = await getNcpData(ENDPOINT_FORM, 'get', { returnUrl: returnUrl, domestic: null })
      let status = ncrResponse.status
      if (status === 200) {
        state.certificationForm = ncrResponse.data
        // PASS앱 인증시 사용자 정보 입력하는 과정을 건너뛰어 인증을 완료할 수 없는 현상으로 간편인증으로 강제 변경함
        state.certificationForm = state.certificationForm.replaceAll('<input type="hidden" name="kcp_cert_pass_use" value="Y"/>','<input type="hidden" name="kcp_cert_pass_use" value="N"/>')
        state.certificationForm = state.certificationForm.replaceAll('<input type="hidden" name="kcp_cert_intent_use" value="Y"/>','<input type="hidden" name="kcp_cert_intent_use" value="N"/>')
      } else {
        state.certificationForm = ''
      }
    },
    async getCertificationResult ({ state, commit, dispatch }, key) {
      const ncrResponse = await getNcpData(ENDPOINT_RESPONSE, 'get', { key: key })

      let status = ncrResponse.status

      // 테스트 코드
      // birthday = '1989-01-23'
      // birthday = '2004-01-23'
      if (status === 200) {
        var birthday = ncrResponse.data.birthday
        if ((birthday !== undefined || birthday !== null) && birthday.length === 8) {
          birthday = birthday.slice(0, 4) + '-' + birthday.slice(4, 6) + '-' + birthday.slice(6, 8)

          // App 정보는 만료시간 360일 후 설정
          const expAppInfo = new Date()
          expAppInfo.setTime(expAppInfo.getTime() + 1000 * 60 * 60 * 24 * 30 * 12)
          cookies.set('birthday', birthday, { 'expires': expAppInfo })
          cookies.set('lastAdultCertDate', getTodayTime(), { 'expires': expAppInfo })

          dispatch('sendCertificationResult', birthday)
        }
      }

      return checkIsAdult()
    },
    async sendCertificationResult ({ state, commit, dispatch }, birthday) {
      await getLBData(ENDPOINT_SENDMEMBERADULTCERTIFICATION, 'post', {mallAccessToken: cookies.get('ncpAccessToken'), birthday: birthday})
    }
  },
  mutations: {
    INIT_CERTIFICATION_FORM (state) {
      state.certificationForm = ''
    }
  }
}
