import { getLBData } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_COMMUNITYPOSTDETAIL = 'ImitateOrder/CommunityPostDetail'
const ENDPOINT_STORYHIGHFIVE = 'ImitateOrder/HighFiveReg'
const ENDPOINT_STORYFAVORITE = 'ImitateOrder/FavoriteReg'

export default {
  namespaced: true,
  state: {
    communityPostDetail: null,
    MDPageScrollPos: null
  },
  actions: {
    async getCommunityPostDetail ({ state, commit, dispatch }, payload) {
      const resp = await getLBData(ENDPOINT_COMMUNITYPOSTDETAIL, 'post', {
        imitateOrderIdx: payload.imitateOrderIdx,
        MallAccessToken: cookies.get('ncpAccessToken'),
        uid: cookies.get('memberCummunityUID'),
        appType: cookies.get('appType'),
        appVersion: cookies.get('appVersion'),
        callLocation: null
      })
      console.log('getCommunityPostDetail : ' + resp.data)
      state.communityPostDetail = resp.data
    },
    async setRecordHighFive ({ state, commit, dispatch }, payload) {
      payload.MallAccessToken = cookies.get('ncpAccessToken')
      payload.uid = cookies.get('memberCummunityUID')
      payload.appType = cookies.get('appType')
      payload.appVersion = cookies.get('appVersion')
      payload.callLocation = null
      payload.languageCode = 'ko',
      payload.countryCode = 82,
      payload.whereToUseCode = 'FEED',
      payload.logCode = '',
      payload.utcInterval = -540
      console.log(payload)
      const resp = await getLBData(ENDPOINT_STORYHIGHFIVE, 'post', payload)
      return resp.data
    },

    async setRecordFavorite ({ state, commit, dispatch }, payload) {
      console.log(payload)
      const resp = await getLBData(ENDPOINT_STORYFAVORITE, 'post', {
        imitateOrderIdx: payload.imitateOrderIdx,
        isOk: payload.isOk,
        MallAccessToken: cookies.get('ncpAccessToken'),
        uid: cookies.get('memberCummunityUID'),
        appType: cookies.get('appType'),
        appVersion: cookies.get('appVersion'),
        callLocation: null
      })
      console.log('setRecordFavorite : ' + resp.data)
      return resp.data
    }
  },
  mutations: {
    SET_MDPAGE_SCROLLPOS (state, payload) {
      state.MDPageScrollPos = payload
    },
    INIT_MDPAGE_SCROLLPOS (state) {
      state.MDPageScrollPos = null
    },
    /**
     * 피드에서 성공스토리 즐겨찾기 클릭시 성공스토리 상세데이터 동기화
     * data 구조
     * {  
          key: '',
          isActiveFavorite: true / false
        }
     */
    SYNC_FAVORITE_DATA (state, data) {
      if (state.communityPostDetail?.detail.imitateOrderIdx === data.key) {
        state.communityPostDetail.detail.favoriteCount = data.isActiveFavorite ? state.communityPostDetail.detail.favoriteCount + 1 : state.communityPostDetail.detail.favoriteCount - 1
        state.communityPostDetail.detail.isFavorite = data.isActiveFavorite
      }
    },
    /**
     * 피드에서 성공스토리 하이파이브 클릭시 성공스토리 상세데이터 동기화
     * data 구조
     * {  
          key: '',
          isActiveHighFive: true
        }
     */
    SYNC_HIGHFIVE_DATA (state, data) {
      if (state.communityPostDetail?.detail.imitateOrderIdx === data.key) {
        state.communityPostDetail.detail.highFiveCount = data.isActiveHighFive ? state.communityPostDetail.detail.highFiveCount + 1 : state.communityPostDetail.detail.highFiveCount - 1
        state.communityPostDetail.detail.isHighFive = data.isActiveHighFive
      }
    },
  }
}
