<template>
    <div id="header_freshway" class="cj_freshway_wrap">
      <header id="headerArea">
        <h1>런바디</h1>
      </header>
    </div>
</template>

<script>

export default {
  methods: {
    callMain () {
      if (this.$route.fullPath === '/') {
        window.location.reload()
      } else {
        this.$router.push({
          path: '/'
        })
      }
    }
  }
}
</script>
<style scoped>
  .cj_freshway_wrap header h1 {
    color: #EC2619;
  }
</style>