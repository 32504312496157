
import { getLBData } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_COMMUNITYPOSTDETAIL = 'CommunityPost/Detail'

export default {
  namespaced: true,
  state: {
    communityPostDetail: {}
  },
  actions: {
    async getCommunityPostDetail ({ state, commit, dispatch }, postID) {
      const resp = await getLBData(ENDPOINT_COMMUNITYPOSTDETAIL, 'get', { postID: postID, MallAccessToken: cookies.get('ncpAccessToken') })

      console.log('getCommunityPostDetail : ' + resp.data)
      state.communityPostDetail = resp.data
    }
  }
}
