<template>
  <div class="util_box">
    <div class="btn_search" @click="clickSearch"></div>
    <router-link @click="marketingLog('MarketHomeCartMoreClick')" to="/cart" class="btn_cart">
      <span v-if="count" class="num" :style="updateBadgeBGColor">{{count.count>=100?'99+':count.count}}</span>
    </router-link>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import cookies from 'js-cookie'

export default {
  components: {
  },
  data () {
    return {
      viewShow: false
    }
  },
  props: {
    type: {
      type: String
    }
  },
  computed: {
    isWebView () {
      return process.env.BUILD_TARGET === 'webview'
    },
    ...mapState('cart', ['count']),
    updateBadgeBGColor () {
      if (this.count !== null && this.count.count > 0) {
        return 'background-color: rgba(236, 38, 25, 0.86);'
      } else {
        return 'background-color: rgba(148, 157, 164, 0.86);'
      }
    }
  },
  methods: {
    closeApp () {
      // if confirm('CJ프레시웨이 임직원몰을 닫으시겠어요?')
      cookies.remove('buywowAccessToken')
      location.href = 'buywow://close'
    },
    marketingLog (val) {
      let marketingLogData = JSON.stringify({
          marketLogCode: val,
          marketLogValue: ''
      })
      this.$store.dispatch('lbMarketingLog/setMarketingLog', { marketingLogData: marketingLogData, logCode: val })
    },
    clickSearch () {
      let logCode = 'CombinedSearchIconClick'
      let marketingLogData = JSON.stringify({
        marketLogCode: logCode,
        SearchTab: 'Market'
      })
      this.$store.dispatch('lbMarketingLog/setMarketingLog', { marketingLogData: marketingLogData, logCode: logCode })

      // 검색전화면 진입시 검색 키워드 / 타겟 / 결과화면여부 / 탭 정보 초기화
      this.$store.commit('lbIntegrateSearchBefore/INIT_SEARCH_STORE_PARAMS')

      this.$router.push({
          name: 'NewSearch',
          query: {
              tabValue: 'Market'
          }
      })
    },
    ...mapMutations('search', ['show_search_layer'])
  },
  beforeMount () {
    /** cart post to server */
    const preItems = JSON.parse(window.localStorage.cartInfo || '[]')
    if (preItems.length > 0 && this.$store.getters.isLogined) {
      if (cookies.get('ncpMemberAgree') === 'Y') {
        this.$store.dispatch('cart/addToCart', preItems)
        delete window.localStorage.cartInfo
      }
    }
    this.$store.dispatch('cart/fetchCartCount')
  }
}
</script>
