import cookies from 'js-cookie'

export default {
  namespaced: true,
  state: {
    recentKeywords: []
  },
  mutations: {
    REFRESH_KEYWORD_LIST (state) {
      let cookiee = cookies.get('recentSearchText')
      if (cookiee !== undefined && cookiee !== null) {
        let recentKeywordArray = cookiee
        let recentKeywordJson = JSON.parse(recentKeywordArray).reverse()
        let arrUnique = recentKeywordJson.filter((val, idx) => {
          return recentKeywordJson.indexOf(val) === idx
        })
        state.recentKeywords = arrUnique
      }
    },
    ADD_KEYWORD_LIST (state, keyword) {
      // 키워드 추가
      var keywordList = cookies.get('recentSearchText') !== undefined && cookies.get('recentSearchText') !== null ? JSON.parse(cookies.get('recentSearchText')) : []
      keywordList.push(keyword)

      // 중복제거
      let arrListUnique = keywordList.reverse().filter((val, idx) => {
        return keywordList.indexOf(val) === idx
      }).reverse()

      // 10개까지만 저장
      if (arrListUnique.length > 9) {
        arrListUnique.splice(0, arrListUnique.length - 10)
      }
      cookies.set('recentSearchText', JSON.stringify(arrListUnique))
    }
  }
}
