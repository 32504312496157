import { getLBData } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_SUGGESTEDSEARCH = 'Search/SuggestedSearch'

export default {
  namespaced: true,
  state: {
    suggestedSearch: []
  },
  actions: {
    async getSuggestedSearch ({ state, commit, dispatch }) {
      const resp = await getLBData(ENDPOINT_SUGGESTEDSEARCH, 'get', '')
      state.suggestedSearch = resp.data

      cookies.set('suggestedSearchText', JSON.stringify(resp.data), { 'expires': 7 })
    }
  }
}
