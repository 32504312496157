
import { getLBData } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_RECOMMENDGROUP = 'HotDeal/RecommendEventGroup'

export default {
  namespaced: true,
  state: {
    marketRecommendCollection: {}
  },
  actions: {
    async getRecommendCollection ({ state, commit, dispatch }) {
      const resp = await getLBData(ENDPOINT_RECOMMENDGROUP, 'get', { MallAccessToken: cookies.get('ncpAccessToken') })
      state.marketRecommendCollection = resp.data
    }
  }
}
