// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/image/btn_back@3x.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main-btn[data-v-707673e1]{display:flex;align-content:center;width:75%}.logo a[data-v-707673e1]{display:block;height:100%;color:#EC2619;background-image:none;text-indent:0;font-size:18px;background-size:80%;background-position-x:0;margin-left:10px}.logo a img[data-v-707673e1]{width:115px;vertical-align:middle}.back-btn[data-v-707673e1]{width:40px;height:40px;display:inline-block;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;background-size:contain}\n", "",{"version":3,"sources":["webpack://./src/components/header/MainHeader.vue"],"names":[],"mappings":"AACA,2BACE,YAAa,CACb,oBAAqB,CACrB,SAAU,CACX,yBAEG,aAAc,CACd,WAAY,CACZ,aAAc,CACd,qBAAsB,CACtB,aAAc,CACd,cAAe,CACf,mBAAoB,CACpB,uBAAwB,CACxB,gBAAiB,CACpB,6BAEC,WAAY,CAAE,qBAAsB,CACrC,2BAEC,UAAW,CAAE,WAAY,CAAE,oBAAqB,CAChD,mEAAoE,CACpE,uBAAwB","sourcesContent":["\n.main-btn {\n  display: flex;\n  align-content: center;\n  width: 75%;\n}\n.logo a {\n    display: block;\n    height: 100%;\n    color: #EC2619;\n    background-image: none;\n    text-indent: 0;\n    font-size: 18px;\n    background-size: 80%;\n    background-position-x: 0;\n    margin-left: 10px;\n}\n.logo a img {\n  width: 115px; vertical-align: middle;\n}\n.back-btn {\n  width: 40px; height: 40px; display: inline-block;\n  background: url(../../assets/image/btn_back@3x.png) no-repeat center;\n  background-size: contain;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
