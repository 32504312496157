import createApiStoreCreator from './createApiStoreCreator'
import ncpApi from './ncpApi'
import {
  postLBFeedData,
  postLBFeedFollowData,
  postLBFeedFavoriteData,
  postLBFeedHighFiveData,
  postLBFeedFilterData,
  postLBFeedRecentFilterData,
  postLBFeedFilterTotalData,
  postLBFeedQuizData,
  getLBData,
  getNcpData,
  getLBDataWithCatch,
  getLBDiaryData,
  getLBCrewData
} from './lbApi'

const createNcpApiStore = createApiStoreCreator(ncpApi)

export {
  ncpApi as default,
  ncpApi,
  createNcpApiStore,
  getLBData,
  getNcpData,
  getLBDataWithCatch,
  getLBDiaryData,
  getLBCrewData,
  postLBFeedData,
  postLBFeedFollowData,
  postLBFeedFavoriteData,
  postLBFeedHighFiveData,
  postLBFeedFilterData,
  postLBFeedRecentFilterData,
  postLBFeedFilterTotalData,
  postLBFeedQuizData
}
