import { getLBData } from '@/api'
import cookies from 'js-cookie'
import _ from 'lodash'

const ENDPOINT_RECOMMENDFILTER = 'Suggested/MarketRecommendedFilter'
const ENDPOINT_MARKETFILTER = 'Suggested/MarketFilter'
const ENDPOINT_FILTERTOTALCOUNT = 'Suggested/FilterTotalCount'

export default {
  namespaced: true,
  state: {
    selectedCategory: [],
    tempSelectedCategory: [],
    isFilterChange: false,
    isNeedRefreshProductList: false,
    recommendFilterList: [],
    marketFilterList: null,
    // 적용된 필터 데이터
    selectedFilterData: {
      discountList: [],
      deliveryList: [],
      filterPrice: null,
      filterBrandList: [],
      filterNutrientList: [],
      filterCategoryLabel: ''
    },
    // 임시 필터 데이터
    tempFilterData: {
      discountList: [],
      deliveryList: [],
      filterPrice: null,
      filterBrandList: [],
      filterNutrientList: [],
      filterCategoryLabel: ''
    },
    // 선택된 추천필터의 영양성분 정보
    recommendFilterNutrientList: [],
    // 카테고리 탭에서 추천필터를 클릭한 경우여부
    isRecommendFromCategoryTab: false,
    totalCount: 0,
    // 통합검색에서 검색된 검색어
    searchText: '',
    // 랜딩정보
    landingData: {
      brandNoArrayString: ''
    },
    // 랜딩 관련 플래그
    recommendFilterCodeFromLanding: '',
    isBrandNoArrayStringFromLanding: false,
    brandNoArrayStringFromLanding: '',
    isBrandNameStringFromLanding: false,
    brandNameStringFromLanding: ''
  },
  actions: {
    async getRecommendFilter ({ state, commit, dispatch }) {
      const resp = await getLBData(ENDPOINT_RECOMMENDFILTER, 'get', '')
      // console.log(resp.data)
      state.recommendFilterList = resp.data
    },
    async getMarketFilter ({ state, commit, dispatch }, payload) {
      const params = {'displayCategoryNoArrayString': payload}
      const resp = await getLBData(ENDPOINT_MARKETFILTER, 'post', params)
      state.marketFilterList = resp.data

      // 필터 화면에 사용될 영역별 temp 필터 data
      state.tempFilterData.filterNutrientList = resp.data.nutrientFilterList
      state.recommendFilterNutrientList.forEach((element) => {
        let nutrientInfo = state.tempFilterData.filterNutrientList.filter((w) => w.nutrientCode === element.nutrientCode)[0]
        if (nutrientInfo !== null && nutrientInfo !== undefined) {
          if (element.userMinValue !== null) {
            nutrientInfo.userMinValue = element.userMinValue
          }

          if (element.userMaxValue !== null) {
            nutrientInfo.userMaxValue = element.userMaxValue
          }
        }
      })
      state.tempFilterData.filterCategoryLabel = resp.data.categoryLabelText
      state.tempFilterData.discountList = resp.data.discountList
      state.tempFilterData.deliveryList = resp.data.deliveryList
      state.tempFilterData.filterPrice = resp.data.filterPrice

      // 랜딩정보가 있는경우
      if (state.landingData.brandNoArrayString !== '') {
        let arrBrandNo = state.landingData.brandNoArrayString.split(',')
        let tempFilterBrandList = resp.data.filterBrandList

        arrBrandNo.forEach(element => {
          tempFilterBrandList.forEach(b => {
            for (let i = 0; i < b.brandList.length; i++) {
              let brand = b.brandList[i]
              if (element === brand.brandNo + '') {
                brand.isSelected = true
                break
              }
            }
          })
        })

        state.tempFilterData.filterBrandList = tempFilterBrandList
        state.landingData.brandNoArrayString = ''
      } else {
        state.tempFilterData.filterBrandList = resp.data.filterBrandList
      }

      // 필터 설정에 따른 상품 갯수 조회
      if (resp.data !== null && resp.data !== undefined &&
        !state.isRecommendFromCategoryTab &&
        !state.isBrandNoArrayStringFromLanding &&
        !state.isBrandNameStringFromLanding) {
        dispatch('getFilterTotalCount')
      } else if (state.isRecommendFromCategoryTab) {
        /**
         * 카테고리 탭 > 추천필터 > 전체상품 > 필터화면으로 진입시
         * 카테고리를 제외한 기타 필터 정보가 없어 필터 조정이 불가능하게 되므로
         * 해당 플래그로 getMarketFilter 호출 후 selectedFilterData에 바로 반영되도록한다.
         */
        commit('INIT_RECOMMEND_FILTER_NUTRIENT_LIST')
        commit('SET_SELECTED_FILTER_DATA')
        state.isRecommendFromCategoryTab = false
      } else if (state.isBrandNoArrayStringFromLanding) {
        /**
         * 런바디 홈 > 브랜드 필터로 진입하는 경우
         * 카테고리를 제외한 기타 필터 정보가 없어 필터 조정이 불가능하게 되므로
         * 해당 플래그로 getMarketFilter 호출 후 selectedFilterData에 바로 반영되도록한다
         */
        // 브랜드 필터 선택 반영
        // BrandNo가 Array string으로 넘어오는 경우
        if (state.brandNoArrayStringFromLanding !== '') {
          let arrBrandNo2 = state.brandNoArrayStringFromLanding.split(',')
          let tempFilterBrandList2 = resp.data.filterBrandList
          arrBrandNo2.forEach(element => {
            tempFilterBrandList2.forEach(b => {
              for (let i = 0; i < b.brandList.length; i++) {
                let brand = b.brandList[i]
                if (element === brand.brandNo + '') {
                  brand.isSelected = true
                  break
                }
              }
            })
          })

          state.tempFilterData.filterBrandList = tempFilterBrandList2
        }

        commit('SET_SELECTED_FILTER_DATA')

        state.isBrandNoArrayStringFromLanding = false
      } else if (state.isBrandNameStringFromLanding) {
        /**
         * 상품상세에서 브랜드명 클릭하는 경우
         * 카테고리를 제외한 기타 필터 정보가 없어 필터 조정이 불가능하게 되므로
         * 해당 플래그로 getMarketFilter 호출 후 selectedFilterData에 바로 반영되도록한다
         */
        // 브랜드 필터 선택 반영
        if (state.brandNameStringFromLanding !== '') {
          let tempFilterBrandList3 = resp.data.filterBrandList
          tempFilterBrandList3.forEach(b => {
            for (let i = 0; i < b.brandList.length; i++) {
              let brand = b.brandList[i]
              if ((brand.brandName + '').indexOf(state.brandNameStringFromLanding) > -1) {
                brand.isSelected = true
                break
              }
            }
          })

          state.tempFilterData.filterBrandList = tempFilterBrandList3
        }

        commit('SET_SELECTED_FILTER_DATA')

        state.isBrandNameStringFromLanding = false
      }
    },
    async getFilterTotalCount ({ state, commit, dispatch }) {
      let params = {
        isAllSearch: false,
        navigation: 0,
        displayCategoryNoArrayString: '',
        nutrientList: [],
        discountArrayString: '',
        deliveryArrayString: '',
        minPrice: null,
        maxPrice: null,
        brandNoArrayString: '',
        searchText: state.searchText,
        orderBy: 0,
        isNew: false,
        hotDealNo: 0,
        pageNum: 0,
        pageSize: 0,
        mallAccessToken: cookies.get('ncpLastAccessToken')
      }

      // 선택된 카테고리
      let displayCategoryNoArrayString = ''
      state.tempSelectedCategory.filter((w) => w.isSelected).forEach((e) => {
        if (displayCategoryNoArrayString === '') {
          displayCategoryNoArrayString = e.categoryNo + ''
        } else {
          displayCategoryNoArrayString += ',' + e.categoryNo
        }
      })
      params.displayCategoryNoArrayString = displayCategoryNoArrayString

      // 선택된 영양 필터
      state.tempFilterData.filterNutrientList.forEach(element => {
        if (element.userMinValue !== undefined || element.userMaxValue !== undefined) {
          let nutrientInfo = {
            nutrientCode: element.nutrientCode,
            minValue: element.userMinValue === undefined ? element.minValue : element.userMinValue,
            maxValue: element.userMaxValue === undefined ? element.maxValue : element.userMaxValue
          }
          params.nutrientList.push(nutrientInfo)
        }
      })

      // 선택된 할인 필터
      let discountArrayString = ''
      state.tempFilterData.discountList.filter((w) => w.isSelected).forEach((e) => {
        if (discountArrayString === '') {
          discountArrayString = e.value + ''
        } else {
          discountArrayString += ',' + e.value
        }
      })
      params.discountArrayString = discountArrayString

      // 선택된 무료배송 필터
      let deliveryArrayString = ''
      state.tempFilterData.deliveryList.filter((w) => w.isSelected).forEach((e) => {
        if (deliveryArrayString === '') {
          deliveryArrayString = e.value + ''
        } else {
          deliveryArrayString += ',' + e.value
        }
      })
      params.deliveryArrayString = deliveryArrayString

      // 최대 / 최소 금액
      if (state.tempFilterData.filterPrice !== null) {
        params.minPrice = state.tempFilterData.filterPrice.isPriceChanged ? state.tempFilterData.filterPrice.minPrice : null
        params.maxPrice = state.tempFilterData.filterPrice.isPriceChanged ? state.tempFilterData.filterPrice.maxPrice : null
      }

      // 선택된 브랜드 목록
      let brandNoArrayString = ''
      state.tempFilterData.filterBrandList.forEach((b) => b.brandList.filter((w) => w.isSelected).forEach((e) => {
        if (brandNoArrayString === '') {
          brandNoArrayString = e.brandNo + ''
        } else {
          brandNoArrayString += ',' + e.brandNo
        }
      }))
      params.brandNoArrayString = brandNoArrayString

      // 필터정보가 있으면 isAllSearch는 false로 설정하고 이외는 true
      if (params.displayCategoryNoArrayString === '' &&
        params.nutrientList.length === 0 &&
        params.discountArrayString === '' &&
        params.deliveryArrayString === '' &&
        params.minPrice === null &&
        params.maxPrice === null &&
        params.brandNoArrayString === '' &&
        params.searchText === '') {
        params.isAllSearch = true
      } else {
        params.isAllSearch = false
      }

      const resp = await getLBData(ENDPOINT_FILTERTOTALCOUNT, 'post', params)
      state.totalCount = resp.data.totalCount
    }
  },
  mutations: {
    SET_SELECTED_CATEGORY (state, categoryList) {
      state.selectedCategory = categoryList
    },
    SET_TEMP_SELECTED_CATEGORY (state, newCategoryList) {
      state.tempSelectedCategory = newCategoryList
    },
    COPY_SELECTED_CATEGORY_LIST (state) {
      state.tempSelectedCategory = _.cloneDeep(state.selectedCategory)
    },
    CHANGE_SELECTED_FILTER_DATA (state) {
      state.selectedCategory = _.cloneDeep(state.tempSelectedCategory)
      state.selectedFilterData = _.cloneDeep(state.tempFilterData)
      state.isNeedRefreshProductList = true
    },
    INIT_ORIGIN_CATEGORIES (state) {
      state.selectedCategory = []
      state.isFilterChange = false
    },
    INIT_IS_FILTER_CHANGE (state) {
      state.isFilterChange = false
    },
    SET_IS_FILTER_CHANGE (state) {
      state.isFilterChange = true
    },
    INIT_SELECTED_FILTER_DATA (state) {
      state.selectedFilterData = {
        discountList: [],
        deliveryList: [],
        filterPrice: null,
        filterBrandList: [],
        filterNutrientList: [],
        filterCategoryLabel: ''
      }
    },
    INIT_IS_NEED_PRODUCT_LIST (state) {
      state.isNeedRefreshProductList = false
    },
    SET_IS_NEED_PRODUCT_LIST (state) {
      state.isNeedRefreshProductList = true
    },
    INIT_TEMP_CATEGORIES (state, multiLevelCategories) {
      if (multiLevelCategories !== null && multiLevelCategories !== undefined) {
        let categoryInfo = null
        state.tempSelectedCategory = []
        multiLevelCategories.forEach(parentCategory => {
          categoryInfo = {
            categoryNo: parentCategory.categoryNo,
            label: parentCategory.label,
            parentCategoryNo: 0,
            isSelected: false,
            children: parentCategory.children
          }
          state.tempSelectedCategory.push(categoryInfo)
          parentCategory.children.forEach(category => {
            if (category.label !== '') {
              categoryInfo = {
                categoryNo: category.categoryNo,
                label: category.label,
                parentCategoryNo: parentCategory.categoryNo,
                isSelected: false
              }
              state.tempSelectedCategory.push(categoryInfo)
            }
          })
        })
      } else {
        state.tempSelectedCategory = []
      }
    },
    COPY_TEMP_SELECTED_CATEGORY (state) {
      state.selectedCategory = _.cloneDeep(state.tempSelectedCategory)
    },
    INIT_TEMP_FILTER_DATA (state) {
      state.tempFilterData.filterNutrientList = state.marketFilterList.nutrientFilterList

      state.tempFilterData.discountList.forEach(item => {
        item.isSelected = false
      })

      state.tempFilterData.deliveryList.forEach(item => {
        item.isSelected = false
      })

      state.tempFilterData.filterPrice = state.marketFilterList.filterPrice

      state.tempFilterData.filterBrandList.forEach(item => {
        item.forEach(brandItem => {
          brandItem.isSelected = false
        })
      })

      state.tempFilterData.filterCategoryLabel = ''
    },
    COPY_SELECTED_FILTER_DATA (state) {
      state.tempFilterData = _.cloneDeep(state.selectedFilterData)
    },
    SET_SELECTED_FILTER_DATA (state) {
      state.selectedFilterData = _.cloneDeep(state.tempFilterData)
    },
    SET_RECOMMEND_FILTER_CODE_FROM_LANDING (state, code) {
      state.recommendFilterCodeFromLanding = code
    },
    SET_IS_RECOMMEND_FROM_CATEGORY_TAB (state) {
      state.isRecommendFromCategoryTab = true
    },
    INIT_RECOMMEND_FILTER_NUTRIENT_LIST (state) {
      state.recommendFilterNutrientList = []
    },
    SET_RECOMMEND_FILTER_NUTRIENT_LIST (state, payload) {
      state.recommendFilterNutrientList = []
      payload.forEach((element) => {
        let nutrient = {
          nutrientCode: element.nutrientCode,
          userMinValue: 0,
          userMaxValue: 0
        }

        if (element.inequalityCode === 'EQUAL') {
          nutrient.userMinValue = element.nutrientValue
          nutrient.userMaxValue = element.nutrientValue
        } else if (element.inequalityCode === 'LITTLE_EQUAL') {
          nutrient.userMinValue = element.minValue === undefined ? 0 : element.minValue
          nutrient.userMaxValue = element.nutrientValue
        } else if (element.inequalityCode === 'GREATER_EQUAL') {
          nutrient.userMinValue = element.nutrientValue
          nutrient.userMaxValue = element.maxValue === undefined ? 0 : element.maxValue
        }
        state.recommendFilterNutrientList.push(nutrient)
      })
    },
    SET_SELECTED_FILTER_NUTRIENT_LIST (state, payload) {
      state.selectedFilterData.filterNutrientList = []
      payload.forEach((element) => {
        let nutrient = {
          nutrientCode: element.nutrientCode,
          userMinValue: 0,
          userMaxValue: 0,
          minValue: element.minValue,
          maxValue: element.maxValue,
          nutrientUnit: element.nutrientUnit
        }

        if (element.inequalityCode === 'EQUAL') {
          nutrient.userMinValue = element.nutrientValue
          nutrient.userMaxValue = element.nutrientValue
        } else if (element.inequalityCode === 'LITTLE_EQUAL') {
          nutrient.userMinValue = element.minValue === undefined ? 0 : element.minValue
          nutrient.userMaxValue = element.nutrientValue
          if (nutrient.userMinValue === null) delete nutrient.userMinValue
        } else if (element.inequalityCode === 'GREATER_EQUAL') {
          nutrient.userMinValue = element.nutrientValue
          nutrient.userMaxValue = element.maxValue === undefined ? 0 : element.maxValue
          if (nutrient.userMaxValue === null) delete nutrient.userMaxValue
        }
        state.selectedFilterData.filterNutrientList.push(nutrient)
      })
    },
    SET_SEARCH_TEXT (state, text) {
      state.searchText = text
    },
    SET_IS_BRAND_NO_ARRAY_STRING_FROM_LANDING (state) {
      state.isBrandNoArrayStringFromLanding = true
    },
    SET_BRAND_NO_ARRAY_STRING_FROM_LANDING (state, brandNoArrayString) {
      state.brandNoArrayStringFromLanding = brandNoArrayString
    },
    SET_IS_BRAND_NAME_STRING_FROM_LANDING (state) {
      state.isBrandNameStringFromLanding = true
    },
    SET_BRAND_NAME_STRING_FROM_LANDING (state, brandNameString) {
      state.brandNameStringFromLanding = brandNameString
    },
    INIT_BRAND_NAME_STRING_FROM_LANDING (state) {
      state.brandNameStringFromLanding = ''
    }
  },
  getters: {
    // 기존에 선택된 카테고리 정보가 있는지 체크
    checkIsSelectedCategoryData (state) {
      let displayCategoryNoArrayString = ''
      state.selectedCategory.filter((w) => w.isSelected).forEach((e) => {
        if (displayCategoryNoArrayString === '') {
          displayCategoryNoArrayString = e.categoryNo + ''
        } else {
          displayCategoryNoArrayString += ',' + e.categoryNo
        }
      })

      if (displayCategoryNoArrayString === '') {
        return false
      } else {
        return true
      }
    },
    // 기존에 선택된 필터 정보가 있는지 체크
    checkIsSelectedFilterData (state) {
      // 선택된 영양 필터
      let nutrientList = []
      state.selectedFilterData.filterNutrientList.forEach(element => {
        if (element.userMinValue !== undefined || element.userMaxValue !== undefined) {
          let nutrientInfo = {
            nutrientCode: element.nutrientCode,
            minValue: element.userMinValue === undefined ? element.minValue : element.userMinValue,
            maxValue: element.userMaxValue === undefined ? element.maxValue : element.userMaxValue
          }
          nutrientList.push(nutrientInfo)
        }
      })

      // 선택된 할인 필터
      let discountArrayString = ''
      state.selectedFilterData.discountList.filter((w) => w.isSelected).forEach((e) => {
        if (discountArrayString === '') {
          discountArrayString = e.value + ''
        } else {
          discountArrayString += ',' + e.value
        }
      })

      // 선택된 무료배송 필터
      let deliveryArrayString = ''
      state.selectedFilterData.deliveryList.filter((w) => w.isSelected).forEach((e) => {
        if (deliveryArrayString === '') {
          deliveryArrayString = e.value + ''
        } else {
          deliveryArrayString += ',' + e.value
        }
      })

      // 최대 / 최소 금액
      let minPrice = null
      let maxPrice = null
      if (state.selectedFilterData.filterPrice !== null) {
        minPrice = state.selectedFilterData.filterPrice.isPriceChanged ? state.selectedFilterData.filterPrice.minPrice : null
        maxPrice = state.selectedFilterData.filterPrice.isPriceChanged ? state.selectedFilterData.filterPrice.maxPrice : null
      }

      // 선택된 브랜드 목록
      let brandNoArrayString = ''
      state.selectedFilterData.filterBrandList.forEach((b) => b.brandList.filter((w) => w.isSelected).forEach((e) => {
        if (brandNoArrayString === '') {
          brandNoArrayString = e.brandNo + ''
        } else {
          brandNoArrayString += ',' + e.brandNo
        }
      }))

      if (nutrientList.length === 0 &&
        discountArrayString === '' &&
        deliveryArrayString === '' &&
        minPrice === null &&
        maxPrice === null &&
        brandNoArrayString === '') {
        return false
      } else {
        return true
      }
    }
  }
}
