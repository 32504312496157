import { getLBData } from '@/api'
import cookies from 'js-cookie'

const endpointDietMeal = 'DietMeal/MealListWithMallProductNo'
const endpointCommunity = 'CommunityPost/PostListWithMallProductNo'

export const COMMUNITYLIST_PAGECODE_PRODUCTDETAIL = 1
export const COMMUNITYLIST_PAGECODE_TOTALLIST = 2

export default {
  namespaced: true,
  state: {
    mallProductNo: null,
    pageNum: 0,
    dietMealList: [],
    dietMealCategoryID: 0,
    communitydietStoryList: [],
    communitydietStoryCategoryID: 0,
    communitydietdiaryList: [],
    communitydietdiaryCategoryID: 0,
    communitydietbibleList: [],
    communitydietbibleCategoryID: 0,
    communitydietLetterList: [],
    communitydietLetterCategoryID: 0,
    dietMealPageInfo: {
      page: 0,
      pageSize: 0,
      pageCount: 0,
      totalCount: 0
    },
    dietStoryPageInfo: {
      page: 0,
      pageSize: 0,
      pageCount: 0,
      totalCount: 0
    },
    dietDiaryPageInfo: {
      page: 0,
      pageSize: 0,
      pageCount: 0,
      totalCount: 0
    },
    dietBiblePageInfo: {
      page: 0,
      pageSize: 0,
      pageCount: 0,
      totalCount: 0
    },
    dietLetterPageInfo: {
      page: 0,
      pageSize: 0,
      pageCount: 0,
      totalCount: 0
    },
    nowPageCode: 0
  },
  actions: {
    setParameterInit ({ state, commit, dispatch }, payload) {
      state.pageNum = 0

      if (payload.categoryCode === 'dietMeal') {
        state.dietMealList = []
        state.dietMealPageInfo = {
          page: 0,
          pageSize: 0,
          pageCount: 0,
          totalCount: 0
        }
        state.dietMealCategoryID = 0
      } else if (payload.categoryCode === 'story') {
        state.communitydietStoryList = []
        state.dietStoryPageInfo = {
          page: 0,
          pageSize: 0,
          pageCount: 0,
          totalCount: 0
        }
        state.communitydietStoryCategoryID = 0
      } else if (payload.categoryCode === 'diary') {
        state.communitydietdiaryList = []
        state.dietDiaryPageInfo = {
          page: 0,
          pageSize: 0,
          pageCount: 0,
          totalCount: 0
        }
        state.communitydietdiaryCategoryID = 0
      } else if (payload.categoryCode === 'bible') {
        state.communitydietbibleList = []
        state.dietBiblePageInfo = {
          page: 0,
          pageSize: 0,
          pageCount: 0,
          totalCount: 0
        }
        state.communitydietbibleCategoryID = 0
      } else if (payload.categoryCode === 'letter') {
        state.communitydietLetterList = []
        state.dietLetterPageInfo = {
          page: 0,
          pageSize: 0,
          pageCount: 0,
          totalCount: 0
        }
        state.communitydietLetterCategoryID = 0
      }
    },
    async getMealListWithMallProductNo ({ state, commit, dispatch }, payload) {
      // 1. API를 통해서 MallProduct 리스트를 가져온다
      // 2. 반환값을 받아서 state에 적용
      // api
      //  console.log(state.parameter)
      state.nowPageCode = payload.pageCode !== undefined ? payload.pageCode : state.nowPageCode
      state.pageNum = payload.pageNum

      payload.mallAccessToken = cookies.get('ncpAccessToken')
      payload.appType = cookies.get('appType')
      payload.appVersion = cookies.get('appVersion')
      if (!payload.pageSize) {
        payload.pageSize = 5
      }
      const resp = await getLBData(endpointDietMeal, 'get', payload)

      if (payload.pageNum === 1) {
        state.dietMealList = resp.data.dietMealWhatToEatDetailList
      } else {
        state.dietMealList.push(...resp.data.dietMealWhatToEatDetailList)
      }

      state.dietMealPageInfo = {
        page: resp.data.page,
        pageSize: resp.data.pageSize,
        pageCount: resp.data.pageCount,
        totalCount: resp.data.totalCount
      }
      state.dietMealCategoryID = resp.data.categoryID !== undefined ? resp.data.categoryID : state.dietMealCategoryID
    },
    async getCommunityStoryListWithMallProductNo ({ state, commit, dispatch }, payload) {
      // 1. API를 통해서 MallProduct 리스트를 가져온다
      // 2. 반환값을 받아서 state에 적용
      // api
      state.nowPageCode = payload.pageCode !== undefined ? payload.pageCode : state.nowPageCode
      state.pageNum = payload.pageNum

      payload.mallAccessToken = cookies.get('ncpAccessToken')
      payload.appType = cookies.get('appType')
      payload.appVersion = cookies.get('appVersion')
      if (!payload.pageSize) {
        payload.pageSize = 5
      }
      const resp = await getLBData(endpointCommunity, 'get', payload)
      if (payload.pageNum === 1) {
        state.communitydietStoryList = resp.data.postList
      } else {
        state.communitydietStoryList.push(...resp.data.postList)
      }
      state.dietStoryPageInfo = {
        page: resp.data.page,
        pageSize: resp.data.pageSize,
        pageCount: resp.data.pageCount,
        totalCount: resp.data.totalCount
      }
      state.communitydietStoryCategoryID = resp.data.categoryID
    },
    async getCommunityDiaryListWithMallProductNo ({ state, commit, dispatch }, payload) {
      // 1. API를 통해서 MallProduct 리스트를 가져온다
      // 2. 반환값을 받아서 state에 적용
      // api
      state.nowPageCode = payload.pageCode !== undefined ? payload.pageCode : state.nowPageCode
      state.pageNum = payload.pageNum

      payload.mallAccessToken = cookies.get('ncpAccessToken')
      payload.appType = cookies.get('appType')
      payload.appVersion = cookies.get('appVersion')
      if (!payload.pageSize) {
        payload.pageSize = 5
      }
      const resp = await getLBData(endpointCommunity, 'get', payload)
      if (payload.pageNum === 1) {
        state.communitydietdiaryList = resp.data.postList
      } else {
        state.communitydietdiaryList.push(...resp.data.postList)
      }
      state.dietDiaryPageInfo = {
        page: resp.data.page,
        pageSize: resp.data.pageSize,
        pageCount: resp.data.pageCount,
        totalCount: resp.data.totalCount
      }
      state.communitydietdiaryCategoryID = resp.data.categoryID
    },
    async getCommunityBibleListWithMallProductNo ({ state, commit, dispatch }, payload) {
      // 1. API를 통해서 MallProduct 리스트를 가져온다
      // 2. 반환값을 받아서 state에 적용
      // api
      state.nowPageCode = payload.pageCode !== undefined ? payload.pageCode : state.nowPageCode
      state.pageNum = payload.pageNum

      payload.mallAccessToken = cookies.get('ncpAccessToken')
      payload.appType = cookies.get('appType')
      payload.appVersion = cookies.get('appVersion')
      if (!payload.pageSize) {
        payload.pageSize = 5
      }
      const resp = await getLBData(endpointCommunity, 'get', payload)
      if (payload.pageNum === 1) {
        state.communitydietbibleList = resp.data.postList
      } else {
        state.communitydietbibleList.push(...resp.data.postList)
      }
      state.dietBiblePageInfo = {
        page: resp.data.page,
        pageSize: resp.data.pageSize,
        pageCount: resp.data.pageCount,
        totalCount: resp.data.totalCount
      }
      state.communitydietbibleCategoryID = resp.data.categoryID
    },
    async getCommunityLetterListWithMallProductNo ({ state, commit, dispatch }, payload) {
      // 1. API를 통해서 MallProduct 리스트를 가져온다
      // 2. 반환값을 받아서 state에 적용
      // api
      state.nowPageCode = payload.pageCode !== undefined ? payload.pageCode : state.nowPageCode
      state.pageNum = payload.pageNum

      payload.mallAccessToken = cookies.get('ncpAccessToken')
      payload.appType = cookies.get('appType')
      payload.appVersion = cookies.get('appVersion')
      if (!payload.pageSize) {
        payload.pageSize = 5
      }
      const resp = await getLBData(endpointCommunity, 'get', payload)
      if (payload.pageNum === 1) {
        state.communitydietLetterList = resp.data.postList
      } else {
        state.communitydietLetterList.push(...resp.data.postList)
      }
      state.dietLetterPageInfo = {
        page: resp.data.page,
        pageSize: resp.data.pageSize,
        pageCount: resp.data.pageCount,
        totalCount: resp.data.totalCount
      }
      state.communitydietLetterCategoryID = resp.data.categoryID
    }
  }
}
