export default {
  namespaced: true,
  state: {
    mainProduct: {
      // 피드타입코드
      feedTypeCode: 'MARKET_PRODUCT',
      // 상품번호
      feedIndexKey: '102161210',
      // 프로필 정보
      profile: {
        profileType: 'MARKET_BRAND',
        id: '107725',
        profileName: '바디닭',
        profileImageUrl: 'https://rlyfaazj0.toastcdn.net/PARTNER/20220608/PARTNER_54291/202206081610283e8930c5c4e54820b21d65f7b3b42a93/102274322_0.jpg',
        characteristicTagList: ['상품 추천'],
        isFollow: false
      },
      // 타이틀
      title: '"닭가습살 질릴 때, 돼지 안심 어때요?"',
      // 상품 정보
      productInfo: {
        mallProductNo: 102274322,
        brandNo: 107725,
        brandName: '바디닭',
        productName: '소프트 닭가슴살 그릴 10팩',
        salePrice: 21000.0,
        salePriceString: '21,000',
        saleEndYmdString: '2999-12-31',
        immediateDiscountApplyPrice: 0.0,
        immediateDiscountApplyPriceString: '0',
        immediateDiscountValue: 6500.0,
        immediateDiscountValueString: '6,500',
        // 할인률
        immediateDiscountRateText: '31%',
        immediateDiscountSalePrice: 14500.0,
        // 할인된 가격
        immediateDiscountSalePriceString: '14,500',
        // 금액 단위
        immediateDiscountUnitTypeText: '원',
        immediateDiscountPeriodYn: 'N',
        immediateDiscountEndYmdt: null,
        hasScore: true,
        // 맛 별점
        averageTasteScore: '4.6',
        // 안질림 별점
        averageBoringScore: '4.6',
        hasNutrient: true,
        pricePerServing: 1450.0,
        pricePerServingString: '1,450',
        weightPerServing: 100.0,
        // 상품단위
        nutrientUnitText: '팩',
        productUnitText: 'g',
        // 칼로리 값
        caloriePerServing: 119.7,
        // 탄수화물 값
        carbohydratePerServing: 1.1,
        // 당 값
        sugarPerServing: 0.0,
        // 단백질 값
        proteinPerServing: 25.7,
        // 지방 값
        fatPerServing: 1.4,
        // 상품 이미지
        imageUrl: 'https://rlyfaazj0.toastcdn.net/PARTNER/20220608/PARTNER_54291/202206081610283e8930c5c4e54820b21d65f7b3b42a93/102274322_0.jpg',
        // 상품 하단 [이번 주 핫딜], [무료배송] 태그영역의 태그 목록
        stickerList: [{
          mallProductNo: 102287149,
          label: '이번 주 핫딜',
          stickerExposurePosition: 'COMMON',
          displayOrder: 1,
          stickerType: 'TEXT',
          imageUrl: '',
          cssClass: 'lowest-price'
        },
        {
          mallProductNo: 107590109,
          label: '무료배송',
          stickerExposurePosition: 'FREE',
          displayOrder: 3,
          stickerType: 'TEXT',
          imageUrl: '',
          cssClass: 'shipment'
        }],
        soldoutCssClass: '',
        rendingInfo: {
          viewCode: 1,
          rendingCode: 'MARKET_WEBVIEW',
          rendingData: 'https://market.learnbody.co.kr/product/102274322?direct=1&calllocation=&uId=&appType=&appVersion=',
          webViewUrl: 'https://market.learnbody.co.kr/product/102274322?direct=1&calllocation=&uId=&appType=&appVersion=',
          webViewData: null,
          outLinkURL: null
        },
        buyRendingInfo: {
          viewCode: 1,
          rendingCode: 'MARKET_WEBVIEW',
          rendingData: 'https://market.learnbody.co.kr/product/102274322?direct=1&calllocation=&isBuy=1&uId=&appType=&appVersion=',
          webViewUrl: 'https://market.learnbody.co.kr/product/102274322?direct=1&calllocation=&isBuy=1&uId=&appType=&appVersion=',
          webViewData: null,
          outLinkURL: null
        },
        productCssClass: null,
        caloriePer100g: 119.7,
        carbohydratePer100g: 1.1,
        sugarPer100g: 0.0,
        proteinPer100g: 25.7,
        fatPer100g: 1.4,
        totalServing: 10.0,
        totalWeight: 1000.0,
        isAllowSale: true,
        isAdultProduct: false,
        minimumPricePerServing: 1650.0,
        // 팩당 최저가격
        minimumPricePerServingString: '1팩당 최저 1,650원'
      },
      reviewInfo: {
        // 리뷰 카운트
        reviewCount: 24,
        // 리뷰 이미지 목록
        reviewImages: [
          'https://lbwebkr.blob.core.windows.net/diet-community/market/review/102286634_28_01_thumb.jpeg?v=20220124073310',
          'https://lbwebkr.blob.core.windows.net/diet-community/market/review/102286634_28_02_thumb.jpeg?v=20220124073310',
          'https://lbwebkr.blob.core.windows.net/diet-community/market/review/102286634_17_01_thumb.jpeg?v=20220124073021',
          'https://lbwebkr.blob.core.windows.net/diet-community/market/review/102286634_13_04_thumb.jpeg?v=20220124073019'
        ]
      },
      // 찜하기 여부
      isLike: true,
      // 찜하기 카운트
      likeCount: 2
    }
  },
  mutations: {},
  actions: {}
};
