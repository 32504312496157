<template>
  <div class="header-title-box detail-header sticky-header">
        <div>
          <p class="icon-close btn_home" @click="goBack"></p>
          <p class="icon-home" @click="callMain" v-if="getParam('direct')==='1' && computedShowHomeBtn"></p>
        </div>
        <span class="header-title">상품 정보</span>
        <div class="util_box">
            <router-link to="/cart" class="btn_cart">
                <span v-if="count" class="num" :style="updateBadgeBGColor">{{count.count>=100?'99+':count.count}}</span>
            </router-link>
        </div>
        <LBLoading></LBLoading>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import cookies from 'js-cookie'
import { historyPage, commonAppCall, checkUsableFunctionWithOSVersion } from '@/utils/utils'
import LBLoading from '../common/LBLoading'

export default {
  computed: {
    computedShowHomeBtn () {
      return !checkUsableFunctionWithOSVersion([2, 4, 4], [2, 4, 3])
    },
    isWebView () {
      return process.env.BUILD_TARGET === 'webview'
    },
    ...mapState('cart', ['count']),
    updateBadgeBGColor () {
      if (this.count.count > 0) {
        return 'background-color: rgba(236, 38, 25, 0.86);'
      } else {
        return 'background-color: rgba(148, 157, 164, 0.86);'
      }
    }
  },
  methods: {
    goBack () {
      historyPage()
      //  this.$router.go(-1)
      // this.$nextTick(() => this.$refs.solutionDetails.showCurrent(comm and))
    },
    callMain () {
      this.$router.push({
        path: '/'
      })
      commonAppCall('CLEAR_WEBVIEWS')
    },
    getParam (name) {
      name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
      var regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
      let results = regex.exec(location.search)
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
    }
  },
  beforeMount () {
    /** cart post to server */
    const preItems = JSON.parse(window.localStorage.cartInfo || '[]')
    if (preItems.length > 0 && this.$store.getters.isLogined) {
      if (cookies.get('ncpMemberAgree') === 'Y') {
        this.$store.dispatch('cart/addToCart', preItems)
        delete window.localStorage.cartInfo
      }
    }
    this.$store.dispatch('cart/fetchCartCount')
  },
  components: {
    LBLoading
  },
  mounted () {
    document.getElementById('LBloader').style.display = 'flex'
      setTimeout(() => {
        document.getElementById('LBloader').style.display = 'none'
    }, 500)
  }
}
</script>

<style scoped>
#contents.cart_list_con {padding-top: 0;}
.header-title-box .icon-close {
    width: 40px; height: 40px; display: inline-block;
    background: url(../../assets/image/btn_back@3x.png) no-repeat center;
    background-size: contain;
}
.header-title-box .icon-home {
    width: 40px; height: 40px; display: inline-block;
    background: url(../../assets/image/btn_marketHome@3x.png) no-repeat center;
    background-size: 24px;
}
</style>
