<template>
  <BaseHeader type="main">
  <div class="main-btn">
    <button class="back-btn" @click.prevent="moveToBack"></button>
      <h2 class="logo">
        <a @click.prevent="callMain"><img src="../../assets/image/logo_learnbodyMarket_main.png"></a>
      </h2>
  </div>
  </BaseHeader>
</template>

<script>
import BaseHeader from './BaseHeader'
import { historyPage } from '@/utils/utils'

export default {
  components: {
    BaseHeader
  },
  methods: {
    callMain () {
      if (this.$route.fullPath === '/') {
        window.location.reload()
      } else {
        this.$router.push({
          path: '/'
        })
      }
    },
    moveToBack () {
      //  logoutRemoveCookie()
      historyPage()
    }
  }
}
</script>

<style scoped>
.main-btn {
  display: flex;
  align-content: center;
  width: 75%;
}
.logo a {
    display: block;
    height: 100%;
    color: #EC2619;
    background-image: none;
    text-indent: 0;
    font-size: 18px;
    background-size: 80%;
    background-position-x: 0;
    margin-left: 10px;
}
.logo a img {
  width: 115px; vertical-align: middle;
}
.back-btn {
  width: 40px; height: 40px; display: inline-block;
  background: url(../../assets/image/btn_back@3x.png) no-repeat center;
  background-size: contain;
}
</style>
