
import { getLBData } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_MARKETINGLOG = 'Log/SetMarketingLog'

/**
 * EMPTY = 0
 * PRODUCTVIEW = 1
 * FAVORITEADD = 2
 * FAVORITEREMOVE = 3
 */

export const MARKETINGLOG_EMPTY = 0
export const MARKETINGLOG_PRODUCTVIEW = 1
export const MARKETINGLOG_FAVORITEADD = 2
export const MARKETINGLOG_FAVORITEREMOVE = 3

export const MARKETINGLOG_STORAGE_LEARNBODYMARKET = 'LearnBodyMarketLog'
export const MARKETINGLOG_STORAGE_DIETMEALWHATTOEAT = 'DietMealWhatToEatLog'
export const MARKETINGLOG_STORAGE_MARKETING = 'MarketingLog'

export default {
  namespaced: true,
  actions: {
    async setMarketingLog ({ state, commit, dispatch }, payload) {
      var logTableName = payload.logTableName === null || payload.logTableName === undefined ? MARKETINGLOG_STORAGE_LEARNBODYMARKET : payload.logTableName
      getLBData(ENDPOINT_MARKETINGLOG, 'post', {
        mallAccessToken: cookies.get('ncpAccessToken'),
        marketingLogData: payload.marketingLogData,
        dbTargetCode: payload.dbTargetCode,
        mallProductNo: payload.mallProductNo,
        languageCode: '',
        countryCode: '',
        osVersion: '',
        phoneModel: '',
        appType: cookies.get('appType'),
        appVersion: cookies.get('appVersion'),
        logCode: payload.logCode,
        logTableName: logTableName
      })
    }
  }
}
