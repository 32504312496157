import { getLBData } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_HOTDEALBRANDLIST = 'HotDeal/HotDealBrandList'

export default {
  namespaced: true,
  state: {
    hotDealBrandList: null,
    selectedHotDealNo: cookies.get('selectedHotDealNo') === 'null' || cookies.get('selectedHotDealNo') === undefined ? '0' : cookies.get('selectedHotDealNo'),
    needSendClickLog: true
  },
  actions: {
    async getHotdealBrandList ({ state, commit, dispatch }, payload) {
      // 토큰 조회
      payload.mallAccessToken = cookies.get('ncpAccessToken')
      payload.appType = cookies.get('appType')
      payload.appVersion = cookies.get('appVersion')

      const resp = await getLBData(ENDPOINT_HOTDEALBRANDLIST, 'get', payload)
      state.hotDealBrandList = resp.data

      console.log(resp.data)
      commit('SET_SELECTED_HOTDEAL_NO', state.selectedHotDealNo)
      return resp.data
    }
  },
  mutations: {
    SET_SELECTED_HOTDEAL_NO (state, hotDealNo) {
      state.selectedHotDealNo = hotDealNo

      if (hotDealNo + '' !== '0') {
        var nSelectedCnt = 0
        state.hotDealBrandList = state.hotDealBrandList.filter((w) => {
          if (w.hotDealNo + '' === hotDealNo + '') {
            w.selected = true
            nSelectedCnt += 1
          } else {
            w.selected = false
          }
          return w.hotDealNo !== null && w.hotDealNo !== undefined
        })

        if (nSelectedCnt === 0) {
          state.hotDealBrandList[0].selected = true
          state.selectedHotDealNo = state.hotDealBrandList[0].hotDealNo
        }

        cookies.set('selectedHotDealNo', state.selectedHotDealNo, '1d')
      } else {
        state.hotDealBrandList[0].selected = true
      }
    },
    INIT_NEED_SEND_CLICK_LOG (state) {
      state.needSendClickLog = false
    }
  }
}
