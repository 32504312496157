<template>
    <header id="header" :class="{'small':!checkVisible}">
        <div class="feed_main_tab">
            <div class="feed_main_tab_content" :class="{'active':isSelectedMarket}" @click="clickMarket()">
                <span class="tab-icon market"></span>마켓
            </div>
            <div class="feed_main_tab_content crew" :class="{'active':isSelectedCrewApply}" v-if="showCrewApply" @click="clickCrewApply()">
                <span class="tab-icon crew"></span>크루
            </div>
            <div class="feed_main_tab_content" :class="{'active':isSelectedFeed}" @click="clickFeed()">
                <span class="tab-icon feed"></span>피드
            </div>
            <span class="feed_main_tab_content" @click="clickDiary()">다이어리</span>
        </div>
        <LBFeedMainTopCategory v-if="checkVisible" />
    </header>
</template>
  
<script setup>
import config from '@/config'
import cookies from 'js-cookie'
import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import LBFeedMainTopCategory from "../LBFeed/LBFeedMainTopCategory"
import feedIcon from '@/assets/image/LBFeed/feedIcon.png'
import { useStore } from "vuex";
import router from '@/router'
import { commonAppCall } from '@/utils/utils'
import { getDateFromIssueYmdt } from '@/utils/dateUtils'
import { MARKETINGLOG_STORAGE_MARKETING } from '@/store/modules/lbMarketingLog'

const store = useStore()
const route = useRoute()

const checkVisible = computed(() => {
  if (route.path === '/LBFeed') {
    return true
  } else return false
})

const isSelectedFeed = computed(() => {
  if (route.path === '/LBFeed') {
    return true
  } else return false
})

const isSelectedMarket = computed(() => {
  if (route.path === '/LBMarket') {
    return true
  } else return false
})

const showCrewApply = computed(() => {
  console.log('showCrewApply')
  return false
  /* 크루 기능 잠정 중단
  let userAge = cookies.get('userAge')
  userAge = userAge === undefined || userAge === null ? store.state.lbProfile.profileInfo?.profile?.age : userAge
  userAge = userAge !== undefined && userAge !== null ? Number(userAge) : 0
  let userGender = cookies.get('userGender')
  userGender = userGender === undefined || userAge === null ? store.state.lbProfile.profileInfo?.profile?.gender : userGender
    
  // 날짜조건 제거 
  // let dateExpire = getDateFromIssueYmdt('2023-10-22 15:00:00')
  // 30대 / 여성 / 크루탭을 보지 않은 경우 크루탭으로 자동랜딩
  if ((userAge >= 18 && userAge <= 49)
  // && new Date() < dateExpire
  && 'F' === userGender) return true
  else return false
  */
})

const isSelectedCrewApply = computed(() => {
  if (route.path === '/LBCrewApply') {
    return true
  } else return false
})

const routerMove = (index) => {
    if (!store.state.lbFeed.selectedValue) {
        store.state.lbFeed.selectedValue = 0
    }
    /* 
    router.push({
        path: '/LBFeed'
    })
     */
}

const callAppChangeTab = (tabName) => {
  if (config.isLocalDevelopment) return

  let data = {
    'ViewCode': 1,
    'RendingCode': 'CHANGE_LEARNBODY_TAB_CONTENTS',
    'RendingData': tabName
  }

  commonAppCall(data, true)
}

const clickDiary = () => {
  MainTabClick('DIARY')
  callAppChangeTab('DIARY')
}

const clickFeed = () => {
  MainTabClick('LEARNBODY')
  callAppChangeTab('LEARNBODY')
  showFeed()
}

const showFeed = () => {
  routerMove()
  router.push({
    path: '/LBFeed'
  })
}

const clickMarket = () => {
  MainTabClick('MARKET')
  callAppChangeTab('MARKET')
  showMarket()
}

const showMarket = () => {
  router.push({
    path: '/LBMarket'
  })
}

const clickCrewApply = () => {
  MainTabClick('CREWAPPLY')
  router.push({
    path: '/LBCrewApply'
  })
}

const changeMainTab = (tab) => {
  if (tab === 'FEED') clickFeed()
  else if (tab === 'MARKET') clickMarket()
}

/**
 * 로그 함수
 */
const MainTabClick = (tab) => {
  let logCode = 'MainTabClick'
  let tabType = 'LearnBodyFeed'

  if ('DIARY' === tab) {
    tabType = 'LearnBodyDiary'
  } else if ('MARKET' === tab) {
    tabType = 'LearnBodyMarket'
  }

  let marketingLogData = JSON.stringify({
    UID: cookies.get('memberCummunityUID'),
    TabType: tabType
  })
  store.dispatch('lbMarketingLog/setMarketingLog', { marketingLogData: marketingLogData, logCode: logCode, logTableName: MARKETINGLOG_STORAGE_MARKETING })
}

onMounted(() => {
  // 초기 진입시 선택돼있지 않은 경우
  if (route.path !== '/LBFeed' && route.path !== '/LBMarket') {
    // showFeed()
    // 초기화면은 마켓으로 한다.
    showMarket()
  }

  window.ChangeMainTab=changeMainTab
})
</script>

<style scoped>
.feed_main_tab_content_image {
    margin: 5px 0px 0px 0px
}

#header {
    height: 80px;
    padding: 0px
}

#header.small {
  height: 45px;
}

.feed_main_tab {
  height: 45px;
  display: flex;
  justify-content: space-around;
  padding: 0px 11px 0px 11px;
  flex-wrap: nowrap;
  width: 100%;
  border-bottom: 1px solid #f0f2f5;
}

.feed_main_tab_content {
  display: flex;
  justify-content: center;
  width: 25%;
  align-items: center;
  font-size: 16px;
  color: #b3bbc5;
  letter-spacing: -2px;
}
.feed_main_tab_content.crew::after {
  content: '';
  display: block;
  width: 17px;
  height: 10px;
  background: url(../../assets/image/LBFeed/crewBadgeBeta.png) no-repeat center;
  background-size: contain;
  transform: translateY(-10px);
}

.feed_main_tab_content_image_cover {
    display: flex;
    justify-content: center;
    
    align-items: center;
    font-size: 16px;
    color: #b3bbc5;
}

.feed_main_tab_content.active {
  font-weight: bold;
  position: relative;
  color: #3D4861;
  border-bottom: 3px solid #182031;
  font-weight: bold;
}
.feed_main_tab_content.active .tab-icon {
  display: block;
}
.icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  background: url(../../assets/image/ic_hotdealTimer_16.png) no-repeat center;
  background-size: contain;
}
.tab-icon {
  display: none;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.tab-icon.feed {
  background: url(../../assets/image/LBFeed/feedIcon.png) no-repeat center center;
  background-size: contain;
}
.tab-icon.market {
  background: url(../../assets/image/LBMarket/ic_shoppingbag_v2.png) no-repeat center center;
  background-size: contain;
}
.tab-icon.crew {
  background: url(../../assets/image/LBFeed/crewIcon.png) no-repeat center center;
  background-size: contain;
}
.feed-main-top-category-wrap {
  width:100%; border-bottom: 1px solid #f0f2f5;
  /* transform: translateY(0); */
}
.feed-main-top-category-wrap.non-visible {
  /* transform: translateY(-100%); */
  display: none;
}
</style>
