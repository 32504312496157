import { getLBData, getLBDiaryData } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_PROFILE_INFO = 'Profile/ProfileInfo'
const ENDPOINT_PROFILE_INFO_UPDATE = 'Profile/ProfileInfoReg'
const ENDPOINT_FOLLOWLIST = 'Profile/FollowList'
const ENDPOINT_UPDATE_FOLLOW = 'Profile/FollowReg'
const ENDPOINT_FEED_CATEGORYFEED = 'Feed/CategoryFeed'
const ENDPOINT_USERINFO = 'V1/UserInfo'

const defaultParams = {
  appType: cookies.get('appType'),
  appVersion: cookies.get('appVersion'),
  targetType: 'USER',
  mallAccessToken: null,
  memberNo: 0,
  languageCode: 'ko',
  countryCode: '82',
  whereToUseCode: 'FEED',
  utcInterval: -540,
  logCode: '',
}

export default {
  namespaced: true,
  state: {
    profileInfo: {},
    followList: [],
    feedList: [],
    surveyList: [],
    userInfo: {}
  },
  actions: {
    //로그인한 사용자의 정보 조회
    async getLoginUserInfo({ state }) {
      const resp = await getLBDiaryData(ENDPOINT_USERINFO, 'post', {
        uId: cookies.get('memberCummunityUID'),
        appType: cookies.get('appType'),
        appVersion: cookies.get('appVersion'),
        languageCode: 'ko',
        countryCode: '82',
        whereToUseCode: 'FEED',
        cuid: 0,
        challengeId: 0,
        utcInterval: -540,
        logCode: ''
      })

      if (resp.data !== undefined && resp.data !== null) {
        state.userInfo = resp.data
      }
    },
    async getProfileInfo({ state }, payload) {
      payload = {
        ...defaultParams,
        ...payload,
      }

      const resp = await getLBData(ENDPOINT_PROFILE_INFO, 'post', payload)
      state.profileInfo = resp.data

      let gender = state.profileInfo?.profile?.gender
      if (gender) {
        // App 정보는 만료시간 360일 후 설정
        const expAppInfo = new Date()
        expAppInfo.setTime(expAppInfo.getTime() + 1000 * 60 * 60 * 24 * 30 * 12)
        cookies.set('userGender', gender, { 'expires': expAppInfo })
      }

      let age = state.profileInfo?.profile?.age
      if (age) {
        // App 정보는 만료시간 360일 후 설정
        const expAppInfo = new Date()
        expAppInfo.setTime(expAppInfo.getTime() + 1000 * 60 * 60 * 24 * 30 * 12)
        cookies.set('userAge', age, { 'expires': expAppInfo })
      }

      return resp.data
    },
    async updateProfileInfo({ state }, payload) {
      payload = {
        ...defaultParams,
        ...payload,
      }
      const resp = await getLBData(ENDPOINT_PROFILE_INFO_UPDATE, 'post', payload)
      return resp.data
    },
    // 팔로우
    async getFollowList({ state }, payload) {
      payload.mallAccessToken = null
      payload.memberNo = 0
      payload.appType = 'ios'
      payload.appVersion = '2.4'
      payload.languageCode = 'ko'
      payload.countryCode = 82
      payload.whereToUseCode = 'FEED'
      payload.logCode = ''
      payload.utcInterval = -540
      const resp = await getLBData(ENDPOINT_FOLLOWLIST, 'post', payload)
      state.followList = resp.data.followList
      return resp.data
    },
    async updateFollow({ state }, payload) {
      payload.followType = 'USER'
      payload.mallAccessToken = null
      payload.memberNo = 0
      payload.appType = 'ios'
      payload.appVersion = '2.4'
      payload.languageCode = 'ko'
      payload.countryCode = '82'
      payload.whereToUseCode = 'FEED'
      payload.logCode = 'string'
      payload.utcInterval = -540
      const resp = await getLBData(ENDPOINT_UPDATE_FOLLOW, 'post', payload)
      return resp.data
    },
    async getFeedList({ state }, payload) {
      payload.filterValueObject = {
        campaignCodeArrayString: null,
        genderCode: null,
        ageGroupCodeArrayString: null,
        characteristicTagCodeArrayString: null,
        recordMealCodeArrayString: null,
        mealProteinCodeArrayString: null,
        mealInevitableSituationCodeArrayString: null,
        mealCookingDifficultyCodeArrayString: null,
        mealSituationCodeArrayString: null,
        workoutTypeCodeArrayString: null,
        tipTypeCodeArrayString: null,
        tabOrderCode: null,
      }
      payload.feedCategoryCode = 'STORY'
      payload.pageNum = 1
      payload.mallAccessToken = null
      payload.memberNo = 0
      payload.appType = 'ios'
      payload.appVersion = '2.4'
      payload.languageCode = 'ko'
      payload.countryCode = '82'
      payload.whereToUseCode = 'FEED'
      payload.logCode = 'string'
      payload.utcInterval = -540
      const resp = await getLBData(ENDPOINT_FEED_CATEGORYFEED, 'post', payload)
      state.feedList = resp.data.feedList
      return resp.data
    },
  },
}
