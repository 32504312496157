import { getLBData } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_ROUTINEINFO = 'ImitateOrder/RoutineInfo'
const ENDPOINT_RECORDMEALTINFO = 'ImitateOrder/RecordMealInfo'
const ENDPOINT_MALLPRODUCTGROUPINFO = 'ImitateOrder/MallProductGroupInfo'

export default {
  namespaced: true,
  state: {
    dieterRoutineInfo: null,
    dieterMealInfo: null,
    dieterProductGroupInfo: null,
    dieterUserInfo: null,
    pageSelectedInfo: {
      selectedTab: -1,
      tabs: [
        {
          title: '일별',
          sort: 0,
          isSelected: true
        },
        {
          title: '끼니',
          sort: 1,
          isSelected: false
        },
        {
          title: '식품',
          sort: 2,
          isSelected: false
        }
      ],
      nowRoutineChipIdx: -1,
      nowMealChipIdx: -1,
      nowProductChipIdx: -1
    }
  },
  actions: {
    async getDieterRoutinInfo ({ state, commit, dispatch }, payload) {
      // 토큰 조회
      // 성공 스토리 인덱스
      // payload.imitateOrderIdx = payload.imitateOrderIdx
      // 성공 스토리의 루틴 인덱스
      // payload.routineNo = payload.routineNo
      payload.mallAccessToken = cookies.get('ncpAccessToken')
      payload.uId = cookies.get('memberCummunityUID')
      payload.appType = cookies.get('appType')
      payload.appVersion = cookies.get('appVersion')
      payload.languageCode = 'ko'
      payload.countryCode = '82'
      payload.whereToUseCode = 'DIARY'
      payload.utcInterval = -540

      const resp = await getLBData(ENDPOINT_ROUTINEINFO, 'post', payload)
      state.dieterRoutineInfo = resp.data.routineMealList
      return resp.data
    },
    async getDieterMealInfo ({ state, commit, dispatch }, payload) {
      // 토큰 조회
      // 성공 스토리 인덱스
      // payload.imitateOrderIdx = payload.imitateOrderIdx
      // 성공 스토리의 식단 인덱스
      // payload.diaryRecordMealCode = payload.diaryRecordMealCode
      payload.mallAccessToken = cookies.get('ncpAccessToken')
      payload.uId = cookies.get('memberCummunityUID')
      payload.appType = cookies.get('appType')
      payload.appVersion = cookies.get('appVersion')
      payload.languageCode = 'ko'
      payload.countryCode = '82'
      payload.whereToUseCode = 'DIARY'
      payload.utcInterval = -540

      const resp = await getLBData(ENDPOINT_RECORDMEALTINFO, 'post', payload)
      state.dieterMealInfo = resp.data.recordMealList
      return resp.data
    },
    async getDieterProductGroupInfo ({ state, commit, dispatch }, payload) {
      // 토큰 조회
      // 성공 스토리 인덱스
      // payload.imitateOrderIdx = payload.imitateOrderIdx
      // 성공 스토리의 상품 그룹 인덱스
      // payload.mallProductGroupIdx = payload.mallProductGroupIdx
      payload.mallAccessToken = cookies.get('ncpAccessToken')
      payload.uId = cookies.get('memberCummunityUID')
      payload.appType = cookies.get('appType')
      payload.appVersion = cookies.get('appVersion')
      payload.languageCode = 'ko'
      payload.countryCode = '82'
      payload.whereToUseCode = 'DIARY'
      payload.utcInterval = -540

      const resp = await getLBData(ENDPOINT_MALLPRODUCTGROUPINFO, 'post', payload)
      state.dieterUserInfo = resp.data.userInfo
      state.dieterProductGroupInfo = resp.data.mallProductGroupList
      return resp.data
    }
  },
  mutations: {
    INIT_PAGE_SELECTED_INFO (state) {
      state.pageSelectedInfo = {
        selectedTab: -1,
        tabs: [
          {
            title: '일별',
            sort: 0,
            isSelected: true
          },
          {
            title: '끼니',
            sort: 1,
            isSelected: false
          },
          {
            title: '식품',
            sort: 2,
            isSelected: false
          }
        ],
        nowRoutineChipIdx: -1,
        nowMealChipIdx: -1,
        nowProductChipIdx: -1
      }
    },
    SET_SELECTED_TAB (state, idx) {
      state.pageSelectedInfo.selectedTab = idx
      state.pageSelectedInfo.tabs.forEach(item => {
        if (item.sort === idx) {
          item.isSelected = true
        } else {
          item.isSelected = false
        }
      })
    },
    SET_SELECTED_ROUTINE_CHIP_IDX (state, idx) {
      state.pageSelectedInfo.nowRoutineChipIdx = idx
    },
    SET_SELECTED_MEAL_CHIP_IDX (state, idx) {
      state.pageSelectedInfo.nowMealChipIdx = idx
    },
    SET_SELECTED_PRODUCT_CHIP_IDX (state, idx) {
      state.pageSelectedInfo.nowProductChipIdx = idx
    }
  }
}
