<template>
  <header id="header" :class="[{'hide': !showNavbar}, className ]">
    <div class="header-wrap">
      <p v-if="type!=='main'" @click="goBack" class="btn_home"></p>
      <slot></slot>
      <TopMenu :type="type" v-if="type =='main'" ></TopMenu>
    </div>
    <LBLoading></LBLoading>
  </header>
</template>

<script>
import TopMenu from './TopMenu'
import LBLoading from '../common/LBLoading'
import $ from 'jquery'
import { historyPage } from '@/utils/utils'

export default {
  data () {
    return {
      showNavbar: true,
      lastScrollPosition: 0
    }
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: val => ['main', 'sub', 'search'].indexOf(val) > -1
    }
  },
  computed: {
    className () {
      return {
        'main_header': this.type === 'main',
        'sub_header': this.type === 'sub' || this.type === 'search',
        'sch_header': this.type === 'search'
      }
    }
  },
  components: {
    TopMenu, LBLoading
  },
  mounted () {
    document.getElementById('LBloader').style.display = 'flex'
      setTimeout(() => {
        document.getElementById('LBloader').style.display = 'none'
    }, 500)
    this.lastScrollPosition = window.pageYOffset
    window.addEventListener('scroll', this.onScroll)
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    goBack () {
      historyPage()
      // this.$nextTick(() => this.$refs.solutionDetails.showCurrent(comm and))
    },
    onScroll () {
      if (window.pageYOffset < 0) {
        return
      }
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < 30) {
        return
      }
      this.showNavbar = window.pageYOffset < this.lastScrollPosition
      this.lastScrollPosition = window.pageYOffset
      if (this.showNavbar) {
        $('.sub_menu').css('top', '50px')
      } else {
        $('.sub_menu').css('top', '0')
      }
    }
  }
}
</script>

<style scoped>
.main_header.hide {
  transform: translate3d(0, -100%, 0) !important;
}
</style>
