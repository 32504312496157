import { getLBData } from '@/api'
import cookies from 'js-cookie'

const endpointDetail = 'dietmeal/Detail'
const endpointSetFavorite = 'dietmeal/SetFavorite'

export default {
  namespaced: true,
  state: {
    dietMealData: null,
    dietMealWhatToEatDetail: null,
    recipeMallProductList: [],
    dietMealPrevNext: null,
    mallProductNo: null,
    dietMealWhatToEatIDX: null,
    enterSubstituteFood: false
  },
  actions: {
    async getDietMealDetail ({ state, commit, dispatch }, payload) {
      // 1. API를 통해서 MallProduct 리스트를 가져온다
      // 2. 반환값을 받아서 state에 적용
      // api
      //  console.log(state.parameter)
      payload.mallAccessToken = cookies.get('ncpAccessToken')
      payload.appType = cookies.get('appType')
      payload.appVersion = cookies.get('appVersion')
      const resp = await getLBData(endpointDetail, 'get', payload)
      state.dietMealData = resp.data
      console.log(resp.data)
      state.dietMealWhatToEatDetail = resp.data.dietMealWhatToEatDetail
      state.recipeMallProductList = resp.data.recipeMallProductList
      state.dietMealPrevNext = resp.data.dietMealPrevNext
      state.mallProductNo = resp.data.mallProductNo
      state.dietMealWhatToEatIDX = resp.data.dietMealWhatToEatIDX
    },
    async setDietMealFavorite ({ state, commit, dispatch }, payload) {
      // 1. API를 통해서 MallProduct 리스트를 가져온다
      // 2. 반환값을 받아서 state에 적용
      // api
      //  console.log(state.parameter)

      await getLBData(endpointSetFavorite, 'post', {mallAccessToken: cookies.get('ncpAccessToken'), dietMealWhatToEatIDX: payload.dietMealWhatToEatIDX, isFavorite: payload.isFavorite, appType: '', appVersion: ''})
    }
  },
  mutations: {
    INIT_ENTER_SUBSTITUTE_FOOD (state) {
      state.enterSubstituteFood = false
    },
    SET_ENTER_SUBSTITUTE_FOOD (state) {
      state.enterSubstituteFood = true
    }
  }
}
