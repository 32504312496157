// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/image/btn_back@3x.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#contents.cart_list_con{padding-top:0}.header-title-box .icon-close{width:40px;height:40px;display:inline-block;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;background-size:contain}\n", "",{"version":3,"sources":["webpack://./src/components/header/CategoryHeader.vue"],"names":[],"mappings":"AAEA,wBAAyB,aAAc,CAAG,8BAEtC,UAAW,CAAE,WAAY,CAAE,oBAAqB,CAChD,mEAAoE,CACpE,uBAAwB","sourcesContent":["\n\n#contents.cart_list_con {padding-top: 0;}\n.header-title-box .icon-close {\n    width: 40px; height: 40px; display: inline-block;\n    background: url(../../assets/image/btn_back@3x.png) no-repeat center;\n    background-size: contain;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
