<template>
  <div class="invite-deny-popup" v-if="introPage">
      <div class="invite-deny-popup-wrap">
          <div class="invite-deny-popup-content">
            <div class="content-wrap">
                    <p class="content-title">회원님께서는 이미<br>구매 이력이 있으시네요!</p>
                    <div class="content-sub">
                        <p class="desc">
                          구매 이력이 있으신 경우, 친구 초대 쿠폰을 받으실 수 없습니다🥲
                        </p>
                        <p class="desc2">
                            <span class="icon"></span>
                            새로운 친구를 초대하시고<br>
                            <span class="boldT">적립금 5,000원</span> 혜택을 받으세요 :)
                        </p>
                    </div>
                </div>
          <p @click="getStart" class="footer_btn start"><span>시작하기!</span></p>
          </div>
      </div>
  </div>
</template>

<script>
// import $ from 'jquery'
import { removeClass } from '@/utils/utils'
import cookies from 'js-cookie'

export default {
  data () {
    return {
      introPage: true
    }
  },
  components: {
  },
  methods: {
    getStart () {
        cookies.remove('isShowInvitationDenyPopup')
        this.introPage = false
        removeClass(document.body, 'noscroll_class')
    },
    marketingLog (val) {
        let logCode = 'MarketHomeBrandingPopupClick'
        let marketingLogData = JSON.stringify({
            marketLogCode: logCode,
            marketLogValue: val
        })
        this.$store.dispatch('lbMarketingLog/setMarketingLog', { marketingLogData: marketingLogData, logCode: logCode })
    }
  },
  created () {
  },
  mounted () {
  }
}
</script>

<style>
.invite-deny-popup {
    width: 100%; height: 100vh;
    position: fixed; top: 0; left: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 9999999;
}
.invite-deny-popup-wrap {
    width: 100%; height: 100%;
    display: flex; justify-content: center; align-items: center;
    }
.invite-deny-popup-content {
    width: 295px; height:325px; background-color: white;
    padding: 24px 0 0; position: relative;
    border-radius: 10px; box-shadow: 0 3px 6px rgba(0,0,0,0.16);
}
.invite-deny-popup-content .content-wrap {
    padding: 0 20px; position: relative; height: 100%;
}
.invite-deny-popup-content .content-title {
  font-size: 22px; font-weight: bold; color: black;
  word-spacing: -3px;
  display: inline-block; padding: 0;
}

.invite-deny-popup-content .content-sub {
  font-size: 14px; font-weight: 500; color: black;
  position: relative; text-indent: 24px;
  margin-top: 23px;
}
.invite-deny-popup-content .content-sub .desc {
  font-size: 14px; font-weight: 500;
  position: relative; text-indent: 0px;
  margin-top: 20px;
}
.invite-deny-popup-content .content-sub .desc2 {
  margin-top: 23px;
}
.invite-deny-popup-content .content-sub .boldT{
  font-weight: bold;
}
.invite-deny-popup-content .content-sub .icon {
    width: 24px; height: 24px; display: inline-block;
    background: url(../../assets/image/ic_page2_2.png) no-repeat center;
    background-size: contain;
    position: absolute; left:0;
}

.invite-deny-popup .footer_btn {
    width: 100%; height: 40px;
    position: absolute; bottom: 16px;
    padding: 0 20px; left: 0;
}
.invite-deny-popup .footer_btn span {
    display: block; height: 100%; text-align: center;
    width: 100%; background-color: #EC2618; color: white;
    font-size: 16px; font-weight: 500; line-height: 40px;
    border-radius: 4px;
}
</style>