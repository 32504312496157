<template>
  <div class="loading-progress-bar" id="LBloader">
      <div class="loading-wrap">
          <div class="progress-bar"></div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.loading-progress-bar {
    width: 100%; height: 4px;
    position: absolute; bottom: 0; left: 0;
}
.loading-progress-bar .loading-wrap {
    width: 100%; height: 4px; background-color: rgba(236, 236, 236, 0.6);
}
.loading-progress-bar .loading-wrap .progress-bar {
    position: absolute;
    width: 100%; height: 4px; background-color: #EC2619;
    animation-name: widthAction;
    animation-duration: 0.5s;
    animation-fill-mode: backwards ;
    animation-delay: 0;
    animation-timing-function: ease-in-out;
}
@-webkit-keyframes widthAction {
    from {width: 0;}
    to {width: 100;}
}
</style>