import { getLBData } from '@/api'

export default {
  namespaced: true,
  state: {
    mainHotdelProductList: [],
    mallProductNews: [],
    mallProductHotdeals: [],
    mallProductHotdealItems: [],
    mallProductHotdeal: {},
    mallProductList: [],
    mallProductHotdealTopBanner: {},
    mallProductHotdealDetailBanner: {},
    hotDealNo: 0,
    pageHotdealsInfo: {
      page: 0,
      pageSize: 0,
      pageCount: 0,
      totalCount: 0
    },
    pageHotdealInfo: {
      page: 0,
      pageSize: 0,
      pageCount: 0,
      totalCount: 0
    },
    hotDealExpireDate: null
  },
  actions: {
    async getMallProductHotdeals ({ state, commit, dispatch }, payload) {
      const lbResponse = await getLBData(`HotDeal/HotDealGroup`, 'get')
      state.mallProductHotdeals = lbResponse.data

      // 브랜드별 배열을 하나의 배열로 목록화
      let tempHotdealItems = []
      state.mallProductHotdeals.forEach(hotdealData => {
        hotdealData.products.forEach(item => {
          item.hotDealNo = hotdealData.hotDealNo
          item.brandNames = hotdealData.brandNames
          tempHotdealItems.push(item)
        })
      })
      state.mallProductHotdealItems = tempHotdealItems
    },
    async getMallProductHotdeal ({ state, commit, dispatch }, payload) {
      const hotdealData = await getLBData(`HotDeal/HotDeal?hotDealNo=${payload.hotDealNo}`, 'get')

      state.mallProductHotdeal = hotdealData.data
    },
    async getMallProductNews ({ state, commit, dispatch }, payload) {
      const lbResponse = await getLBData(`HotDeal/NewGroup`, 'get')

      state.mallProductNews = lbResponse.data
    },
    async getHotDealEndDate ({ state, commit, dispatch }, hotDealNo) {
      hotDealNo = hotDealNo === undefined ? 0 : hotDealNo
      const lbResponse = await getLBData(`HotDeal/HotDealEndDate`, 'get', { hotDealNo: hotDealNo })
      state.hotDealExpireDate = lbResponse.data.endDate
    },
    async getHotDealProductCheck ({ state }, mallProductNo) {
      mallProductNo = mallProductNo === undefined ? 0 : mallProductNo
      const lbResponse = await getLBData(`/HotDeal/HotDealProductCheck`, 'get', { mallProductNo: mallProductNo })
      return lbResponse.data
    }
  },
  mutations: {
    INIT_HOTDEAL_BRAND_PRODUCTS (state) {
      state.mallProductHotdeal = []
    }
  }
}
