<template>
  <footer id="footer" v-show="!page.initDataFetching">
    <!-- <div class="footer_link">
      <div class="fl_inner">
        <router-link to="/etc/agree" active-class="is_selected">이용약관</router-link>
        <router-link to="/etc/privacy" active-class="is_selected" style="font-weight: bold;">개인정보처리방침</router-link>
        <a target="_blank" @click.prevent="openView">사업자정보확인</a>
        <a @click.prevent="maillTo">입점문의</a>
      </div>
    </div> -->
    <div class="footer_info">
      <address class="address">
        <p class="footer_info_title">입점문의</p>
        <dl>
          <dt>판매자</dt>
          <dd><a @click.prevent="goLinkSeller" style="text-decoration: underline;">제품 입점 신청></a></dd>
        </dl>
        <dl>
          <dt>구매자</dt>
          <dd><a @click.prevent="goLinkConsumer" style="text-decoration: underline;">이 제품도 입점해 주세요></a></dd>
        </dl>
        <p class="footer_info_title" style="margin-top: 21px;">문의</p>
        <dl>
          <dt>고객센터</dt>
          <dd><a @click.prevent="goLinkKakao" style="text-decoration: underline;">카카오톡 문의></a></dd>
        </dl>
        <p class="footer_info_title" style="margin-top: 21px;">사업자 정보</p>
        <dl>
          <dt>상호명</dt>
          <dd>주식회사 런바디</dd>
        </dl>
        <dl>
          <dt>대표</dt>
          <dd v-if="footerInfo">{{ footerInfo.representativeName }}</dd>
        </dl>
        <dl>
          <dt>주소</dt>
          <dd v-if="footerInfo">
            {{ footerInfo.zipCd }} {{ footerInfo.address }} <br> {{ footerInfo.addressDetail }}
          </dd>
        </dl>
        <dl>
          <dt>
            <router-link :to="`/etc/faq`">전화번호</router-link>
          </dt>
          <dd>
            <a @click.prevent="telTo" v-if="footerInfo">{{ footerInfo.phoneNo }}</a>
          </dd>
        </dl>
        <dl>
          <dt>사업자등록번호</dt>
          <dd>354-87-00835</dd>
        </dl>
        <dl>
          <dt>전자우편</dt>
          <dd><a @click.prevent="maillTo">{{ footerEmail }}</a></dd>
        </dl>
        <dl>
          <dt>통신판매업신고</dt>
          <dd>제2018-서울강남-04672호</dd>
        </dl>
        <!-- <dl>
          <dt>상호</dt>
          <dd v-if="footerInfo">{{ footerInfo.companyName }}</dd>
        </dl> -->
      </address>
      <!-- <div class="registration">
        <dl>
          <dt>개인정보관리 책임자</dt>
          <dd v-if="footerInfo">{{ footerInfo.privacyManagerName }}</dd>
        </dl>
        <dl>
          <dt>통신판매업신고번호</dt>
          <dd v-if="footerInfo">{{ footerInfo.onlineMarketingBusinessDeclarationNo }}</dd>
        </dl>
        <dl>
          <dt>사업자 등록번호</dt>
          <dd v-if="footerInfo">{{ footerInfo.businessRegistrationNo }}</dd>
        </dl>
      </div> -->
    </div>
    <go-top-button v-if="this.$router.options.history.location === '/LBMarket'"></go-top-button>
  </footer>
</template>

<script>
import GoTopButton from './GoTopButton'
import { mapState, mapGetters } from 'vuex'
import { appOutLink } from '@/utils/utils'

export default {
  data () {
    return {
      footerEmail: 'learnbodymarket@learnbody.co.kr'
    }
  },
  components: {
    GoTopButton
  },
  computed: {
    ...mapState(['page']),
    ...mapGetters('common', ['footerInfo'])
  },
  methods: {
    openView () {
      const href = 'http://www.ftc.go.kr/bizCommPop.do?wrkr_no=6038111270'
      if (this.$store.state.osType() === 'WEB') {
        window.open(href)
      } else {
        window.location.href = 'buywow://newBrowser?url=' + encodeURIComponent(href)
      }
    },
    maillTo () {
      if (this.footerInfo) {
        if (this.$store.state.osType() !== 'AOS') {
          window.location.href = `mailto:${this.footerEmail}`
        } else {
          alert(`메일(${this.footerEmail})로 문의해주세요.`)
        }
      }
    },
    telTo () {
      if (this.footerInfo) {
        window.location.href = `tel:${this.footerInfo.phoneNo}`
      }
    },
    goLinkSeller () {
      const href = 'https://forms.gle/2KtNrDYkxeLwAdef7'
      appOutLink(href)
    },
    goLinkConsumer () {
      const href = 'https://forms.gle/CRC8uQvke3uSjvNf9'
      appOutLink(href)
    },
    goLinkKakao () {
      const href = 'https://pf.kakao.com/_sxjlsb/chat'
      // 22.10.17 카카오톡 플러스친구 서비스 문제로 임시 변경
      // const href = 'https://open.kakao.com/o/sQyYM2hb'
      appOutLink(href)
    }
  }
}
</script>
