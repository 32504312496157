
import { getLBData } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_MIXDISPLAYCATEGORY = 'Product/MixDisplayCategory'

export default {
  namespaced: true,
  state: {
    recommendedFilterList: [],
    displayCategoryList: []
  },
  actions: {
    async getMixDisplayCategory ({ state, commit, dispatch }) {
      if (state.recommendedFilterList.length === 0 || state.displayCategoryList.length === 0) {
        const resp = await getLBData(ENDPOINT_MIXDISPLAYCATEGORY, 'post', { MallAccessToken: cookies.get('ncpAccessToken'), uid: cookies.get('memberCummunityUID'), appType: cookies.get('appType'), appVersion: cookies.get('appVersion'), callLocation: null })
        if (resp.data !== undefined && resp.data !== null) {
          state.recommendedFilterList = resp.data.recommendedFilterList
          state.displayCategoryList = resp.data.displayCategoryList
        }
      }
    }
  }
}
