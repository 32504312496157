import { getLBData } from '@/api'
import cookies from 'js-cookie'
import { isEqualOrAfterYmdt, parseYmd, dateAddDay, formatYmd } from '@learnbody/date'
import { isAppleDevice } from '@/utils/utils'

const defaultParams = {
  mallAccessToken: cookies.get('ncpAccessToken'),
  memberNo: cookies.get('userMemberNo'),
  appType: cookies.get('appType'),
  appVersion: cookies.get('appVersion'),
  languageCode: 'ko',
  countryCode: '82',
  whereToUseCode: '',
  utcInterval: -540,
  logCode: ''
}

export default {
  namespaced: true,
  state: {
    homeRollingBannerList: [],
    eventCoupon: null,
    isDownloadResult: null,
    hasCoupon: null,
    // 쿠폰을 받았는지 여부
    isInEventPeriod: true,
    eventAccumuationPageData: null
  },
  actions: {
    async getHomeRollingBannerList ({ state, commit, dispatch }, payload) {
      payload.mallAccessToken = cookies.get('ncpAccessToken')
      payload.appType = cookies.get('appType')
      payload.appVersion = cookies.get('appVersion')

      const lbResponse = await getLBData(`Marketing/HomeRollingBannerList`, 'post', payload)
      state.homeRollingBannerList = lbResponse.data
    },
    async getEventCoupon ({ state, commit, dispatch }, payload) {
      payload.mallAccessToken = cookies.get('ncpAccessToken')
      payload.appType = cookies.get('appType')
      payload.appVersion = cookies.get('appVersion')

      const lbResponse = await getLBData(`Marketing/EventCoupon`, 'post', payload)
      state.eventCoupon = lbResponse.data
      // // 해당 코드만 띠배너를 위한 값 저장
      // if (lbResponse.data.eventTypeCode === 'EVENT_DOWNLOAD') {
      //   state.hasCoupon = lbResponse.data.isDownloadCompleted
      //   console.log(state.hasCoupon)
      // }
    },
    async getEventCouponDownload ({ state, commit, dispatch }, payload) {
      payload.eventTypeCode = state.eventCoupon.eventTypeCode
      payload.mallAccessToken = cookies.get('ncpAccessToken')
      payload.appType = cookies.get('appType')
      payload.appVersion = cookies.get('appVersion')

      await getLBData(`Marketing/EventCouponDownload`, 'post', payload).then((res) => {
        state.isDownloadResult = res.data.result
        if (res.data.result) {
          // 호출 성공인 경우 메인 롤링배너 목록 초기화
          // 7% 적립금 이벤트로 잠시 주석처리
          // commit('INIT_HOME_ROLLING_BANNER_LIST')
          state.eventCoupon.isDownloadCompleted = true
        }
      })
    },
    async getEventAccumuationPage ({ state }, payload) {
      const params = {
        ...defaultParams,
        ...payload
      }
      await getLBData(`Marketing/EventAccumuationPage`, 'post', params).then((res) => {
        state.eventAccumuationPageData = res.data
      })
    },
    async getEventAccumuationAdd ({ state }, payload) {
      const params = {
        ...defaultParams,
        ...payload
      }
      await getLBData(`Marketing/EventAccumuationAdd`, 'post', params)
    },
  },
  mutations: {
    INIT_HOME_ROLLING_BANNER_LIST (state) {
      state.homeRollingBannerList = []
    },
    INIT_IS_DOWNLOAD_RESULT (state) {
      state.isDownloadResult = null
    },
    UPDATE_IS_COUPON_DOWNLOADABLE (state, payload) {
      // 처음 앱 시작할 때와 다운받은 후 직접 변경
      state.hasCoupon = payload
    },
    SET_IS_IN_EVENT_PERIOD (state) {
      // 현재 이벤트 중인지 (이벤트 기한 ~9/30)
      // let expireDateString = state.eventCoupon.endDateString
      let expireDateString = '2023-09-30'
      let expireDate = parseYmd(expireDateString, isAppleDevice())
      let realExpireDate = dateAddDay(expireDate, 1, true, isAppleDevice())
      let realExpireDateYMDT = `${formatYmd(realExpireDate)} 00:00:00`

      let startDateYMDT = '2023-07-15 00:00:00'

      let today = new Date()
      let isEnd = isEqualOrAfterYmdt(today, realExpireDateYMDT, isAppleDevice())
      let isStart = isEqualOrAfterYmdt(today, startDateYMDT, isAppleDevice())
      
      if (isStart) {
        if (!isEnd) {
          state.isInEventPeriod = true
        } else {
          state.isInEventPeriod = false
        }
      } else {
        state.isInEventPeriod = false
      }
    },
  }
}
