import { isAppleDevice } from '@/utils/utils'

export function addDay (date, cnt, separator) {
  date = new Date(date.replace(/-/g, '/'))
  if (date.constructor !== Date) {
    return ''
  }

  date.setDate(date.getDate() + cnt)

  let month = date.getMonth() + 1
  let day = date.getDate()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }

  if (separator === null || separator === undefined) {
    separator = '-'
  }
  const reDate = date.getFullYear() + separator + month + separator + day

  return reDate
}

export function addMonth (date, cnt, separator) {
  if (date.constructor !== Date) {
    return ''
  }

  if (separator === null || separator === undefined) {
    separator = '-'
  }

  let newDate = new Date(date.getFullYear(), date.getMonth() + cnt, date.getDate())
  const newDateEndDay = new Date(date.getFullYear(), date.getMonth() + cnt + 1, 0)
  if (newDate.getMonth() !== newDateEndDay.getMonth()) {
    newDate = newDateEndDay
  }
  let month = newDate.getMonth() + 1
  let day = newDate.getDate()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }

  return newDate.getFullYear() + separator + month + separator + day
}

export function getStrDate (date, separator) {
  if (date.constructor !== Date) {
    date = new Date(date.replace(/-/g, '/'))
  }
  if (date.constructor !== Date) {
    return ''
  }

  let month = date.getMonth() + 1
  let day = date.getDate()
  // console.log(day)
  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }

  if (separator === null || separator === undefined) {
    separator = '-'
  }
  const reDate = date.getFullYear() + separator + month + separator + day

  return reDate
}

export function getStrYMDHM (date, separator) {
  date = new Date(date.replace(/-/g, '/'))
  if (date.constructor !== Date) {
    return ''
  }

  let month = date.getMonth() + 1
  let day = date.getDate()
  let hours = date.getHours()
  let minutes = date.getMinutes()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }
  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }

  if (separator === null || separator === undefined) {
    separator = '-'
  }
  const reDate = date.getFullYear() + separator + month + separator + day + ' ' + hours + ':' + minutes

  return reDate
}

export function getStrYMDHMSS (date, separator) {
  if (date.constructor !== Date) {
    date = new Date(date.replace(/-/g, '/'))
  }
  // date = new Date(date.replace(/-/g, '/'))
  let month = date.getMonth() + 1
  let day = date.getDate()
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let seconds = date.getSeconds()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }
  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }

  if (separator === null || separator === undefined) {
    separator = '-'
  }
  const reDate = date.getFullYear() + separator + month + separator + day + ' ' + hours + ':' + minutes + ':' + seconds

  return reDate
}

export function getToday () {
  return getStrDate(new Date())
}

export function getTodayTime () {
  return getStrYMDHMSS(new Date())
}

export function getFirstAndEndDay (mm, separator) {
  const nowdays = new Date()
  let year = nowdays.getFullYear()
  let month = nowdays.getMonth() + 1

  if (month <= mm) {
    year = year - 1
    month = month - mm + 12
  } else {
    month = month - mm
  }

  if (month < 10) {
    month = '0' + month
  }

  if (separator === null || separator === undefined) {
    separator = '-'
  }

  const startYmd = year + separator + month + separator + '01'

  const myDate = new Date(year, month, 0)
  const endYmd = year + separator + month + separator + myDate.getDate()

  return { startYmd, endYmd }
}

export function getSixMonths () {
  const sixMonths = []
  for (let index = 0; index < 6; index++) {
    const nowDate = new Date()
    const newDate = new Date(nowDate.getFullYear(), nowDate.getMonth() - index, 1)
    sixMonths.push(newDate.getFullYear() + '년 ' + (newDate.getMonth() + 1) + '월')
  }
  return sixMonths
}

export function isYearEndDay () {
  const date = new Date()
  if (date.getMonth() === 11 && date.getDate() === 31) {
    return true
  } else {
    return false
  }
}

export function getJanuary5 () {
  const date = new Date()
  return (date.getFullYear() + 1) + '-01-05 23:59:59'
}

export function getEndDayForYear () {
  const date = new Date()
  return date.getFullYear() + '-12-31 23:59:59'
}

export function getStrMDW (date) {
  const weeks = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  return (date.getMonth() + 1) + '.' + date.getDate() + ' ' + weeks[date.getDay()]
}

export function isOverDay (date) {
  const ONE_DAY = 1000 * 60 * 60 * 24
  const d = new Date(date.replace(/-/g, '/') + ' GMT+9') - new Date().getTime()
  return d > 0 && d <= ONE_DAY
}

/**
 * 날짜비교 함수
 * operand가 date보다 이후 인경우 1 반환
 * operand가 date와 동일한 경우 0 반환
 * operand가 date보다 이전 인경우 -1 반환
 */
export function compareDate (date, operandDate) {
  const d = new Date(operandDate.replace(/-/g, '/')) - new Date(date.replace(/-/g, '/'))
  if (d > 0) {
    return 1
  } else if (d === 0) {
    return 0
  } else {
    return -1
  }
}

export function within30Days (date) {
  const ONE_DAY = 1000 * 60 * 60 * 24 * 30
  const d = new Date(date.replace(/-/g, '/') + ' GMT+9') - new Date().getTime()
  return d > 0 && d <= ONE_DAY
}

export function getDateFormat (date) {
  if (date) {
    let slaDate = new Date(date.replace(/-/g, '/'))

    let y = slaDate.getFullYear()
    let m = slaDate.getMonth() + 1
    m = m < 10 ? '0' + m : m
    let d = slaDate.getDate()
    d = d < 10 ? ('0' + d) : d
    return y + '년 ' + m + '월 ' + d + '일'
  }
}

export function getDateFormatMD (date) {
  if (date) {
    let slaDate = new Date(date.replace(/-/g, '/'))
    let m = slaDate.getMonth() + 1
    let d = slaDate.getDate()
    return m + '월 ' + d + '일 ' + ' 오픈예정'
  }
}

export function getDateFormatYMDHM (date) {
  if (date) {
    let slaDate = new Date(date.replace(/-/g, '/'))

    let y = slaDate.getFullYear()
    let m = slaDate.getMonth() + 1
    m = m < 10 ? '0' + m : m
    let d = slaDate.getDate()
    d = d < 10 ? ('0' + d) : d
    let hours = slaDate.getHours()
    let minutes = slaDate.getMinutes()
    minutes = minutes < 10 ? ('0' + minutes) : minutes
    return y + '년 ' + m + '월 ' + d + '일' + (hours < 12 ? ' 오전 ' : ' 오후 ') + (hours < 12 ? hours : hours - 12) + ':' + minutes
  }
}

export function getDateFormatMDHM (date) {
  if (date) {
    let slaDate = new Date(date.replace(/-/g, '/'))
    let m = slaDate.getMonth() + 1
    let d = slaDate.getDate()
    let hours = slaDate.getHours()
    let minutes = slaDate.getMinutes()
    minutes = minutes < 10 ? ('0' + minutes) : minutes
    return m + '월 ' + d + '일' + (hours < 12 ? ' 오전 ' : ' 오후 ') + (hours < 12 ? hours : hours - 12) + ':' + minutes + ' 오픈예정'
  }
}

export function getDateFormatForAlert () {
  let slaDate = new Date()

  let y = slaDate.getFullYear()
  let m = slaDate.getMonth() + 1
  m = m < 10 ? '0' + m : m
  let d = slaDate.getDate()
  d = d < 10 ? ('0' + d) : d
  let hours = slaDate.getHours()
  let minutes = slaDate.getMinutes()
  return y + '.' + m + '.' + d + ' ' + hours + ':' + minutes
}

export function isHour () {
  // const now = new Date()
  // const nowHour = now.getHours()
  // if (nowHour >= 7 && nowHour < 17) {
  return true
  // }

  // return false
}

export function getDateFromIssueYmdt (issueYmdt) {
  if (isAppleDevice()) {
      // 2022-01-10 15:54:04
      let arrYMDHMS = issueYmdt.split(' ')
      let arrYMD = arrYMDHMS[0].split('-')
      let arrHMS = arrYMDHMS[1].split(':')

      return new Date(arrYMD[0], arrYMD[1] - 1, arrYMD[2], arrHMS[0], arrHMS[1], arrHMS[2])
  } else {
      return new Date(issueYmdt)
  }
}

export function getDateFromIssueYmd (issueYmd) {
  if (isAppleDevice()) {
      // 2022-01-10 15:54:04
      let arrYMD = issueYmd.split('-')

      return new Date(arrYMD[0], arrYMD[1] - 1, arrYMD[2])
  } else {
      return new Date(issueYmd)
  }
}

// yyyy-MM-dd의 날짜를 M/d로 반환
export function getStringFromDatetimeYMD (datetime, isDate) {
  let date = datetime
  if (!isDate) {
    date = getDateFromIssueYmd(datetime)
  }

  let month = date.getMonth() + 1
  let day = date.getDate()

  return month + '/' + day
}

export function getDiffYears (dt2, dt1) {
  var diff = (dt2.getTime() - dt1.getTime()) / 1000
  diff /= (60 * 60 * 24)
  return Math.floor(Math.abs(diff / 365.25))
}

export function getAfterDayFromToday (count) {
  const afterDate = new Date()
  // 오늘부터 count일 후 날짜
  afterDate.setTime(afterDate.getTime() + 1000 * 60 * 60 * 24 * count)
  return afterDate
}

/**
 * Date를 'yyyy-MM-dd' 형식의 string으로 반환
 * @param { Date } issueDate string으로 변환하고자 하는 Date
 * @param { string } separator 반환될 string 중 년/월/일 사이에 넣을 날짜 구분자. 만약 separator를 지정하지 않으면, '-'가 들어감
 * @returns issueDate의 날짜를 'yyyy-MM-dd'형식의 string으로 반환
 */
export function getStringYmdFromIssueDate (issueDate, separator) {
  if (issueDate.constructor !== Date) {
    return ''
  }
  let month = issueDate.getMonth() + 1
  let day = issueDate.getDate()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }

  if (separator === null || separator === undefined) {
    separator = '-'
  }
  const reDate = issueDate.getFullYear() + separator + month + separator + day
  return reDate
}

/**
 * Date를 'yyyyMMddHHmmss' 형식의 string으로 반환
 * @param { Date } issueDate string으로 변환하고자 하는 Date
 * @param { string } separator 반환될 string 중 년/월/일 사이에 넣을 날짜 구분자. 만약 separator를 지정하지 않으면, '-'가 들어감
 * @returns issueDate의 날짜를 'yyyyMMddHHmmss'형식의 string으로 반환
 */
export function getStringYmdtFromIssueDate (issueDate, separator) {
  if (issueDate.constructor !== Date) {
    return ''
  }
  let month = issueDate.getMonth() + 1
  let day = issueDate.getDate()
  let hours = issueDate.getHours()
  let minutes = issueDate.getMinutes()
  let seconds = issueDate.getSeconds()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }

  if (hours < 10) {
    hours = '0' + hours
  }

  if (minutes < 10) {
    minutes = '0' + minutes
  }

  if (seconds < 10) {
    seconds = '0' + seconds
  }

  const reDate = issueDate.getFullYear() + '' + month + '' + day + '' + hours + '' + minutes + '' + seconds
  return reDate
}

/**
 * 두 날짜 간의 일수찾이 계산
 * @param { String } issueYmd1 yyyy-MM-dd 형식의 String
 * @param { String } issueYmd2 yyyy-MM-dd 형식의 String
 * @returns 
 */
export function getDiffDayFromYmd (issueYmd1, issueYmd2) {
  let date1 = getDateFromIssueYmd(issueYmd1);
  let date2 = getDateFromIssueYmd(issueYmd2);
  let diff = date2.getTime() - date1.getTime();
  let diffDay = Math.ceil(diff / (1000 * 60 * 60 * 24));
  return diffDay;
}

/**
 * 두 날짜 간의 일수찾이 계산
 * @param { String } issueYmdt1 yyyy-MM-dd HH:mm:ss 형식의 String
 * @param { String } issueYmdt2 yyyy-MM-dd HH:mm:ss 형식의 String
 * @returns 
 */
export function getDiffDayFromYmdt2 (issueYmdt1, issueYmdt2) {
  let date1 = getDateFromIssueYmdt(issueYmdt1);
  let date2 = getDateFromIssueYmdt(issueYmdt2);
  let diff = date2.getTime() - date1.getTime();
  let diffDay = Math.floor(diff / (1000 * 60 * 60 * 24));
  return diffDay;
}

/**
 * 두 날짜 간의 시간(분)차이 계산
 * @param { String } issueYmdt1 yyyy-MM-dd HH:mm:ss 형식의 String
 * @param { String } issueYmdt2 yyyy-MM-dd HH:mm:ss 형식의 String
 * @returns 
 */
export function getDiffMinFromYmdt (issueYmdt1, issueYmdt2) {
  let date1 = getDateFromIssueYmdt(issueYmdt1);
  let date2 = getDateFromIssueYmdt(issueYmdt2);
  let diff = date2.getTime() - date1.getTime();
  let diffDay = Math.ceil(diff / (1000 * 60));
  return diffDay;
}

/**
 * issueDate의 시간 반환 ex 오전 07:05
 * @param {*} issueDate Date
 * @param {*} morningText 오전 텍스트
 * @param {*} afternoonText 오후 텍스트
 */
export function getStringTimeFromDate (issueDate, morningText, afternoonText) {
  let date = issueDate;

  let timePrefix = (date.getHours() < 12) ? morningText : afternoonText;
  let hours = date.getHours();
  hours = (hours <= 12 ? hours : hours - 12)
  let minutes = date.getMinutes();

  if (hours < 10) {
    hours = '0' + hours
  }

  if (minutes < 10) {
    minutes = '0' + minutes
  }

  return timePrefix + ' ' + hours + ':' + minutes;
}

/**
 * issueYmdt의 요일 반환
 * @param { String } issueYmdt yyyy-MM-dd HH:mm:ss 형식의 String
 */
export function getStringWeekdayFromIssueYmdt (ctx, issueYmdt, isShort, isDate) {
  let date = null;
  if (isDate) date = issueYmdt;
  else date = getDateFromIssueYmdt(issueYmdt);
  let weekday = '';
  let dayOfWeek = date.getDay();

  switch (dayOfWeek) {
    case 0:
      if (isShort) weekday = '일'//ctx.$t('common_sunday_short')
      else weekday = '일요일'//ctx.$t('common_sunday_long')
      break;
    case 1:
      if (isShort) weekday = '월'//ctx.$t('common_monday_short')
      else weekday = '월요일'//ctx.$t('common_monday_long')
      break;
    case 2:
      if (isShort) weekday = '화'//ctx.$t('common_tuesday_short')
      else weekday = '화요일'//ctx.$t('common_tuesday_long')
      break;
    case 3:
      if (isShort) weekday = '수'//ctx.$t('common_wednesday_short')
      else weekday = '수요일'//ctx.$t('common_wednesday_long')
      break;
    case 4:
      if (isShort) weekday = '목'//ctx.$t('common_thursday_short')
      else weekday = '목요일'//ctx.$t('common_thursday_long')
      break;
    case 5:
      if (isShort) weekday = '금'//ctx.$t('common_friday_short')
      else weekday = '금요일'//ctx.$t('common_friday_long')
      break;
    case 6:
      if (isShort) weekday = '토'//ctx.$t('common_saturday_short')
      else weekday = '토요일'//ctx.$t('common_saturday_long')
      break;
  
    default:
      break;
  }

  return weekday;
}

/**
 * issueYmd의 요일 반환
 * @param { String } issueYmd yyyy-MM-dd 형식의 String
 */
export function getStringWeekdayFromIssueYmd (ctx, issueYmd, isShort, isDate) {
  let date = null;
  if (isDate) date = issueYmd;
  else date = getDateFromIssueYmd(issueYmd);
  let weekday = '';
  let dayOfWeek = date.getDay();

  switch (dayOfWeek) {
    case 0:
      if (isShort) weekday = '일'//ctx.$t('common_sunday_short')
      else weekday = '일요일'//ctx.$t('common_sunday_long')
      break;
    case 1:
      if (isShort) weekday = '월'//ctx.$t('common_monday_short')
      else weekday = '월요일'//ctx.$t('common_monday_long')
      break;
    case 2:
      if (isShort) weekday = '화'//ctx.$t('common_tuesday_short')
      else weekday = '화요일'//ctx.$t('common_tuesday_long')
      break;
    case 3:
      if (isShort) weekday = '수'//ctx.$t('common_wednesday_short')
      else weekday = '수요일'//ctx.$t('common_wednesday_long')
      break;
    case 4:
      if (isShort) weekday = '목'//ctx.$t('common_thursday_short')
      else weekday = '목요일'//ctx.$t('common_thursday_long')
      break;
    case 5:
      if (isShort) weekday = '금'//ctx.$t('common_friday_short')
      else weekday = '금요일'//ctx.$t('common_friday_long')
      break;
    case 6:
      if (isShort) weekday = '토'//ctx.$t('common_saturday_short')
      else weekday = '토요일'//ctx.$t('common_saturday_long')
      break;
  
    default:
      break;
  }

  return weekday;
}

/**
 * Date를 'yyyyMMddHHmmss' 형식의 string으로 반환
 * @param { Date } issueDate string으로 변환하고자 하는 Date
 * @param { string } separator 반환될 string 중 년/월/일 사이에 넣을 날짜 구분자. 만약 separator를 지정하지 않으면, '-'가 들어감
 * @returns issueDate의 날짜를 'yyyyMMddHHmmss'형식의 string으로 반환
 */
export function getStringYYYYMMDDHHmmSSFromIssueDate (issueDate) {
  if (issueDate.constructor !== Date) {
    return ''
  }
  let month = issueDate.getMonth() + 1
  let day = issueDate.getDate()
  let hours = issueDate.getHours()
  let minutes = issueDate.getMinutes()
  let seconds = issueDate.getSeconds()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }
  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  
  const reDate = issueDate.getFullYear() + '' + month + '' + day + '' + hours + '' + minutes + '' + seconds

  return reDate
}

/**
 * Date를 'yyyy-MM-dd HH:mm:ss' 형식의 string으로 반환
 * @param { Date } issueDate string으로 변환하고자 하는 Date
 * @param { string } separator 반환될 string 중 년/월/일 사이에 넣을 날짜 구분자. 만약 separator를 지정하지 않으면, '-'가 들어감
 * @returns issueDate의 날짜를 'yyyy-MM-dd HH:mm:ss'형식의 string으로 반환
 */
export function getStringYYYYMMDDHHmmSSFromIssueDate2 (issueDate) {
  if (issueDate.constructor !== Date) {
    return ''
  }
  let month = issueDate.getMonth() + 1
  let day = issueDate.getDate()
  let hours = issueDate.getHours()
  let minutes = issueDate.getMinutes()
  let seconds = issueDate.getSeconds()

  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }
  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  
  const reDate = issueDate.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds

  return reDate
}

export function checkOverDay (date, expireDate) {

  // yyyy-MM-dd 형식의 날짜 string으로 변환
  let operandDate = getStringYmdFromIssueDate(date)

  // Date로 변환
  let dateOperand = getDateFromIssueYmd(operandDate);
  let dateExpire = getDateFromIssueYmd(expireDate);

  if (dateOperand <= dateExpire) {
    return true
  } else {
    return false
  }
}