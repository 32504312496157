import { getLBData, getNcpData } from '@/api'
import cookies from 'js-cookie'

export default {
  namespaced: true,
  state: {
    mallProductList: '',
    mallProductListGroup: '',
    name: '',
    id: ''
  },
  actions: {
    async getMallProductList ({ state, commit, dispatch }, payload) {
      let params = {
        navigation: 1,
        searchTextTarget: 0,
        searchText: '',
        orderByTarget: 0,
        displayCategoryNo: 0,
        pageNum: 0,
        pageSize: 0
      }
      const resp = await getLBData('Product/List', 'get', params)
      const ncrResponse = await getNcpData('display/events', 'get', { keyword: '' })

      state.mallProductListGroup = ncrResponse.data

      //  const resp = await getLBData('Product/ExpertReviewList', 'post', postData)
      // console.log(resp)
      state.mallProductList = resp.data[0]
      //  state.name = state.mallProductList.productName
    },
    async sendAppMarketAgree ({ state, commit, dispatch }, patload) {
      const resp = await getLBData('Member/SendAppMarketAgree', 'get', { mallAcessToken: cookies.get('ncpAccessToken') })
      return resp
    },
    async sendMarketStart ({ state, commit, dispatch }, patload) {
      const resp = await getLBData('Member/sendMarketStart', 'get', { mallAcessToken: cookies.get('ncpAccessToken') })
      return resp
    }
  }
}
