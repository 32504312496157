<template>
    <div style="width:100% ; border-bottom: 1px solid #f0f2f5;">
        <div class="feed_main_top_filter_wrap">
            <div class="feed_main_top_filter">
                <button v-for="(item, i) in computedCategoryList" :key="i"
                    :class="[computedSelectedValue == i ? 'feed_main_top_category_button_selected' : 'feed_main_top_category_button', {'displayNone' : checkShowBtns(item.type)}]"
                    @click="changeType(item.type, i)">{{
                        item.name
                    }}</button>
            </div>
        </div>
    </div>
</template>

<script setup>

import ssq from 'jquery'
import { cloneDeep } from 'lodash';
import cookies from 'js-cookie'
import { ref, defineProps, reactive, inject, computed, onMounted } from 'vue'
import { useStore, mapState } from 'vuex'
import { MARKETINGLOG_STORAGE_MARKETING } from '@/store/modules/lbMarketingLog'
import { useRoute } from 'vue-router';
import { setCookieForYear } from '@/utils/utils'

const store = inject('store');

const route = useRoute();
const props = defineProps({
  pageTypeCode: String,
  isMe: Boolean,
  profileTab: {
    type: String,
    default: ''
  },
  pageTargetUserId: String
})

const category = reactive([
    { name: '꿀팁', type: 'tip', isSelected: true },
    { name: '성공스토리', type: 'dietStory', isSelected: false },
    { name: '식단', type: 'meal', isSelected: false },
    { name: '운동', type: 'workout', isSelected: false },
    { name: '퀴즈', type: 'quiz', isSelected: false },
    { name: '일기', type: 'diary', isSelected: false },
    { name: '질문', type: 'help', isSelected: false },
])

const categoryProfile = reactive([
    { name: '전체', type: 'total', isSelected: true},
    { name: '성공스토리', type: 'dietStory', isSelected: false },
    { name: '식단', type: 'meal', isSelected: false },
    { name: '운동', type: 'workout', isSelected: false },
    { name: '퀴즈', type: 'quiz', isSelected: false },
    { name: '꿀팁', type: 'tip', isSelected: false },
    { name: '일기', type: 'diary', isSelected: false },
    { name: '질문', type: 'help', isSelected: false },
])

const computedCategoryList = computed(() => {
  if (props.pageTypeCode === 'PROFILE') {
    return categoryProfile
  } else {
    return category
  }
})

const checkShowBtns = (type) => {
  if (props.pageTypeCode === 'PROFILE') {
    if (type === 'quiz') {
      return true
    } else return false
  } else return false
}

const toTop = () => {
    ssq('body,html').animate({
        scrollTop: 0
    }, 200)
}

const computedSelectedValue = computed(() => {
  if (props.pageTypeCode === 'PROFILE') {
    return store.state.lbFeed.ProfileSelectedValue
  } else {
    return store.state.lbFeed.selectedValue
  }
})

const changeType = (type, index) => {
    FeedTabClick(type)
    if (props.pageTypeCode === 'PROFILE') {
      store.state.lbFeed.ProfileSelectedValue = index
    } else {
      store.state.lbFeed.selectedValue = index
    }

    if (!store.state.lbFeed.routerBack) {
        store.state.lbFeed.queryData.filterValueObject = {
            campaignCodeArrayString: null,
            genderCode: null,
            ageGroupCodeArrayString: null,
            characteristicTagCodeArrayString: null,
            recordMealCodeArrayString: null,
            mealProteinCodeArrayString: null,
            mealInevitableSituationCodeArrayString: null,
            mealCookingDifficultyCodeArrayString: null,
            mealSituationCodeArrayString: null,
            workoutTypeCodeArrayString: null,
            tipTypeCodeArrayString: null,
            tabOrderCode: null,
        }
    }
    toTop()
    if (props.pageTypeCode === 'PROFILE') {
      store.state.lbFeed.ProfileDataType = type
    } else {
      store.state.lbFeed.dataType = type
    }

    let profileData = {}
    if (props.pageTypeCode === 'PROFILE') {
      if (props.isMe) {
        profileData = {
          targetUId: store.state.lbProfile.profileInfo.profile.id,
          targetType: 'USER',
        }
      } else {
        profileData = {
          targetUId: props.pageTargetUserId,
          targetType: 'USER',
        }
      }
    }
    store.state.lbFeed.loading = true
    switch (type) {
        case 'total':
            if (props.pageTypeCode === 'PROFILE') {
              store.state.lbFeed.ProfileQueryData.isFullFilterSearch = false
              store.state.lbFeed.ProfileQueryData.page = 1
              store.state.lbFeed.ProfileQueryData.feedCategoryCode = "ALL"
            } else {
              store.state.lbFeed.queryData.isFullFilterSearch = false
              store.state.lbFeed.queryData.page = 1
              store.state.lbFeed.queryData.feedCategoryCode = "ALL"
            }
            if (props.pageTypeCode === 'PROFILE') {
              if (props.profileTab === 'favorite') 
                store.dispatch('lbFeed/postLBFavoriteFeedDataTrigger')
              else
                store.dispatch('lbFeed/postLBProfileDataTrigger', profileData)
            }
            else {
              store.dispatch('lbFeed/postLBFeedMainDataTrigger').then(() => {
                removeLoading()
              })
              FeedPageExpose(store.state.lbFeed.queryData.page)
            }
            break;
        case 'dietStory':
            if (props.pageTypeCode === 'PROFILE') {
              store.state.lbFeed.ProfileQueryData.isFullFilterSearch = false
              store.state.lbFeed.ProfileQueryData.page = 1
              store.state.lbFeed.ProfileQueryData.feedCategoryCode = "STORY"
            } else {
              store.state.lbFeed.queryData.isFullFilterSearch = false
              store.state.lbFeed.queryData.page = 1
              store.state.lbFeed.queryData.feedCategoryCode = "STORY"
            }
            if (props.pageTypeCode === 'PROFILE') {
              if (props.profileTab === 'favorite') 
                store.dispatch('lbFeed/postLBFavoriteFeedDataTrigger')
              else
                store.dispatch('lbFeed/postLBProfileDataTrigger', profileData)
            }
            else {
              store.state.lbFeed.queryFilterData.feedCategoryCode = "STORY"
              store.state.lbFeed.testFilterData = store.state.lbFeed.testFilterDataDietStory
              Promise.all([
                store.dispatch('lbFeed/postLBFeedDataTrigger'),
                store.dispatch('lbFeed/postLBFeedFilterDataTrigger')
              ])
              .then((res) => {
                removeLoading()
              })
              .finally(() => {
                // 성공스토리 진입 시 성별 필터 선택
                const userGender = store.state.lbProfile.profileInfo.profile.gender
                const genderCodeList = store.state.lbFeed.feedFilterData.genderFilterInfo.genderCodeList

                store.state.lbFeed.queryData.page = 1

                genderCodeList.forEach((item) => {
                  if (item.code === userGender) {
                    store.state.lbFeed.tempFilterValueObject.genderCode = userGender
                    store.state.lbFeed.queryData.filterValueObject.genderCode = userGender
                    item.isSelected = true
                  } else if (item.code === 'A') {
                    item.isSelected = false
                    store.state.lbFeed.tempFilterValueObject.genderCode = 'A'
                    store.state.lbFeed.queryData.filterValueObject.genderCode = 'A'
                  }
                })
                store.dispatch('lbFeed/postLBFeedDataTrigger')
              })
              .catch((e) => removeLoading())
            }
            break;

        case 'meal':
            if (props.pageTypeCode === 'PROFILE') {
              store.state.lbFeed.ProfileQueryData.isFullFilterSearch = false
              store.state.lbFeed.ProfileQueryData.page = 1
              store.state.lbFeed.ProfileQueryData.feedCategoryCode = "MEAL"
            } else {
              store.state.lbFeed.queryData.isFullFilterSearch = false
              store.state.lbFeed.queryData.page = 1
              store.state.lbFeed.queryData.feedCategoryCode = "MEAL"
            }
            if (props.pageTypeCode === 'PROFILE') {
              if (props.profileTab === 'favorite') 
                store.dispatch('lbFeed/postLBFavoriteFeedDataTrigger')
              else
                store.dispatch('lbFeed/postLBProfileDataTrigger', profileData)
            }
            else {
              store.state.lbFeed.queryFilterData.feedCategoryCode = "MEAL"
              store.state.lbFeed.testFilterData = store.state.lbFeed.testFilterDataMeal
              Promise.all([
                store.dispatch('lbFeed/postLBFeedDataTrigger'),
                store.dispatch('lbFeed/postLBFeedFilterDataTrigger')
              ])
              .then((res) => { removeLoading() })
              .catch((e) => removeLoading())
            }
            break;

        case 'workout':
            if (props.pageTypeCode === 'PROFILE') {
              store.state.lbFeed.ProfileQueryData.isFullFilterSearch = false
              store.state.lbFeed.ProfileQueryData.page = 1
              store.state.lbFeed.ProfileQueryData.feedCategoryCode = "WORKOUT"
            } else {
              store.state.lbFeed.queryData.isFullFilterSearch = false
              store.state.lbFeed.queryData.page = 1
              store.state.lbFeed.queryData.feedCategoryCode = "WORKOUT"
            }
            if (props.pageTypeCode === 'PROFILE') {
              if (props.profileTab === 'favorite') 
                store.dispatch('lbFeed/postLBFavoriteFeedDataTrigger')
              else
                store.dispatch('lbFeed/postLBProfileDataTrigger', profileData)
            }
            else {
              store.state.lbFeed.queryFilterData.feedCategoryCode = "WORKOUT"
              store.state.lbFeed.testFilterData = store.state.lbFeed.testFilterDataWorkout
              Promise.all([
                store.dispatch('lbFeed/postLBFeedDataTrigger'),
                store.dispatch('lbFeed/postLBFeedFilterDataTrigger')
              ])
              .then((res) => { removeLoading() })
              .catch((e) => removeLoading())
            }
            break;

        case 'quiz':
            if (props.pageTypeCode === 'PROFILE') {
              store.state.lbFeed.ProfileQueryData.isFullFilterSearch = false
              store.state.lbFeed.ProfileQueryData.page = 1
              store.state.lbFeed.ProfileQueryData.feedCategoryCode = "QUIZ"
            } else {
              store.state.lbFeed.queryData.isFullFilterSearch = false
              store.state.lbFeed.queryData.page = 1
              store.state.lbFeed.queryData.feedCategoryCode = "QUIZ"
            }
            store.state.lbFeed.queryFilterData.feedCategoryCode = "QUIZ"
            store.state.lbFeed.testFilterData = store.state.lbFeed.testFilterDataQuiz
            Promise.all([
              store.dispatch('lbFeed/postLBFeedDataTrigger'),
              store.dispatch('lbFeed/postLBFeedFilterDataTrigger')
            ])
            .then((res) => { removeLoading() })
            .catch((e) => removeLoading())
            break;

        case 'tip':
            if (props.pageTypeCode === 'PROFILE') {
              store.state.lbFeed.ProfileQueryData.isFullFilterSearch = false
              store.state.lbFeed.ProfileQueryData.page = 1
              store.state.lbFeed.ProfileQueryData.feedCategoryCode = "TIP"
            } else {
              store.state.lbFeed.queryData.isFullFilterSearch = false
              store.state.lbFeed.queryData.page = 1
              store.state.lbFeed.queryData.feedCategoryCode = "TIP"
            }
            if (props.pageTypeCode === 'PROFILE') {
              if (props.profileTab === 'favorite') 
                store.dispatch('lbFeed/postLBFavoriteFeedDataTrigger')
              else
                store.dispatch('lbFeed/postLBProfileDataTrigger', profileData)
            }
            else {
              store.state.lbFeed.queryFilterData.feedCategoryCode = "TIP"
              store.state.lbFeed.testFilterData = store.state.lbFeed.testFilterDataTip
              Promise.all([
                store.dispatch('lbFeed/postLBFeedDataTrigger'),
                store.dispatch('lbFeed/postLBFeedFilterDataTrigger')
              ])
              .then((res) => { removeLoading() })
              .catch((e) => removeLoading())
            }
            break;

        case 'diary':
            if (props.pageTypeCode === 'PROFILE') {
              store.state.lbFeed.ProfileQueryData.isFullFilterSearch = false
              store.state.lbFeed.ProfileQueryData.page = 1
              store.state.lbFeed.ProfileQueryData.feedCategoryCode = "DIARY"
            } else {
              store.state.lbFeed.queryData.isFullFilterSearch = false
              store.state.lbFeed.queryData.page = 1
              store.state.lbFeed.queryData.feedCategoryCode = "DIARY"
            }
            if (props.pageTypeCode === 'PROFILE') {
              if (props.profileTab === 'favorite') 
                store.dispatch('lbFeed/postLBFavoriteFeedDataTrigger')
              else
                store.dispatch('lbFeed/postLBProfileDataTrigger', profileData)
            }
            else {
              store.state.lbFeed.queryFilterData.feedCategoryCode = "DIARY"
              store.state.lbFeed.testFilterData = store.state.lbFeed.testFilterDataDiary
              Promise.all([
                store.dispatch('lbFeed/postLBFeedDataTrigger'),
                store.dispatch('lbFeed/postLBFeedFilterDataTrigger')
              ])
              .then((res) => { removeLoading() })
              .catch((e) => removeLoading())
            }
            break;

        case 'help':
            if (props.pageTypeCode === 'PROFILE') {
              store.state.lbFeed.ProfileQueryData.isFullFilterSearch = false
              store.state.lbFeed.ProfileQueryData.page = 1
              store.state.lbFeed.ProfileQueryData.feedCategoryCode = "HELP"
            } else {
              store.state.lbFeed.queryData.isFullFilterSearch = false
              store.state.lbFeed.queryData.page = 1
              store.state.lbFeed.queryData.feedCategoryCode = "HELP"
            }
            if (props.pageTypeCode === 'PROFILE') {
              if (props.profileTab === 'favorite') 
                store.dispatch('lbFeed/postLBFavoriteFeedDataTrigger')
              else
                store.dispatch('lbFeed/postLBProfileDataTrigger', profileData)
            }
            else {
              store.state.lbFeed.queryFilterData.feedCategoryCode = "HELP"
              store.state.lbFeed.testFilterData = store.state.lbFeed.testFilterDataQuestion
              Promise.all([
                store.dispatch('lbFeed/postLBFeedDataTrigger'),
                store.dispatch('lbFeed/postLBFeedFilterDataTrigger')
              ])
              .then((res) => { removeLoading() })
              .catch((e) => removeLoading())
            }
            break;

        default:
            break;
    }
    store.state.lbFeed.routerBack = false
}

const removeLoading = () => {
  setTimeout(() => {
    store.state.lbFeed.loading = false
  }, 500)
}

/**
 * 로그 함수
 */
const FeedTabClick = (tab) => {
  let logCode = 'FeedTabClick'
  let tabType = 'Home'

  if ('dietStory' === tab) {
    tabType = 'Story'
  } else if ('meal' === tab) {
    tabType = 'Meal'
  } else if ('workout' === tab) {
    tabType = 'Workout'
  } else if ('quiz' === tab) {
    tabType = 'Quiz'
  } else if ('tip' === tab) {
    tabType = 'Tip'
  } else if ('diary' === tab) {
    tabType = 'Diary'
  } else if ('help' === tab) {
    tabType = 'Help'
  }

  let marketingLogData = JSON.stringify({
    UID: cookies.get('memberCummunityUID'),
    TabType: tabType
  })
  store.dispatch('lbMarketingLog/setMarketingLog', { marketingLogData: marketingLogData, logCode: logCode, logTableName: MARKETINGLOG_STORAGE_MARKETING })
}

const FeedPageExpose = (page) => {
  let logCode = 'FeedPageExpose'

  let marketingLogData = JSON.stringify({
    UID: cookies.get('memberCummunityUID'),
    PageNum: page
  })
  store.dispatch('lbMarketingLog/setMarketingLog', { marketingLogData: marketingLogData, logCode: logCode, logTableName: MARKETINGLOG_STORAGE_MARKETING })
}

onMounted(() => {
  let needDirectTab = route.query.needDirectTab
  let isCheckedCategoryTabQueryString = cookies.get('IsCheckedCategoryTabQueryString')
  if (needDirectTab !== undefined && needDirectTab !== ''
  // 피드 메인에서 화면 로드시 쿼리스트링을 한번 체크하기 위해 조건 추가
  && isCheckedCategoryTabQueryString === '0') {
    setCookieForYear('IsCheckedCategoryTabQueryString','1')
    if (needDirectTab === 'meal') {
      setTimeout(() => {
        changeType(needDirectTab, 2)
      },2000)
    }
  }
})
</script>


<style scoped>
.feed_main_top_filter_wrap {
    font-weight: bold;
    background-color: white;
    white-space: nowrap;
    display: block !important;
    flex-wrap: nowrap;
    max-width: 100%;
    padding: 0px 0px 0px 0px;
    justify-content: space-around;
    overflow-x: scroll;
    font-size: 14px;
}

.feed_main_top_filter_wrap::-webkit-scrollbar {
    display: none;
}

.feed_main_top_filter {
    width: max-content;
    margin: 0 16px;
    display: flex;
    background-color: #fff;
}

.feed_main_top_category_wrap {
    font-weight: bold;
    background-color: white;
    white-space: nowrap;
    display: block !important;
    flex-wrap: nowrap;
    max-width: 100%;
    padding: 0px 0px 0px 0px;
    justify-content: space-around;
    overflow-x: scroll;
    font-size: 14px;
}

.feed_main_top_category_wrap::-webkit-scrollbar {
    display: none;
}

.feed_main_top_category {
    padding: 0 0 0 12px;
    display: flex;
    background-color: #fff;

}

.feed_main_top_category_button {
    height: 36px;
    padding: 6px 8px 0px 8px;
    text-align: center;
    font-size: 14px;
    color: #3D4861;
    font-weight: 500;
    border-bottom: 4px solid transparent;
}

.feed_main_top_category_button_selected {
    /* height: 36px; */
    padding: 6px 8px 0px 8px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    font-weight: bold;
    position: relative;
    color: #cb0d36;
    border-bottom: 4px solid #cb0d36;
    font-weight: 500;
}
</style>