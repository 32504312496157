import { getLBData } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT = 'Product/List'
const ENDPOINT_SUGGESTED_LIST = 'Suggested/List'

export const PRODUCTLIST_PAGECODE_HOME = 1
export const PRODUCTLIST_PAGECODE_HOTDEAL = 2
export const PRODUCTLIST_PAGECODE_NEWPRODUCT = 3
export const PRODUCTLIST_PAGECODE_PRODUCTLIST = 4
export const PRODUCTLIST_PAGECODE_HOTDEALITEM = 5
export const PRODUCTLIST_PAGECODE_SEARCHPRODUCTLIST = 6
export const PRODUCTLIST_PAGECODE_PRODUCTSUBLIST = 7
export const PRODUCTLIST_PAGECODE_SEARCHLAYER = 8
export const PRODUCTLIST_PAGECODE_CATEGORYPAGE = 9

export const PRODUCTLIST_PAGECODE_INTEGRATEDSEARCH = 10
export const PRODUCTLIST_PAGECODE_FILTEREDPRODUCTLIST = 11

export default {
  namespaced: true,
  state: {
    mallProductList: [],
    pageInfo: {
      page: 0,
      pageSize: 0,
      pageCount: 0,
      totalCount: 0
    },
    parameter: {
      navigation: 1,
      searchTextTarget: 0,
      searchText: '',
      orderByTarget: 0,
      displayCategoryNo: 0,
      hotDealNo: 0,
      isHotdeal: false,
      isNew: false,
      brandNoArrayString: '',
      pageNum: 1,
      pageSize: 20
    },
    newParameter: {
      isAllSearch: true,
      navigation: 0,
      displayCategoryNoArrayString: '',
      nutrientList: [],
      discountArrayString: '',
      deliveryArrayString: '',
      minPrice: null,
      maxPrice: null,
      brandNoArrayString: '',
      searchText: '',
      orderBy: 0,
      isNew: false,
      hotDealNo: 0,
      pageNum: 1,
      pageSize: 20,
      mallAccessToken: cookies.get('ncpLastAccessToken')
    },
    nowPageCode: 0
  },
  actions: {
    setParameterInit ({ state, commit, dispatch }, payload) {
      state.parameter = {
        navigation: 1,
        searchTextTarget: 0,
        searchText: '',
        orderByTarget: 0,
        displayCategoryNo: 0,
        hotDealNo: 0,
        isHotdeal: false,
        isNew: false,
        brandNoArrayString: '',
        pageNum: 1,
        pageSize: 20
      }
      state.newParameter = {
        isAllSearch: true,
        navigation: 0,
        displayCategoryNoArrayString: '',
        nutrientList: [],
        discountArrayString: '',
        deliveryArrayString: '',
        minPrice: null,
        maxPrice: null,
        brandNoArrayString: '',
        searchText: '',
        orderBy: 0,
        isNew: false,
        hotDealNo: 0,
        pageNum: 1,
        pageSize: 20,
        mallAccessToken: cookies.get('ncpLastAccessToken')
      }
      state.mallProductList = []
    },
    setPageInfoInit ({ state }) {
      // 통합검색 결과화면에서 마켓 상품목록 화면의 mugen-scroll 이벤트가 호출돼어 불필요하게 서버로 데이터 호출되는 현상 수정
      state.pageInfo = {
        page: 0,
        pageSize: 0,
        pageCount: 0,
        totalCount: 0
      }
    },
    setParameter ({ state }, payload) {
      // 통합검색 검색전화면에서 검색 후 emit을 통해 호출시 parameter 세팅이 안되는 현상으로 추가
      if (payload.navigation !== undefined) {
        state.parameter.navigation = payload.navigation
        state.newParameter.navigation = payload.navigation
      }
      if (payload.searchTextTarget !== undefined) {
        state.parameter.searchTextTarget = payload.searchTextTarget
        state.newParameter.searchTextTarget = payload.searchTextTarget
      }
      if (payload.searchText !== undefined) {
        state.parameter.searchText = payload.searchText
        state.newParameter.searchText = payload.searchText
        state.newParameter.isAllSearch = false
      }
      if (payload.pageNum !== undefined) {
        state.parameter.pageNum = payload.pageNum
        state.newParameter.pageNum = payload.pageNum
      }
      if (payload.hotDealNo !== undefined) {
        state.parameter.hotDealNo = payload.hotDealNo
        state.newParameter.hotDealNo = payload.hotDealNo
        state.newParameter.isAllSearch = false
      }
    },
    async getMallProduct ({ state, commit, dispatch }, payload) {
      // 1. API를 통해서 MallProduct 리스트를 가져온다
      // 2. 반환값을 받아서 state에 적용
      // api
      //  console.log(state.parameter)
      state.nowPageCode = payload.pageCode !== undefined ? payload.pageCode : state.nowPageCode

      /**
       * 검색엔진 성능 저하로 목록 호출시 갯수 20개로 강제 조정. 추후 갯수를 늘릴필요가 있다면 'state.parameter.pageSize = 20' 만 삭제하면 됨
       * 10개~20개: 0.1초 이내
       * 40개: 0.2~0.3초 (가끔 0.8초)
       */
      state.parameter.pageSize = 20

      const resp = await getLBData(ENDPOINT, 'get', state.parameter)

      if (state.parameter.pageNum === 1) {
        state.mallProductList = resp.data.detailList
      } else {
        state.mallProductList.push(...resp.data.detailList)
        dispatch('sendProductListPagingLog', {})
      }
      state.pageInfo = {
        page: resp.data.page,
        pageSize: resp.data.pageSize,
        pageCount: resp.data.pageCount,
        totalCount: resp.data.totalCount
      }

      // 검색 후 반환된 브랜드 목록 설정
      if (state.parameter.navigation === 2 || state.parameter.navigation === 3) {
        commit('lbBrandList/SET_MALL_BRAND_LIST', resp.data.brandList, {root: true})

        state.parameter.brandNoArrayString = resp.data.selectedBrandNos === null ? '' : resp.data.selectedBrandNos
        // 선택된 브랜드에서 정보가 없는 경우 브랜드 선택 정보 초기화
        commit('lbBrandList/SET_SELECTED_BRAND_NO', state.parameter.brandNoArrayString, {root: true})
      }
    },
    async getMallProductSuggested ({ state, commit, dispatch }, payload) {
      state.nowPageCode = payload.pageCode !== undefined ? payload.pageCode : state.nowPageCode

      /**
       * 검색엔진 성능 저하로 목록 호출시 갯수 20개로 강제 조정. 추후 갯수를 늘릴필요가 있다면 'state.parameter.pageSize = 20' 만 삭제하면 됨
       * 10개~20개: 0.1초 이내
       * 40개: 0.2~0.3초 (가끔 0.8초)
       */
      state.newParameter.pageSize = 20

      const resp = await getLBData(ENDPOINT_SUGGESTED_LIST, 'post', state.newParameter)

      if (state.newParameter.pageNum === 1) {
        state.mallProductList = resp.data.detailList
      } else {
        state.mallProductList.push(...resp.data.detailList)
        dispatch('sendProductListPagingLog', {})
      }
      state.pageInfo = {
        page: resp.data.page,
        pageSize: resp.data.pageSize,
        pageCount: resp.data.pageCount,
        totalCount: resp.data.totalCount
      }

      // 검색 후 반환된 브랜드 목록 설정
      //  if (state.newParameter.navigation === 2 || state.newParameter.navigation === 3) {
      //    commit('lbBrandList/SET_MALL_BRAND_LIST', resp.data.brandList, {root: true})
      //    state.newParameter.brandNoArrayString = resp.data.selectedBrandNos === null ? '' : resp.data.selectedBrandNos
      //    // 선택된 브랜드에서 정보가 없는 경우 브랜드 선택 정보 초기화
      //    commit('lbBrandList/SET_SELECTED_BRAND_NO', state.newParameter.brandNoArrayString, {root: true})
      //  }
    },
    async sendProductListPagingLog ({ state, commit, dispatch }, payload) {
      let logCode = 'MarketProductListPaging'
      let marketingLogData = JSON.stringify({
          marketLogCode: logCode
      })
      dispatch('lbMarketingLog/setMarketingLog', { marketingLogData: marketingLogData, logCode: logCode }, { root: true })
    }
  },
  mutations: {
    SET_NOW_PAGE (state, pageCode) {
      state.nowPageCode = pageCode
    },
    SET_NEWPARAMETER_WITH_FILTER (state, payload) {
      // 선택된 카테고리
      let displayCategoryNoArrayString = ''
      payload.selectedCategory.filter((w) => w.isSelected).forEach((e) => {
        if (displayCategoryNoArrayString === '') {
          displayCategoryNoArrayString = e.categoryNo + ''
        } else {
          displayCategoryNoArrayString += ',' + e.categoryNo
        }
      })
      state.newParameter.displayCategoryNoArrayString = displayCategoryNoArrayString

      // 선택된 영양 필터
      state.newParameter.nutrientList = []
      payload.selectedFilterData.filterNutrientList.forEach(element => {
        if (element.userMinValue !== undefined || element.userMaxValue !== undefined) {
          let nutrientInfo = {
            nutrientCode: element.nutrientCode,
            minValue: element.userMinValue === undefined ? element.minValue : element.userMinValue,
            maxValue: element.userMaxValue === undefined ? element.maxValue : element.userMaxValue
          }
          state.newParameter.nutrientList.push(nutrientInfo)
        }
      })

      // 선택된 할인 필터
      let discountArrayString = ''
      payload.selectedFilterData.discountList.filter((w) => w.isSelected).forEach((e) => {
        if (discountArrayString === '') {
          discountArrayString = e.value + ''
        } else {
          discountArrayString += ',' + e.value
        }
      })
      state.newParameter.discountArrayString = discountArrayString

      // 선택된 무료배송 필터
      let deliveryArrayString = ''
      payload.selectedFilterData.deliveryList.filter((w) => w.isSelected).forEach((e) => {
        if (deliveryArrayString === '') {
          deliveryArrayString = e.value + ''
        } else {
          deliveryArrayString += ',' + e.value
        }
      })
      state.newParameter.deliveryArrayString = deliveryArrayString

      // 최대 / 최소 금액
      if (payload.selectedFilterData.filterPrice !== null) {
        state.newParameter.minPrice = payload.selectedFilterData.filterPrice.isPriceChanged ? payload.selectedFilterData.filterPrice.minPrice : null
        state.newParameter.maxPrice = payload.selectedFilterData.filterPrice.isPriceChanged ? payload.selectedFilterData.filterPrice.maxPrice : null
      }

      // 선택된 브랜드 목록
      let brandNoArrayString = ''
      payload.selectedFilterData.filterBrandList.forEach((b) => b.brandList.filter((w) => w.isSelected).forEach((e) => {
        if (brandNoArrayString === '') {
          brandNoArrayString = e.brandNo + ''
        } else {
          brandNoArrayString += ',' + e.brandNo
        }
      }))
      state.newParameter.brandNoArrayString = brandNoArrayString

      // 필터정보가 있으면 isAllSearch는 false로 설정하고 이외는 true
      if (state.newParameter.displayCategoryNoArrayString === '' &&
        state.newParameter.nutrientList.length === 0 &&
        state.newParameter.discountArrayString === '' &&
        state.newParameter.deliveryArrayString === '' &&
        state.newParameter.minPrice === null &&
        state.newParameter.maxPrice === null &&
        state.newParameter.brandNoArrayString === '') {
        state.newParameter.isAllSearch = true
      } else {
        state.newParameter.isAllSearch = false
      }
    }
  }
}
