import { getLBDiaryData } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_WORKOUTDETAIL = 'V1/WorkoutDetail'
const ENDPOINT_WORKOUT_DELETE = 'V1/WorkoutDelete'

const defaultParams = {
  uId: cookies.get('memberCummunityUID'),
  appType: cookies.get('appType'),
  appVersion: cookies.get('appVersion'),
  languageCode: 'ko',
  countryCode: '82',
  whereToUseCode: 'FEED',
  cuid: 0,
  challengeId: 0,
  utcInterval: -540,
  logCode: ''
}

export default {
  namespaced: true,
  state: {
    workoutDetail: null,
    campaignDetail: null
  },
  actions: {
    async getWorkoutDetail({ state, commit, dispatch }, payload) {
      payload = {
        ...defaultParams,
        ...payload
      }
      const resp = await getLBDiaryData(ENDPOINT_WORKOUTDETAIL, 'post', payload)
      state.workoutDetail = resp.data
      return resp.data
    },
    async deleteWorkout({ state, commit, dispatch }, payload) {
      payload = {
        ...defaultParams,
        ...payload
      }
      const resp = await getLBDiaryData(ENDPOINT_WORKOUT_DELETE, 'post', payload)
      return resp.data
    },
  },
}
