<template>
  <header id="header" class="etc_header" style="margin-top: 0px;">
    <h2 class="logo">
      <router-link to="/">런바디</router-link>
    </h2>
  </header>
</template>

<style>

</style>
