import { getLBData } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_SURVEY = 'Profile/CommunitySurveyResult'
const ENDPOINT_SURVEY_RESULT = 'Profile/CommunitySurveyResultReg'

const defaultParams = {
  appType: cookies.get('appType'),
  appVersion: cookies.get('appVersion'),
  languageCode: 'ko',
  countryCode: '82',
  whereToUseCode: 'FEED',
  challengeId: 0,
  utcInterval: -540,
  logCode: '',
}
export default {
  namespaced: true,
  state: {
    mainSurvey: {
      title: '#NAME#님에 대해 알려주세요.',
      reward: 200,
      desctiption: '라이프스타일과 다이어트 목표 등 질문 5개에만 응답하면 맞춤 콘텐츠와 적립금을 드립니다!',
      buttonTitle: '응답하고 맞춤 콘텐츠와 적립금 받기',
      // ImitateOrderIdx 18, 19, 34, 23, 42, 29, 33, 35, 43, 37, 41, 44, 54, 56, 64, 63, 65, 67, 74
      datas: [
        {
          imageUrl: '',
          storyImageUrl: 'https://learnbodykr.blob.core.windows.net/user-post-images/100650_NoEqupiMB-MainImage.jpg',
          userTag: ['40대 여성', '자영업', '워킹맘'],
          userNickName: '삼남매맘',
          description: '2년간 근육 +3kg',
        },
        {
          imageUrl: '',
          storyImageUrl: 'https://learnbodykr.blob.core.windows.net/user-post-images/1379807_NoEqupiMB-Page0.jpg?v=20230131220452',
          userTag: ['40대 여성', '주부', '육아맘'],
          userNickName: '코코양파',
          description: '2개월 -3.7kg (체지방률 -8%)',
        },
        {
          imageUrl: '',
          storyImageUrl: 'https://lbwebkr.blob.core.windows.net/user-post-image/Review_OSType_2296048_NoEqupiMB_20210709173406.jpeg',
          userTag: ['20대 여성', '직장인'],
          userNickName: 'Hwod',
          description: '1년 -11.3kg (근육 +0.9kg, 지방 -12.6kg)',
        },
        {
          imageUrl: '',
          storyImageUrl: 'https://learnbodykr.blob.core.windows.net/user-post-images/3309673_NoEqupiMB-Page0.jpg?v=20230131220452',
          userTag: ['30대 여성', '프리랜서'],
          userNickName: '더나은정',
          description: '105kg에서 59kg로 총 -46kg',
        },
        {
          imageUrl: '',
          storyImageUrl: 'https://lbwebkr.blob.core.windows.net/user-post-image/8593.jpg',
          userTag: ['40대 남성', '직장인'],
          userNickName: '진느',
          description: '65일 -10kg (체지방률 -7%)',
        },
        // {
        //   imageUrl: '',
        //   storyImageUrl: 'https://learnbodykr.blob.core.windows.net/user-post-images/2709487_NoEqupiMB-Page4.jpg?v=20230131220452',
        //   userTag: ['30대 여성', '직장인'],
        //   userNickName: '취미는어깨',
        //   description: '7개월 -2.5kg (근육 +3.9kg, 지방 -8.5kg)',
        // },
        {
          imageUrl: '',
          storyImageUrl: 'https://lbwebkr.blob.core.windows.net/user-post-image/7685.jpg',
          userTag: ['20대 여성', '직장인'],
          userNickName: '샘이',
          description: '100일 -12kg',
        },
        {
          imageUrl: '',
          storyImageUrl: 'https://lbwebkr.blob.core.windows.net/user-post-image/4586.jpg',
          userTag: ['20대 남성', '직장인'],
          userNickName: 'climb병재',
          description: '1년 3개월 -33kg (지방 -25.6kg)',
        },
        {
          imageUrl: '',
          storyImageUrl: 'https://learnbodykr.blob.core.windows.net/user-post-images/2858290_NoEqupiMB-Page0.jpg?v=20230131220452',
          userTag: ['30대 남성', '직장인'],
          userNickName: '8515',
          description: '6개월 -18kg',
        },
        {
          imageUrl: '',
          storyImageUrl: 'https://learnbodykr.blob.core.windows.net/user-post-images/745159_NoEqupiMB-Page0.jpg?v=20230131220452',
          userTag: ['20대 여성', '직장인', '자영업'],
          userNickName: '서지영',
          description: '7개월 -10.7kg (지방 -10.1kg)',
        },
        {
          imageUrl: '',
          storyImageUrl: 'https://lbwebkr.blob.core.windows.net/user-post-image/Review_OSType_1691136_NoEqupiMB_20210729112604.jpeg',
          userTag: ['30대 여성', '직장인', '워킹맘'],
          userNickName: '수뿡',
          description: '1년 -11.7kg (지방 -10.9kg)',
        },
        {
          imageUrl: '',
          storyImageUrl: 'https://learnbodykr.blob.core.windows.net/user-post-images/3662230_NoEqupiMB-Page0.jpg?v=20230131220452',
          userTag: ['20대 남성', '직장인'],
          userNickName: '영뫼',
          description: '80일 -17.8kg (지방 -15.3kg)',
        },
        //54, 56, 64, 63, 65, 67, 74 데이터 확인을 어디서 해야함?
        // {
        //   imageUrl: '',
        //   storyImageUrl: '',
        //   userTag: ['', ''],
        //   userNickName: '',
        //   description: '',
        // },
        // {
        //   imageUrl: '',
        //   storyImageUrl: '',
        //   userTag: ['', ''],
        //   userNickName: '',
        //   description: '',
        // },
      ],
    },
    detailSurvey: [
      {
        // 질문 번호
        questionNo: 1,
        // 질문 내용
        question: '💼직업 특성과 근무 형태는 어떤 편이신가요? (중복 선택 가능)',
        // 답변 여러개 선택이 가능한지 여부
        isMultiSelect: true,
        // 답변 UI 타입 'CHIP' or 'BAR' 2가지 종류가 있음
        answerUIType: 'CHIP',
        // 답변 목록
        answer: ['직장인', '학생', '육아맘', '주부', '워킹맘', '프리랜서', '자영업자', '야간근무', '3교대근무', '무직', '기타'],
        // 하위 질문 번호 : 해당 값은 질문2와 같이 질문2에 여러가지 질문이 포함되는 경우가 있는데, 이를 위한 구분번호임
        // 예를들어 questionNo = 2, subQuestionNo = 1인 질문의 답변을 선택하면 questionNo = 2, subQuestionNo = 2가 숨어있다가 하단에 표시됩니다.
        subQuestionNo: 1,
        description: '의 성공한 노하우를 추천해드릴게요.',
        tag: ['직장인', '학생', '육아맘', '주부', '워킹맘', '프리랜서', '자영업자', '', '', '', ''],
        code: [5, 30, 10, 9, 3, 11, 6, '', '', '', '']
      },
      {
        // 질문2에는 두가지 질문이 있음
        questionNo: 2,
        question: '🎯달성하고 싶은 목표는 무엇인가요? (중복 선택 가능)',
        isMultiSelect: true,
        answerUIType: 'BAR',
        answer: ['지방 감량', '근육 증가', '건강 증진', '유지어터', '바디프로필 촬영'],
        subQuestionNo: 1,
        description: '에 성공한 다이어터들의 이야기를 찾아드릴게요!',
        tag: ['지방 감량', '근육 증가', '', '유지어터', '바디프로필'],
        code: [31, 32, '', 24, 33]
      },
      {
        questionNo: 2,
        question: '✨선호하는 다이어트 방식은 무엇인가요? (중복 선택 가능)',
        isMultiSelect: true,
        answerUIType: 'BAR',
        answer: ['단기간 집중적인 감량', '천천히 지속가능한 변화', '간헐적 단식', '저탄고지', '비건', '없음'],
        subQuestionNo: 2,
        tag: ['단기어터', '장기어터', '간헐적단식', '저탄고지', '비건', ''],
        code: [34, 35, 36, 37, 38, '']
      },
      {
        // 질문3에는 두가지 질문이 있음
        questionNo: 3,
        question: '⏱️평소 혹은 앞으로 계획 중인 운동 시간대는 언제인가요? (중복 선택 가능)',
        isMultiSelect: true,
        answerUIType: 'CHIP',
        answer: ['이른 아침', '이른 아침(공복)', '오전', '점심', '오후', '저녁', '밤 늦게'],
        subQuestionNo: 1,
        description: '일반식을 먹고 성공한 다이어터의 이야기와 \n일반식 중심의 식단을 보여드릴게요!',
        tag: ['', '', '', '', '', '', ''],
      },
      {
        questionNo: 3,
        question: '💪평소 혹은 앞으로 계획 중인 운동 빈도는 어느 정도인가요?',
        isMultiSelect: false,
        answerUIType: 'BAR',
        answer: ['주 6~7일', '주 5~6일', '주 4~5일', '주 3~4일', '주 2~3일', '주 1~2일'],
        subQuestionNo: 2,
        tag: ['', '', '', '', '', ''],
      },
      {
        // 질문4에는 세가지 질문이 있음
        questionNo: 4,
        question: '🥣식습관 특징은 어떤 편이신가요? (중복 선택 가능)',
        isMultiSelect: true,
        answerUIType: 'BAR',
        answer: [
          '외식 혹은 배달 음식을 자주 먹는다.',
          '시간을 내서 요리를 하는 편이다.',
          '시중 다이어트 식품을 자주 먹는다.',
          '시중 다이어트 식품은 먹지 않는다.',
          '전자레인지 등 간편 조리를 주로 하는 편이다.',
          '클린 식단으로 먹는다.',
        ],
        subQuestionNo: 1,
        description: '운동량이 많지 않은 성공스토리와\n가볍게 시작하시기 좋은 운동 위주로 보여드릴게요!',
        tag: ['', '', '', '', '', ''],
      },
      {
        questionNo: 4,
        question: '☀️평소 아침 식사는 어떻게 드시나요? (중복 선택 가능)',
        isMultiSelect: true,
        answerUIType: 'BAR',
        answer: ['아침을 먹지 않는다.', '아침을 가끔 먹는다.', '간편식으로 먹는 편이다.', '일반식으로 먹는 편이다.'],
        subQuestionNo: 2,
        tag: ['', '', '', ''],
      },
      {
        questionNo: 4,
        question: '🥲식단 관리 중 겪는 어려움은 무엇인가요? (중복 선택 가능)',
        isMultiSelect: true,
        answerUIType: 'BAR',
        answer: [
          '식사량 조절이 어렵다.',
          '간식이 자주 당긴다.',
          '야식이 자주 당긴다.',
          '밀가루 음식이 자주 당긴다.',
          '기름진 음식이 자주 당긴다.',
          '단백질을 챙겨 먹기 힘들다.',
          '닭가슴살이 질린다.',
          '규칙적으로 챙겨 먹기 힘들다.',
          '술자리가 많은 편이다.',
        ],
        subQuestionNo: 3,
        tag: ['', '', '', '', '', '', '', '', ''],
      },
      {
        // 질문5에는 한가지 질문이 있음
        questionNo: 5,
        question: '✅어떤 콘텐츠에 관심이 많으신가요? (중복 선택 가능)',
        isMultiSelect: true,
        answerUIType: 'BAR',
        answer: [
          '자극/팁을 얻을 수 있는 고수들의 성공스토리',
          '고수들의 실시간 식단∙운동',
          '다양하고 건강한 식단 레시피',
          '고민을 해결해주는 영양사의 다이어트 지식 콘텐츠',
          '지식 쌓고 돈 받는 다이어트 퀴즈',
          '런바디마켓의 최저가 보장 핫딜 소식',
          '다이어트∙건강 제품 무료 체험단 모집',
          '다양한 다이어트∙건강 브랜드 혜택 소식 (광고 포함)',
          '요즘 핫한 다이어트 트렌드',
          '본격적으로 함께 관리하는 다이어트 소그룹 모집',
        ],
        subQuestionNo: 1,
        description: '운동량이 많지 않은 성공스토리와\n가볍게 시작하시기 좋은 운동 위주로 보여드릴게요!',
        tag: ['', '', '', '', '', '', '', '', '', ''],
      },
    ],
    surveyList: [],
    tagList: [],
    showCharacteristicTagListQ1: [],
    showCharacteristicTagListQ2: [],
    progressTagList: [
      {
        questionNo: 1,
        code: 'LIFE_STYLE',
        text: '라이프 스타일',
        isEndSurvey: false
      },
      {
        questionNo: 2,
        code: 'DIET_GOAL',
        text: '목표',
        isEndSurvey: false
      },
      {
        questionNo: 3,
        code: 'EXERCISES',
        text: '운동',
        isEndSurvey: false
      },
      {
        questionNo: 4,
        code: 'DIET_MEAL',
        text: '식습관',
        isEndSurvey: false
      },
      {
        questionNo: 5,
        code: 'ETC',
        text: '기타',
        isEndSurvey: false
      },
    ],
    // 완료된 설문에 대한 값 (내 프로필 태그리스트 진행상황에 사용하는)
    completeProgressTagList: [],
    characteristicTagTextList: [
      '직장인', '학생', '육아맘', '주부', '워킹맘', '프리랜서', '자영업자', '지방 감량', '근육 증가', '유지어터', '바디프로필', '단기어터', '장기어터', '간헐적단식', '저탄고지', '비건'
    ],
    characteristicTagCodeList: [
      5, 30, 10, 9 , 3, 11, 6, 31, 32, 24, 33, 34, 35, 36, 37,38 
    ]
  },
  actions: {
    async getCommunitySurvey({ state }, payload) {
      payload = {
        ...defaultParams,
        ...payload,
      }
      const resp = await getLBData(ENDPOINT_SURVEY, 'post', payload)
      state.surveyList = resp.data?.surveyResultList
      state.tagList = resp.data?.characteristicTagCodeList
      return resp.data?.surveyResultList
    },
    async updateCommunitySurvey({ state }, payload) {
      // payload.characteristicTagCodeArrayString = null
      payload = {
        ...defaultParams,
        ...payload,
      }
      console.log(payload)
      const resp = await getLBData(ENDPOINT_SURVEY_RESULT, 'post', payload)
      return resp.data
    },
  },
  mutations: {
    DUPLICATE_TAGLIST (state) {
      state.completeProgressTagList = JSON.parse(JSON.stringify(state.progressTagList))
    }
  }
}
