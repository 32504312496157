// config for alpha

import productionConfig from './prod'
import stagingConfig from './slot'

export default {
  ...productionConfig,
  ...stagingConfig,
  /* production
  lbCommunityBaseUrl: 'https://communityapp.learnbody.co.kr',
  lbMarketApiBaseUrl: 'https://marketapi.learnbody.co.kr/',
  lbDiaryApiBaseUrl: 'https://diaryapi.learnbody.co.kr/',
  lbDiaryBaseUrl: 'https://diary.learnbody.co.kr/',
  lbAutocompleteIndexName: 'autocomplete-production',
  lbMarketStorageUrl: 'https://learnbodykr.blob.core.windows.net/market-images/',
  ncpApiBaseUrl: 'https://shop-api.e-ncp.com/',
  ncpApiBaseUrlWebview: 'https://shop-api.e-ncp.com/',
  ncpClientId: 'xRzkr7sOuawIgcDtOgSWSA==',
  payPlusJs: 'https://stdpay.inicis.com/stdjs/INIStdPay.js',
  payJs: 'ncp_pay.js',
  searchJs: 'ncp_search.js',
  cdnUrl: 'https://learnbodykr.blob.core.windows.net/cdn/',
  lbMarketStorageDir: 'market-images',
  */

  /* staging(slot)
  lbCommunityBaseUrl: 'https://communityapp-slot.learnbody.co.kr',
  lbMarketApiBaseUrl: 'https://learnbodymarketapi-slot.azurewebsites.net/',
  lbDiaryApiBaseUrl: 'https://diaryapi-slot.learnbody.co.kr/',
  lbDiaryBaseUrl: 'https://diary-slot.learnbody.co.kr/',
  lbAutocompleteIndexName: 'autocomplete-staging',
  lbMarketStorageUrl: 'https://learnbodykr.blob.core.windows.net/market-images/',
  ncpApiBaseUrl: 'https://shop-api.e-ncp.com/',
  ncpApiBaseUrlWebview: 'https://shop-api.e-ncp.com/',
  ncpClientId: 'xRzkr7sOuawIgcDtOgSWSA==',
  payPlusJs: 'https://stdpay.inicis.com/stdjs/INIStdPay.js',
  payJs: 'ncp_pay.js',
  searchJs: 'ncp_search.js',
  cdnUrl: 'https://learnbodykr.blob.core.windows.net/cdn/',
  lbMarketStorageDir: 'market-images',
  */

  /* test
  lbCommunityBaseUrl: 'https://testcommunityapp.learnbody.co.kr',
  lbMarketApiBaseUrl: 'https://testmarketapi.learnbody.co.kr/',
  lbDiaryApiBaseUrl: 'https://testdiaryapi.learnbody.co.kr/',
  lbDiaryBaseUrl: 'https://testdiary.learnbody.co.kr/',
  lbAutocompleteIndexName: 'autocomplete-test-production',
  lbMarketStorageUrl: 'https://learnbodykr.blob.core.windows.net/test-market-images/',
  ncpApiBaseUrl: 'https://alpha-api.e-ncp.com/',
  ncpApiBaseUrlWebview: 'https://alpha-api.e-ncp.com/',
  ncpClientId: '47fzWj2ESmKB9fDCwbbZoA==',
  payPlusJs: 'https://stgstdpay.inicis.com/stdjs/INIStdPay.js',
  payJs: 'ncp_pay_alpha.js',
  searchJs: 'ncp_search_alpha.js',
  cdnUrl: 'https://learnbodykr.blob.core.windows.net/cdn/',
  lbMarketStorageDir: 'test-market-images',
  */

  lbCommunityBaseUrl: 'https://testcommunityapp.learnbody.co.kr',
  lbMarketApiBaseUrl: 'https://testmarketapi.learnbody.co.kr/',
  lbDiaryApiBaseUrl: 'https://testdiaryapi.learnbody.co.kr/',
  lbDiaryBaseUrl: 'https://testdiary.learnbody.co.kr/',
  lbAutocompleteIndexName: 'autocomplete-test-production',
  lbMarketStorageUrl: 'https://learnbodykr.blob.core.windows.net/test-market-images/',
  ncpApiBaseUrl: 'https://shop-api.e-ncp.com/',
  ncpApiBaseUrlWebview: 'https://shop-api.e-ncp.com/',
  ncpClientId: 'AXB0u3OIkGxA469WZ9GGmQ==',
  payPlusJs: 'https://stdpay.inicis.com/stdjs/INIStdPay.js',
  payJs: 'ncp_pay.js',
  searchJs: 'ncp_search.js',
  cdnUrl: 'https://learnbodykr.blob.core.windows.net/cdn/',
  lbMarketStorageDir: 'test-market-images',

  paycoAuthorizeUrl: 'https://alpha-id.payco.com/oauth2.0/authorize',
  paycoAuthorizeJoinUrl: 'https://alpha-id.payco.com/join.nhn',
  paycoClientIdForIOS: 'ALPY5IpR2zDA6ILOgiLg',
  paycoClientIdForAOS: 'ALP2lVFWwNLUjamvfrN_',
  paycoClientIdForWEB: 'ALPGnaYSbcaRgC2PWLCN',
  paycoCertifyLogin: 'https://alpha-id.payco.com/certifyLogin.nhn',
  paycoMyinfo: 'https://alpha-id.payco.com/myinfo.nhn',
  paycoChangPwd: 'https://alpha-id.payco.com/myInfoChangePwd.nhn',
  oldPingdamall: 'http://alpha-old.buywow.co.kr',
  oldPdmall: 'http://alpha-old.pdmall.buywow.co.kr',
  // payPlusJs: 'https://testpay.kcp.co.kr/plugin/payplus_web.jsp', --KCP
  homeBannerCode: '000',
  homeMagicBannerCode: '027',
  homeSectionNo: '470',
  todaySectionNo: '3',
  brandBannerCode: '003',
  eventTabBannerCode: '007',
  categoryBannerCode: '008',
  myPageTopBannerCode: '017',
  myPageBottomBannerCode: '018',
  faqBoardNo: '1559',
  kakaoKey: 'bb41bb7c8befb11a9422c4085764df1e',
  navCagetories: ['27703', '27728', '27729', '27713'],

  lbPaycrewBaseUrl: 'https://testpaycrew.learnbody.co.kr/',
}