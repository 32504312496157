import router from '@/router'
import cookies from 'js-cookie'
import config from '@/config/index'
import { compareAppVersion } from '@/utils/StringUtils'
import { hasAccessToken, getAccessToken } from '@/utils/utils'

window.setAppAccessToken = function (token) {
  cookies.set('buywowAccessToken', token)
  const nxturl = cookies.get('nxturl')
  if (nxturl && nxturl.length > 0) {
    window.location.href = decodeURIComponent(nxturl)
  }
}

export default {
  backOrClose () {
    router.back() // TODO: back or close or to main....
  },
  saveLogin ({ dispatch, state }, { code, token, nxturl }) {
    // const osType = state.osType()
    // if (osType !== 'WEB') {
    //   dispatch('paycoTokenLoginForAPP', { nxturl })
    // } else {
    dispatch('paycoTokenLoginForWEB', { code, nxturl })
    // }
  },
  paycoTokenLoginForAPP ({ dispatch, state }, { nxturl }) {
    if (!hasAccessToken()) {
      dispatch('login')
    } else {
      dispatch('authentication/paycoToken2AccessToken', { accessToken: getAccessToken() }).then(function () {
        dispatch('afterLogined', { nxturl })
      })
    }
  },
  paycoTokenLoginForWEB ({ dispatch, state }, { code, nxturl }) {
    dispatch('authentication/paycoCode2AccessToken', { code, nxturl }).then(function () {
      if (state.authentication.openid.dormantMemberResponse) {
        cookies.set('nxturl', encodeURIComponent(nxturl))
        opener.location.replace('/activate')
        self.close()
      } else {
        dispatch('afterLogined', { nxturl })
      }
    })
  },
  afterLogined ({ dispatch, state }, { nxturl }) {
    if (!nxturl || nxturl === '' || nxturl === 'undefined') {
      nxturl = '/'
    }
    dispatch('profile/memberFetch').then(res => {
      const memberstatus = res.memberStatus
      if (memberstatus !== 'ACTIVE') {
        location.href = ('/member/agreement?nxturl=' + nxturl)
      } else {
        dispatch('authentication/fetchShopAgreement').then(() => {
          if (opener) {
            opener.location.replace(nxturl)
            self.close()
          } else {
            location.replace(nxturl)
          }
        })
      }
    })
  },
  login ({ dispatch, state }, regUrl) {
    let url = state.route.query.redirect
    if (regUrl) {
      url = regUrl
    }
    if (process.env.BUILD_TARGET === 'webview') {
      if (!hasAccessToken()) {
        url = `${location.protocol}//${location.host}/logincallback?nxturl=${url}`

        if (state.osType() === 'IOS') {
          /** >=4.3.3 */
          if (compareAppVersion('4.3.3')) {
            cookies.set('nxturl', encodeURIComponent(url))
            window.location.href = 'buywow://login?callback=setAppAccessToken'
          } else if (compareAppVersion('4.3')) {
            /** >=4.3 */
            alert('CJ프레시웨이 임직원몰을 종료하신후 핑크다이어리 앱 > 설정 > 로그인 후 진행해 주시기 바랍니다.')
          } else {
            window.location.href = 'buywow://login?url=' + encodeURIComponent(url)
          }
        } else {
          if (compareAppVersion('4.1.1')) {
            window.location.href = 'buywow://login?url=' + encodeURIComponent(url)
          } else {
            window.location.href = 'buywow://login'
          }
        }
      } else {
        dispatch('paycoTokenLoginForAPP', { nxturl: url })
      }
    } else {
      window.open(config.paycoLoginUrl(url))
    }
  },
  join ({ dispatch, state }) {
    let url = state.route.query.redirect
    if (process.env.BUILD_TARGET === 'webview') {
      if (compareAppVersion('4.3')) {
        window.location.href = 'buywow://join?url=' + encodeURIComponent(url)
      } else if (compareAppVersion('4.1.1')) {
        if (state.osType() === 'IOS') {
          window.nxturl = encodeURIComponent(url)
          window.location.href = 'buywow://login?callback=setAppAccessToken'
        } else {
          window.location.href = 'buywow://login?url=' + encodeURIComponent(url)
        }
      } else {
        window.location.href = 'buywow://login'
      }
    } else {
      window.open(config.paycoJoinUrl(url))
    }
  },
  paycoCertify ({ dispatch, state }, { nxturl }) {
    dispatch('profile/paycoSync').then(() => {
      window.location.href = nxturl
    })
  },
  loginBridge ({ dispatch, state }, { trackingKey, redirect }) {
    if (trackingKey) {
      cookies.set('trackingKey', trackingKey)
    }
    cookies.set('fromPayco', 'Y')
    window.location.replace(config.paycoLoginUrl(redirect))
  }
}
