import { createRouter, createWebHistory } from 'vue-router'
import { scrollBehavior } from './utils'
import { PCwebCheck, setTrackingKey, appLogin, auth, dispatchAction, resetSearchWordInfo, mobilewebCheck, loadTGScript, fetchProfile } from './hooks'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior,
  routes
})

router.beforeEach(setTrackingKey)
router.beforeEach(mobilewebCheck)
router.beforeEach(appLogin)
router.beforeEach(auth)
router.beforeEach(dispatchAction)
// router.beforeEach(appendMeta)
router.afterEach(resetSearchWordInfo)
router.afterEach(loadTGScript)
router.afterEach(fetchProfile)
router.beforeEach(PCwebCheck)
// router.afterEach(loadMoreScript)
export default router
