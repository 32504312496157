import { getLBData } from '@/api'
import cookies from 'js-cookie'

const endpointDietMealFilterList = 'DietMeal/Filters'
const endpointDietMealSetFilterList = 'DietMeal/UserSelectedSearchFilterReg'
const endpointDietMealList = 'Search/DietMealResult'
const endpointDietMealFavoriteList = 'DietMeal/FavoriteList'
const endpointDietMealFavoriteTotalCount = 'DietMeal/FavoriteTotalCount'
const endpointSetFavorite = 'dietmeal/SetFavorite'
const endpointSendMyMealReport = 'DietMeal/ReportMyMealReg'
const endpointCommunityContentsPushRefuseSetup = 'Etc/CommunityContentsPushRefuseSetup'
const endpointCommunityContentsPushNotification = 'Etc/CommunityContentsPushNotification'
const endpointCommunityContentsPushNotificationAdd = 'Etc/CommunityContentsPushNotificationAdd'

export default {
  namespaced: true,
  state: {
    // 뭐먹지
    mallProductNo: null,
    pageNum: 0,
    dietMealList: [],
    dietMealCategoryID: 0,
    dietMealPageInfo: {
      page: 0,
      pageSize: 5,
      pageCount: 0,
      totalCount: 0
    },
    popularIDXArrayString: '',
    searchText: '',
    // 즐겨찾기
    favoriteTotalCount: 0,
    dietMealWhatToEatFavoriteDateList: null,
    // 필터 데이터
    codeDietMealCookingDifficultyList: null,
    codeDietMealCookingDifficultyListLocalSelect: null,
    codeDietMealSituationCategoryList: null,
    codeDietMealSituationList: null,
    codeDietMealSituationListLocalSelect: null,
    codeDietMealNutritionList: null,
    codeDietMealNutritionListLocalSelect: null, // [{ text: '저칼로리', code: 'LOW_CALORIE', isSelected: false }, { text: '저탄수', code: 'LOW_CARBOHYDRATE', isSelected: false }, { text: '고단백', code: 'HIGH_PROTEIN', isSelected: false }, { text: '저지방', code: 'LOW_FAT', isSelected: false }]
    // 필터 적용 여부
    isNewFilterApply: false,
    // 뭐먹지 상세화면 이전 다음
    dietMealPrevNextFromSearch: {isPrev: false, prevIDX: 0, isNext: false, nextIDX: 0, isNeedCallNextPage: false},
    enterDietMealDetail: false,
    dietMealDetailItemIDX: 0,
    CommunityContentsPopupRendingInfo: null,
    needShowContentsAlarmBanner: false
  },
  actions: {
    setParameterInit ({ state, commit, dispatch }) {
      state.pageNum = 0

      state.dietMealList = []
      state.dietMealPageInfo = {
        page: 0,
        pageSize: 5,
        pageCount: 0,
        totalCount: 0
      }
      state.dietMealCategoryID = 0
      state.popularIDXArrayString = ''
    },
    async getDietMealList ({ state, commit, dispatch }, params) {
      state.pageNum += 1

      var payload = {}
      payload.pageNum = state.pageNum
      payload.pageSize = state.dietMealPageInfo.pageSize

      payload.mallAccessToken = cookies.get('ncpAccessToken')
      // payload.uID = '308942_NoEqupiMB'
      payload.appType = cookies.get('appType')
      payload.appVersion = cookies.get('appVersion')

      payload.searchText = state.searchText
      payload.popularIDXArrayString = state.popularIDXArrayString

      // 초간단, 간단, 정성
      var difficultyCodeString = ''
      if (state.codeDietMealCookingDifficultyList) {
        let difficultyData = state.codeDietMealCookingDifficultyList.filter((w) => w.isSelected)[0]
        if (difficultyData) {
          state.codeDietMealCookingDifficultyList.forEach(element => {
              if (element.isSelected) {
                  if (difficultyCodeString === '') {
                    difficultyCodeString = element.code
                  } else {
                    difficultyCodeString += ',' + element.code
                  }
              }
          })
        }
      }

      payload.cookingDifficultyCodeArrayString = difficultyCodeString

      // 상황 #단것당길때
      var situationCodeString = ''
      if (state.codeDietMealSituationList) {
        let situationData = state.codeDietMealSituationList.filter((w) => w.isSelected)[0]
        if (situationData) {
          situationCodeString = situationData.code
        }
      }

      payload.situationCodeArrayString = situationCodeString

      // 저칼로리, 저탄수, 고단백, 저지방
      var mealPropertyCodeString = ''
      if (state.codeDietMealNutritionList) {
        let nutritionData = state.codeDietMealNutritionList.filter((w) => w.isSelected)[0]
        if (nutritionData) {
          state.codeDietMealNutritionList.forEach(element => {
            if (element.isSelected) {
              if (mealPropertyCodeString === '') {
                mealPropertyCodeString = element.code
              } else {
                mealPropertyCodeString += ',' + element.code
              }
            }
          })
        }
      }

      payload.mealPropertyCodeArrayString = mealPropertyCodeString

      if (params !== undefined && params.filterInfo !== undefined) {
        if (params.filterInfo.DifficultyFilter !== '') {
          payload.cookingDifficultyCodeArrayString = params.filterInfo.DifficultyFilter
        }
        if (params.filterInfo.SituationFilter !== '') {
          payload.situationCodeArrayString = params.filterInfo.SituationFilter
        }
        if (params.filterInfo.NutritionFilter !== '') {
          payload.mealPropertyCodeArrayString = params.filterInfo.NutritionFilter
        }
      }

      const resp = await getLBData(endpointDietMealList, 'post', payload)

      if (payload.pageNum === 1) {
        state.dietMealList = resp.data.dietMealWhatToEatDetailList
        state.dietMealPageInfo.totalCount = resp.data.totalCount
        state.dietMealPageInfo.pageCount = resp.data.pageCount
        // parseInt(state.dietMealPageInfo.totalCount / state.dietMealPageInfo.pageSize) + ((state.dietMealPageInfo.totalCount % state.dietMealPageInfo.pageSize) > 0 ? 1 : 0)
      } else {
        state.dietMealList.push(...resp.data.dietMealWhatToEatDetailList)
      }

      state.popularIDXArrayString = resp.data.popularIDXArrayString

      // 커뮤니티 > 뭐먹지 상세 진입시 이전/다음 체크
      if (params !== undefined && params.detailWhatToEatIDX !== undefined) {
        commit('SET_DETAIL_IDX', params.detailWhatToEatIDX)
      }
    },
    async getDietMealFavoriteList ({ state, commit, dispatch }) {
      let payload = {
        mallAccessToken: cookies.get('ncpAccessToken'),
        appType: cookies.get('appType'),
        appVersion: cookies.get('appVersion')
        // UID: '308942_NoEqupiMB'
      }
      const resp = await getLBData(endpointDietMealFavoriteList, 'get', payload)
      state.favoriteTotalCount = resp.data.totalCount
      state.dietMealWhatToEatFavoriteDateList = resp.data.dietMealWhatToEatFavoriteDateList
    },
    async getDietMealFilter ({ state, commit, dispatch }, filterInfo) {
      const resp = await getLBData(endpointDietMealFilterList, 'get')

      if (filterInfo !== undefined) {
        if (filterInfo.NutritionFilter !== '') {
          resp.data.codeMealPropertyList.filter((w) => w.code === filterInfo.NutritionFilter)[0].isSelected = true
        }
        if (filterInfo.SituationFilter !== '') {
          resp.data.codeSituationList.filter((w) => w.code + '' === filterInfo.SituationFilter)[0].isSelected = true
        }
        if (filterInfo.DifficultyFilter !== '') {
          if (filterInfo.DifficultyFilter.indexOf(',') > -1) {
            let arrDifficultyCode = filterInfo.DifficultyFilter.split(',')
            arrDifficultyCode.forEach((element) => {
              resp.data.codeCookingDifficultyList.filter((w) => w.code === element)[0].isSelected = true
            })
          } else {
            resp.data.codeCookingDifficultyList.filter((w) => w.code === filterInfo.DifficultyFilter)[0].isSelected = true
          }
        }
      }

      state.codeDietMealCookingDifficultyList = resp.data.codeCookingDifficultyList
      state.codeDietMealCookingDifficultyListLocalSelect = JSON.parse(JSON.stringify(state.codeDietMealCookingDifficultyList)) // deep copy
      state.codeDietMealSituationCategoryList = resp.data.codeSituationCategoryList
      state.codeDietMealSituationList = resp.data.codeSituationList
      state.codeDietMealSituationListLocalSelect = JSON.parse(JSON.stringify(state.codeDietMealSituationList)) // deep copy
      state.codeDietMealNutritionList = resp.data.codeMealPropertyList
      state.codeDietMealNutritionListLocalSelect = JSON.parse(JSON.stringify(state.codeDietMealNutritionList)) // deep copy
    },
    async setDietMealFilter ({ state, commit, dispatch }) {
      var difficultyCodeString = null
      var situationCodeString = null
      var mealPropertyCodeString = null

      let difficultyData = state.codeDietMealCookingDifficultyList.filter((w) => w.isSelected)[0]
      if (difficultyData) {
        state.codeDietMealCookingDifficultyList.forEach(element => {
            if (element.isSelected) {
                if (difficultyCodeString === null) {
                  difficultyCodeString = element.code
                } else {
                  difficultyCodeString += ',' + element.code
                }
            }
        })
      }

      let situationData = state.codeDietMealSituationList.filter((w) => w.isSelected)[0]
      if (situationData) {
        situationCodeString = situationData.code
      }

      let nutritionData = state.codeDietMealNutritionList.filter((w) => w.isSelected)[0]
      if (nutritionData) {
        state.codeDietMealNutritionList.forEach(element => {
          if (element.isSelected) {
            if (mealPropertyCodeString === null) {
              mealPropertyCodeString = element.code
            } else {
              mealPropertyCodeString += ',' + element.code
            }
          }
        })
      }

      let payload = {
        mallAccessToken: cookies.get('ncpAccessToken'),
        appType: cookies.get('appType'),
        appVersion: cookies.get('appVersion'),
        // UID: '308942_NoEqupiMB'
        cookingDifficultyCodeArrayString: difficultyCodeString,
        situationCodeArrayString: situationCodeString,
        mealPropertyCodeArrayString: mealPropertyCodeString
      }
      await getLBData(endpointDietMealSetFilterList, 'post', payload)
    },
    async setDietMealFavorite ({ state, commit, dispatch }, payload) {
      state.favoriteTotalCount = payload.isFavorite ? state.favoriteTotalCount + 1 : state.favoriteTotalCount - 1
      await getLBData(endpointSetFavorite, 'post', {mallAccessToken: cookies.get('ncpAccessToken'), dietMealWhatToEatIDX: payload.dietMealWhatToEatIDX, isFavorite: payload.isFavorite, appType: cookies.get('appType'), appVersion: cookies.get('appVersion')})
    },
    async sendMyMealReport ({ state, commit, dispatch }, payload) {
      await getLBData(endpointSendMyMealReport, 'post', {mallAccessToken: cookies.get('ncpAccessToken'), dietMealWhatToEatIDX: payload.dietMealWhatToEatIDX, message: payload.message, appType: cookies.get('appType'), appVersion: cookies.get('appVersion')})
    },
    async getFavoriteTotalCount ({ state, commit, dispatch }) {
      const resp = await getLBData(endpointDietMealFavoriteTotalCount, 'get', {mallAccessToken: cookies.get('ncpAccessToken'), appType: cookies.get('appType'), appVersion: cookies.get('appVersion')})
      state.favoriteTotalCount = resp.data.totalCount
    },
    async checkCommunityContentsPopupRendingInfo ({ state, commit, dispatch }) {
      state.CommunityContentsPopupRendingInfo = null
      const resp = await getLBData(endpointCommunityContentsPushRefuseSetup, 'post', {mallAccessToken: cookies.get('ncpAccessToken'), appType: cookies.get('appType'), appVersion: cookies.get('appVersion')})
      state.CommunityContentsPopupRendingInfo = resp.data
    },
    async checkCommunityContentsAlarmBanner ({ state, commit, dispatch }) {
      const resp = await getLBData(endpointCommunityContentsPushNotification, 'post', {mallAccessToken: cookies.get('ncpAccessToken'), appType: cookies.get('appType'), appVersion: cookies.get('appVersion')})
      state.needShowContentsAlarmBanner = resp.data.result
    },
    async checkCommunityContentsAlarmBannerClick ({ state, commit, dispatch }) {
      await getLBData(endpointCommunityContentsPushNotificationAdd, 'post', {mallAccessToken: cookies.get('ncpAccessToken'), appType: cookies.get('appType'), appVersion: cookies.get('appVersion')})
    }
  },
  mutations: {
    SET_INIT_FILTER (state) {
      state.codeDietMealCookingDifficultyListLocalSelect.forEach(element => {
        element.isSelected = false
      })

      state.codeDietMealSituationListLocalSelect.forEach(element => {
        element.isSelected = false
      })

      state.codeDietMealNutritionListLocalSelect.forEach(element => {
        element.isSelected = false
      })
    },
    SET_INIT_FILTER_AND_INIT_APPLY (state) {
      state.codeDietMealCookingDifficultyListLocalSelect.forEach(element => {
        element.isSelected = false
      })

      state.codeDietMealSituationListLocalSelect.forEach(element => {
        element.isSelected = false
      })

      state.codeDietMealNutritionListLocalSelect.forEach(element => {
        element.isSelected = false
      })
      state.codeDietMealCookingDifficultyList = JSON.parse(JSON.stringify(state.codeDietMealCookingDifficultyListLocalSelect))
      state.codeDietMealSituationList = JSON.parse(JSON.stringify(state.codeDietMealSituationListLocalSelect))
      state.codeDietMealNutritionList = JSON.parse(JSON.stringify(state.codeDietMealNutritionListLocalSelect))
    },
    SET_APPLY_FILTER (state) {
      state.codeDietMealCookingDifficultyList = JSON.parse(JSON.stringify(state.codeDietMealCookingDifficultyListLocalSelect))
      state.codeDietMealSituationList = JSON.parse(JSON.stringify(state.codeDietMealSituationListLocalSelect))
      state.codeDietMealNutritionList = JSON.parse(JSON.stringify(state.codeDietMealNutritionListLocalSelect))
      state.isNewFilterApply = true
    },
    INIT_NEW_FILTER_APPLY (state) {
      state.isNewFilterApply = false
    },
    COPY_FILTER (state) {
      if (state.codeDietMealCookingDifficultyList !== null) {
        state.codeDietMealCookingDifficultyListLocalSelect = JSON.parse(JSON.stringify(state.codeDietMealCookingDifficultyList))
      }

      if (state.codeDietMealSituationList !== null) {
        state.codeDietMealSituationListLocalSelect = JSON.parse(JSON.stringify(state.codeDietMealSituationList))
      }

      if (state.codeDietMealNutritionList !== null) {
        state.codeDietMealNutritionListLocalSelect = JSON.parse(JSON.stringify(state.codeDietMealNutritionList))
      }
    },
    SET_DETAIL_IDX (state, dietMealIDX) {
      var itemIDX = 0
      for (let idx = 0; idx < state.dietMealList.length; idx++) {
        let item = state.dietMealList[idx]
        if (item.dietMealWhatToEatIDX + '' === dietMealIDX + '') {
          itemIDX = idx
          break
        }
      }

      state.dietMealDetailItemIDX = itemIDX

      if (itemIDX === 0) {
        state.dietMealPrevNextFromSearch.isPrev = false
      } else {
        state.dietMealPrevNextFromSearch.isPrev = true
        state.dietMealPrevNextFromSearch.prevIDX = state.dietMealList[itemIDX - 1].dietMealWhatToEatIDX
      }

      if (itemIDX < state.dietMealPageInfo.totalCount - 1) {
        state.dietMealPrevNextFromSearch.isNext = true
        state.dietMealPrevNextFromSearch.nextIDX = state.dietMealList[itemIDX + 1].dietMealWhatToEatIDX
      } else {
        state.dietMealPrevNextFromSearch.isNext = false
      }

      // 리스트가 3개 이하로 남았을때 다음 페이지 목록 호출
      if (itemIDX >= state.dietMealPageInfo.pageSize * state.pageNum - 3) {
        state.dietMealPrevNextFromSearch.isNeedCallNextPage = true
      }
    },
    INIT_DETAIL_NEED_CALL (state) {
      state.dietMealPrevNextFromSearch.isNeedCallNextPage = false
    },
    INIT_ENTER_DIETMEAL_DETAIL (state) {
      state.enterDietMealDetail = false
    },
    SET_ENTER_DIETMEAL_DETAIL (state) {
      state.enterDietMealDetail = true
    },
    SET_DIETMEAL_FILTER_FROM_SEARCH_PAGE (state, filterInfo) {
      // 필터가 있는 경우 필터 적용
      // 뭐먹지 단독 검색화면 진입시 필터정보적용
      if (filterInfo !== null && filterInfo !== undefined) {
        let filterType = filterInfo.FilterType
        let code = filterInfo.Code

        state.codeDietMealCookingDifficultyList.forEach(element => {
          element.isSelected = false
        })
        state.codeDietMealSituationList.forEach(element => {
          element.isSelected = false
        })
        state.codeDietMealNutritionList.forEach(element => {
          element.isSelected = false
        })

        if (filterType === 'DIFFICULTY') {
          state.codeDietMealCookingDifficultyList.filter((w) => w.code === code)[0].isSelected = true
        } else if (filterType === 'SITUATION') {
          state.codeDietMealSituationList.filter((w) => w.code === code)[0].isSelected = true
        } else if (filterType === 'NUTRITION') {
          state.codeDietMealNutritionList.filter((w) => w.code === code)[0].isSelected = true
        }
      }
    },
    INIT_SEARCH_TEXT (state) {
      state.searchText = ''
    },
    SET_SEARCH_TEXT (state, searchText) {
      if (searchText) {
        state.searchText = searchText
      }
    },
    SET_BOOKMARK_IDX (state, whatToEatInfo) {
      if (state.dietMealList.length > 0) {
        let filteredWhatToEatInfo = state.dietMealList.filter((w) => w.dietMealWhatToEatIDX === whatToEatInfo.dietMealWhatToEatIDX)[0]
        if (filteredWhatToEatInfo !== undefined && filteredWhatToEatInfo !== null) {
          filteredWhatToEatInfo.isFavorite = whatToEatInfo.isFavorite
        }
      }
    }
  }
}
