import { createApp } from 'vue'
import { sync } from 'vuex-router-sync'
import App from './App'
import router from './router'
import store from './store'
import VueClipboard from 'vue3-clipboard'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import ClickOutside from 'vue-click-outside'
import VueCookies from 'vue3-cookies'
// import { createMetaManager } from 'vue-meta'
import lazyPlugin from 'vue3-lazy'
import VueVirtualScroller from 'vue-virtual-scroller'
// import loadingImage from '@/assets/image/LoadingGIF.gif'

sync(store, router)

let Vue = createApp(App)

Vue.use(store)
.use(router)
.use(lazyPlugin, {
  // loading: loadingImage,
  // error: 'error.png'
})
.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})
.use(VueSweetalert2, {
  confirmButtonColor: '#EC2618',
  denyButtonColor: '#999999',
})
// .use(createMetaManager())
.use(VueCookies, {
  expireTimes: "31d",
  path: "/",
  domain: "",
  secure: true,
  sameSite: "Strict"
})
.use(VueVirtualScroller)
.directive('click-outside', ClickOutside)
.mount('#wrap')