import { createNcpApiStore } from '@/api'
import cookies from 'js-cookie'
import config from '@/config'
import { commonAppCall } from '@/utils/utils'
import {getStrYMDHMSS, getStrDate, getToday} from '@/utils/dateUtils'

const apiStore = createNcpApiStore([
  {
    action: '_signUp',
    property: 'profile',
    path: 'profile',
    method: 'post'
  },
  {
    action: '_fetch',
    property: 'profile',
    path: 'profile',
    method: 'get'
  },
  {
    action: '_signUpOpenid',
    property: 'profile',
    path: 'profile/openid',
    method: 'post'
  },
  {
    action: '_delProfile',
    property: 'delResult',
    path: 'profile',
    method: 'delete'
  },
  {
    action: '_syncProfile',
    property: 'sync',
    path: 'profile/payco/sync',
    method: 'put'
  },
  {
    action: '_updateProfile',
    property: 'profile',
    path: 'profile',
    method: 'put'
  },
  {
    action: '_dormancy',
    property: 'profile',
    path: 'profile/dormancy',
    method: 'put'
  },
  {
    action: '_paycoSync',
    property: 'profile',
    path: 'profile/payco/sync',
    method: 'put'
  },
  {
    action: '_resetPassword',
    property: 'profile',
    path: 'profile/password',
    version: '1.1',
    method: 'put'
  },
  {
    action: '_findId',
    property: 'profile',
    path: 'profile/id',
    method: 'get'
  },
  {
    action: '_certificatedByEmail',
    property: 'certificatedByEmail',
    path: 'profile/password/no-authentication/certificated-by-email',
    method: 'put'
  },
  {
    action: '_findPW',
    property: 'profile',
    path: 'profile/find-password',
    method: 'post'
  },
  {
    action: '_emailExits',
    property: '_emailExits',
    path: 'profile/email/exist?email={email}',
    pathParams: ['email'],
    method: 'get'
  },
  {
    action: '_idExits',
    property: 'idExits',
    path: 'profile/id/exist?memberId={memberId}',
    pathParams: ['memberId'],
    method: 'get'
  },
  {
    action: '_idSearch',
    property: 'idSearch',
    path: 'profile/password/search-account?memberId={memberId}',
    pathParams: ['memberId'],
    method: 'get'
  },
  {
    action: '_sendingEmailWithUrl',
    property: 'sendingEmailWithUrl',
    path: 'profile/password/sending-email-with-url',
    method: 'put'
  },
  {
    action: '_checkPW',
    property: 'checkpw',
    path: 'profile/check-password',
    method: 'post'
  },
  {
    action: '_resetPasswordWithNotLogout',
    property: 'profile',
    path: 'profile',
    version: '1.1',
    method: 'put'
  },
  {
    action: '_fetchAccumulations',
    property: 'accumulations',
    path: 'profile/accumulations',
    method: 'get'
  },
  {
    action: '_fetchAccumulationsSummary',
    property: 'accumulationsSummary',
    path: 'profile/accumulations/summary',
    method: 'get'
  },
  {
    action: '_fetchAccumulationsSummary30',
    property: 'accumulationsSummary30',
    path: 'profile/accumulations/summary',
    method: 'get'
  }
])

export default {
  namespaced: true,
  state: {
    fetchAccumulationParams: {},
    listAccumulations: null,
    loading: true
  },
  mixins: [apiStore],
  actions: {
    async signUp ({ commit, dispatch, rootState }, payload) {
      await dispatch('_signUp', { data: payload })
    },
    async memberStatusChange ({ commit, dispatch, state, rootState }, shopAdChecked) {
      if (rootState.memberStatus !== 'ACTIVE') {
        const requestBody = shopAdChecked ? {
          directMailAgreed: true,
          smsAgreed: true
        } : { nodata: '' }
        await dispatch('_signUpOpenid', { data: requestBody })
      }
      await dispatch('_syncProfile')
      await dispatch('memberFetch')
    },
    async memberFetch ({ commit, dispatch, state, route, rootGetters }, payload) {
      // page 전환시 매번 호출되는 경우는 profile이 있으면 재호출하지 않는다.
      if (payload?.isNeedCheckExist && state.profile) {
        return state.profile
      }

      if (cookies.get('ncpAccessToken') && cookies.get('ncpAccessToken').length > 0) {
        await dispatch('_fetch')
        if (state.profile.additionalInfo !== null && state.profile.additionalInfo !== 'null') {
          const additionalInfo = JSON.parse(state.profile.additionalInfo)
          cookies.set('oldOrder', additionalInfo.old_order)
        }
        cookies.set('ncpMemberId', state.profile.memberId)
        cookies.set('ncpOauthIdNo', state.profile.oauthIdNo)
        cookies.set('memberStatus', state.profile.memberStatus)
        cookies.set('ncpCertificated', state.profile.principalCertificated)
        cookies.set('ncpAdultCertificated', state.profile.adultCertificated)
        return state.profile
      }
    },
    async delProfile ({ commit, dispatch, state }) {
      await dispatch('_delProfile', { params: { reason: '회원 탈퇴' } })
    },
    async certificatedByEmail ({ commit, dispatch, state }, params) {
      return dispatch('_certificatedByEmail', { data: { certificationNumber: params.certificationNumber, memberId: params.memberId, newPassword: params.newPassword } })
    },
    async updateProfile ({ commit, dispatch, state }, { smsAgreed, directMailAgreed }) {
      await dispatch('_updateProfile', { data: { smsAgreed, directMailAgreed } })
    },
    async sendingEmailWithUrl ({ commit, dispatch, state }, params) {
      return dispatch('_sendingEmailWithUrl', { data: { memberId: params.memberId, url: params.url } })
    },
    async dormancy ({ commit, dispatch, state }, { authType, certificationNumber, certificationServiceCode, ci, email, memberName, mobileNo }) {
      await dispatch('_dormancy', { data: { authType, certificationNumber, certificationServiceCode, ci, email, memberName, mobileNo } })
    },
    async paycoSync ({ commit, dispatch, state }) {
      await dispatch('_paycoSync')
      return dispatch('memberFetch')
    },
    async getProfileForAceTM ({ commit, dispatch, state }) {
      let ace = {
        uID: '',
        uAge: 0,
        uGender: ''
      }
      if (cookies.get('ncpAccessToken') && cookies.get('ncpAccessToken').length > 0) {
        await dispatch('_fetch')
        ace.uID = state.profile.oauthIdNo
        ace.uAge = state.profile.birthday ? new Date().getFullYear() - state.profile.birthday.substr(0, 4) : 0
        if (state.profile.sex === 'M') {
          ace.uGender = 'man'
        } else {
          ace.uGender = 'woman'
        }
      }
      return ace
    },
    async findId ({ commit, dispatch, state }, params) {
      return dispatch('_findId', { params })
    },
    async findPW ({ commit, dispatch, state }, params) {
      return dispatch('_findPW', { data: params })
    },
    emailExits ({ state, commit, dispatch }, email) {
      return dispatch('_emailExits', { params: { email } })
    },
    idExits ({ state, commit, dispatch }, memberId) {
      return dispatch('_idExits', { params: { memberId } })
    },
    idSearch ({ state, commit, dispatch }, memberId) {
      return dispatch('_idSearch', { params: { memberId } })
    },
    resetPassword ({ state, commit, dispatch }, params) {
      return dispatch('_resetPassword', { data: params })
    },
    async resetPasswordWithNotLogout ({ state, commit, dispatch }, params) {
      return dispatch('_resetPasswordWithNotLogout', { data: params })
    },
    async checkPW ({ state, commit, dispatch }, params) {
      return dispatch('_checkPW', { data: params })
    },
    async fetchAccumulations ({ state, commit, dispatch, rootState }, params) {
      let startYmd = params === undefined ? null : params.startYmd
      let endYmd = params === undefined ? null : params.endYmd
      if (startYmd !== null && startYmd !== undefined && endYmd !== null && endYmd !== undefined) {
        state.fetchAccumulationParams.startYmd = startYmd
        state.fetchAccumulationParams.endYmd = endYmd
      } else if (state.fetchAccumulationParams.startYmd === undefined || state.fetchAccumulationParams.startYmd === null || state.fetchAccumulationParams.endYmd === undefined || state.fetchAccumulationParams.endYmd === null) {
        var date = new Date()
        date.setMonth(date.getMonth() - 1)
        state.fetchAccumulationParams.startYmd = getStrDate(date)
        state.fetchAccumulationParams.endYmd = getToday()
      }
      await dispatch('_fetchAccumulations', { params: state.fetchAccumulationParams }).then(ret => {
        if (ret) {
          if (ret.data.items.length > 0) {
            ret.data.items.forEach(item => {
              item.registerYmdt = getStrDate(item.registerYmdt, '.')
            })
          }

          state.listAccumulations = ret.data
        }
      })
    },
    async fetchMoreAccumulations ({ state, commit, dispatch, rootState }) {
      let nowPageNumber = state.fetchAccumulationParams.pageNumber
      let totalAccumulationCount = state.listAccumulations.totalCount
      if (nowPageNumber * 20 > totalAccumulationCount) {
        state.loading = false
        return
      }

      state.fetchAccumulationParams.pageNumber = state.fetchAccumulationParams.pageNumber + 1

      await dispatch('_fetchAccumulations', { params: state.fetchAccumulationParams }).then(ret => {
        if (ret) {
          if (ret.data.items.length > 0) {
            ret.data.items.forEach(item => {
              item.registerYmdt = getStrDate(item.registerYmdt, '.')
            })
            state.listAccumulations.items.push(...ret.data.items)
          }
        }
      })
    },
    async fetchAccumulationsSummary ({ commit, dispatch, rootState }) {
      await dispatch('_fetchAccumulationsSummary').then(async () => {
        // 앱에 적립금 표시여부 전달
        commit('LANDING_UPDATE_REWARD', false)
      })
    },
    async fetchAccumulationsSummary30 ({ commit, dispatch, rootState }) {
      let current = new Date()
      let date = getStrYMDHMSS(current)
      let oneDay = 1000 * 24 * 60 * 60
      let date30 = getStrYMDHMSS(new Date(current.getTime() + oneDay * 30))
      await dispatch('_fetchAccumulationsSummary30', { params: {expireStartYmdt: date, expireEndYmdt: date30} })
    }
  },
  getters: {
    boardInquireInfo (state) {
      if (state.profile) {
        const memberInfo = {}
        let memberId = state.profile.memberId
        let telephoneNo = state.profile.mobileNo
        let email = state.profile.email
        if (memberId) {
          memberInfo.memberId = memberId
        }
        if (email) {
          memberInfo.email = email
        }
        if (telephoneNo) {
          if (telephoneNo.indexOf('-') > -1) {
            let telephoneArry = telephoneNo.split('-')
            memberInfo.telephoneNo1 = telephoneArry[0]
            memberInfo.telephoneNo2 = telephoneArry[1]
            memberInfo.telephoneNo3 = telephoneArry[2]
            memberInfo.telephoneNoflg = true
          }
        } else {
          memberInfo.telephoneNoflg = false
        }
        return memberInfo
      }
    }
  },
  mutations: {
    INIT_ACCUMULATION_PARAMS (state) {
      state.loading = true
      state.fetchAccumulationParams = {
        'pageNumber': 1,
        'pageSize': 20,
        'hasTotalCount': true,
        'startYmd': null,
        'endYmd': null
      }
    },
    LANDING_UPDATE_REWARD (state, isForceHide) {
      if (config.isLocalDevelopment) return

      if (isForceHide) {
        let data = {
          'ViewCode': 1,
          'RendingCode': 'LEARNBODY_REWARD_UPDATE',
          'RendingData': '0'
        }
      
        commonAppCall(data, true)
      } else {
        if (state.accumulationsSummary !== undefined
          && state.accumulationsSummary !== null) {
          let newCash = state.accumulationsSummary.totalAvailableAmt
          let cookieCash = cookies.get('clickMypageCash')
          if (cookieCash == null) {
            cookieCash = 0
          }
          let differentCash = newCash - cookieCash
          if (differentCash > 0) {
            let data = {
              'ViewCode': 1,
              'RendingCode': 'LEARNBODY_REWARD_UPDATE',
              'RendingData': '1'
            }
          
            commonAppCall(data, true)
          }
        }
      }
    }
  }
}
