import { createNcpApiStore, getLBData } from '@/api'
import { formatCurrency, telnoFormat, getshowOptions } from '@/utils/StringUtils'
import { getToday, getFirstAndEndDay, addMonth, getStrDate, getSixMonths } from '@/utils/dateUtils'
import cookies from 'js-cookie'
import qs from 'qs'
import router from '@/router'
import { loadTGScript, getReviewRewardTotalAmt, isAppleDevice } from '@/utils/utils'
import { parseYmdt } from '@learnbody/date'
import { calBuyConfirmReward } from '@/utils/reward'

const ENDPOINT_SAVEREWARDINFO = 'Order/UserOrderOptionAdd'

const defaultParams = {
  'orderRequestTypes': null,
  'pageNumber': 1,
  'pageSize': 10,
  'hasTotalCount': true,
  'startYmd': null,
  'endYmd': null
}

const apiStore = createNcpApiStore([
  {
    action: '_getOrder',
    property: 'order',
    path: 'profile/orders/{orderNo}',
    pathParams: ['orderNo']
  },
  {
    action: '_getOrderList',
    property: 'orders',
    path: 'profile/orders',
    method: 'get',
    queryParams: true,
    onSuccess (state, payload) {
      state.orders = [...state.orders || [], ...payload.data.items]
      state.totalCount = payload.data.totalCount
      state.orderTotalCount = payload.data.totalCount
    },
    requestConfig: {
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
    },
    cacheable: false,
    cacheMaxAge: 1000 * 30
  },
  {
    action: '_getClaimOrderOption',
    property: 'claimOrderOption',
    path: 'profile/order-options/{orderOptionNo}/claim',
    pathParams: ['orderOptionNo']
  },
  {
    action: '_getClaimEstimate',
    property: 'claimEstimate',
    path: 'profile/order-options/{orderOptionNo}/claim/estimate',
    pathParams: ['orderOptionNo']
  },
  {
    action: '_multipleClaimEstimate',
    property: 'multipleClaimEstimate',
    path: 'profile/claim/estimate',
    method: 'post'
  },
  {
    action: '_multipleClaimReturn',
    property: 'multipleClaimReturn',
    path: 'profile/claim/return',
    method: 'post'
  },
  {
    action: '_postOrderClaimCancel',
    path: 'profile/orders/{orderNo}/claim/cancel',
    pathParams: ['orderNo'],
    method: 'post'
  },
  {
    action: '_postOrderOptionClaimReturn',
    path: 'profile/order-options/{orderOptionNo}/claim/return',
    pathParams: ['orderOptionNo'],
    method: 'post'
  },
  {
    action: '_postOrderOptionClaimCancel',
    path: 'profile/order-options/{orderOptionNo}/claim/cancel',
    pathParams: ['orderOptionNo'],
    method: 'post'
  },
  {
    action: '_putOrderOptionClaimWithdraw',
    path: 'profile/claims/{claimNo}/withdraw',
    pathParams: ['claimNo'],
    method: 'put'
  },
  {
    action: '_putOrderOptionConfirm',
    path: 'profile/order-options/{orderOptionNo}/confirm',
    pathParams: ['orderOptionNo'],
    method: 'put'
  },
  {
    action: '_getClaimResult',
    property: 'claimResult',
    path: 'profile/order-options/{orderOptionNo}/claim/result',
    pathParams: ['orderOptionNo']
  },
  {
    action: '_getMultipleClaimResult',
    property: 'multipleClaimResult',
    path: 'profile/claims/{claimNo}/result',
    pathParams: ['claimNo']
  },
  {
    action: '_editDeliveries',
    property: 'deliverie',
    path: 'profile/orders/{orderNo}/deliveries',
    pathParams: ['orderNo'],
    method: 'put'
  }
])

export default {
  namespaced: true,
  state: {
    oldOrder: cookies.get('oldOrder') === 'Y',
    sixMonths: [],
    fetchListParams: {},
    totalCount: null,
    orderTotalCount: null,
    loading: true,
    selectTypeFlg: 'one_month',
    responsibleObjectTypelabel: '',
    orders: [],
    isOrdersResponded: false,
    isNeedSaveRewardInfo: false
  },
  mixins: [apiStore],
  actions: {
    async getOrder ({ state, commit, dispatch, getters, rootState }, orderNo) {
      if (!rootState.memberIsLogin() || !orderNo) {
        state.isNeedSaveRewardInfo = false
        return
      }
      await dispatch('_getOrder', { params: { orderNo } }).then(() => {
        if (state.isNeedSaveRewardInfo) {
          state.isNeedSaveRewardInfo = false
          if (state.order) {
            let saveRewardInfo = getters.seperatedBuyReward
            // 구매확정 적립금 서버에 저장
            dispatch('saveRewardInfo', saveRewardInfo) 
          }
        }
      })
      if (router.currentRoute._value.name === 'PaymentConfirm' && router.currentRoute._value.query.result === 'SUCCESS') {
        let payType = ''
        if (state.order.payTpye === 'CREDIT_CARD') {
          payType = '신용카드'
        }
        if (state.order.payTpye === 'VIRTUAL_ACCOUNT') {
          payType = '무통장입금'
        }
        if (state.order.payTpye === 'REALTIME_ACCOUNT_TRANSFER') {
          payType = '실시간 계좌이체'
        }
        // acecount
        window._AceTM = (window._AceTM || {})
        window._AceTM.Buy = {
          bOrderNo: orderNo, // 주문번호(필수)
          bTotalPrice: state.order.firstOrderAmount.payAmt.toString(), // 주문 총가격(필수)
          bPay: payType, // 지불방법(ex : 무통장,신용카드 ,~~~)
          bItem: [], // 주문리스트(필수)
          bDeliveryPrice: state.order.firstOrderAmount.deliveryAmt.toString() // 배송비
        }
        state.order.orderOptionsGroupByPartner.forEach(partner => {
          partner.orderOptionsGroupByDelivery.forEach(delivery => {
            delivery.orderOptions.forEach(option => {
              window._AceTM.Buy.bItem.push({
                pCode: option.productNo.toString(), // 제품아이디
                pName: option.productName, // 제품이름
                pQuantity: option.orderCnt, // 제품수량 or 옵션수량
                pPrice: option.price.buyAmt.toString(), // 판매가
                oCode: option.optionNo.toString(), // 옵션아이디
                oName: option.optionValue // 옵션이름
              })
            })
          })
        })
        // setTimeout(() => {
        //   window._AceTM.Buy.bItem.forEach(item => {
        //     // more
        //     let evtData = {
        //       evt: 'buy',
        //       order_id: orderNo,
        //       p_no: item.pCode,
        //       p_name: item.pName,
        //       price: item.pPrice,
        //       qty: item.pQuantity.toString()
        //     }
        //     window.mcroPushEvent(evtData)
        //   })
        // }, 500)
        loadTGScript(window._AceTM.Buy.bItem, 'OrderDetail')
      }
    },
    async getOrderList ({ state, commit, dispatch }, { type, to }) {
      type = to.query.type || type
      const month = to.query.month
      const period = to.query.period
      state.selectTypeFlg = 'one_month'
      if (month || month === 0) {
        state.selectTypeFlg = month
      }
      if (period) {
        state.selectTypeFlg = period
      }
      commit('RESET_LIST')
      // 페이지 사이즈 설정
      if (to.query.pageSize !== undefined) {
        commit('SET_PARAMS', {
          pageSize: to.query.pageSize
        })
      }
      if (month === undefined || month === '' || month === null) {
        if (period === 'six_month') {
          commit('SET_PARAMS', {
            startYmd: addMonth(new Date(), -6),
            endYmd: getToday()
          })
        } else if (period === 'three_month') {
          commit('SET_PARAMS', {
            startYmd: addMonth(new Date(), -3),
            endYmd: getToday()
          })
        } else {
          // period === 'one_month'
          commit('SET_PARAMS', {
            startYmd: addMonth(new Date(), -1),
            endYmd: getToday()
          })
        }
      } else {
        commit('SET_PARAMS', {
          ...getFirstAndEndDay(month)
        })
      }
      if (type === 'ORDER') {
        commit('SET_PARAMS', {
          orderRequestTypes: [
            'DEPOSIT_WAIT',
            'PAY_DONE',
            'PRODUCT_PREPARE',
            'DELIVERY_PREPARE',
            'DELIVERY_ING',
            'DELIVERY_DONE',
            'BUY_CONFIRM'
          ]
        })
      } else {
        commit('SET_PARAMS', {
          orderRequestTypes: [
            'CANCEL_DONE',
            'RETURN_DONE',
            'EXCHANGE_DONE',
            'CANCEL_PROCESSING',
            'RETURN_PROCESSING',
            'EXCHANGE_PROCESSING'
          ]
        })
      }

      await dispatch('_getOrderList', { params: state.fetchListParams })
      // 리뷰남기기 가능여부 확인
      await dispatch('getAllowWriteProductReviewList')
      commit('FORMAT_ORDERLIST', commit)
      state.orders.totalCount = state.totalCount
    },
    async getOrderListReload ({ state, commit, dispatch }, { type, _month, _period }) {
      const month = _month
      const period = _period
      state.selectTypeFlg = 'one_month'
      if (month || month === 0) {
        state.selectTypeFlg = month
      }
      if (period) {
        state.selectTypeFlg = period
      }
      commit('RESET_LIST')
      if (month === undefined || month === '' || month === null) {
        if (period === 'six_month') {
          commit('SET_PARAMS', {
            startYmd: addMonth(new Date(), -6),
            endYmd: getToday()
          })
        } else if (period === 'three_month') {
          commit('SET_PARAMS', {
            startYmd: addMonth(new Date(), -3),
            endYmd: getToday()
          })
        } else {
          // period === 'one_month'
          commit('SET_PARAMS', {
            startYmd: addMonth(new Date(), -1),
            endYmd: getToday()
          })
        }
      } else {
        commit('SET_PARAMS', {
          ...getFirstAndEndDay(month)
        })
      }
      if (type === 'ORDER') {
        commit('SET_PARAMS', {
          orderRequestTypes: [
            'DEPOSIT_WAIT',
            'PAY_DONE',
            'PRODUCT_PREPARE',
            'DELIVERY_PREPARE',
            'DELIVERY_ING',
            'DELIVERY_DONE',
            'BUY_CONFIRM'
          ]
        })
      } else {
        commit('SET_PARAMS', {
          orderRequestTypes: [
            'CANCEL_DONE',
            'RETURN_DONE',
            'EXCHANGE_DONE',
            'CANCEL_PROCESSING',
            'RETURN_PROCESSING',
            'EXCHANGE_PROCESSING'
          ]
        })
      }

      await dispatch('_getOrderList', { params: state.fetchListParams })
      // 리뷰남기기 가능여부 확인
      await dispatch('getAllowWriteProductReviewList')
      commit('FORMAT_ORDERLIST', commit)
      state.orders.totalCount = state.totalCount
    },
    async getAllowWriteProductReviewList ({ state, dispatch, commit }) {
      // 주문목록의 리뷰 작성 가능 여부 확인
      var orderOptionNoString = ''

      // 구매확정인 상품들의 OrderOptionNo 추출 (해당 값으로 리뷰가 작성됐는지 확인)
      if (state.orders && state.orders.length > 0) {
        state.orders.forEach(order => {
          order.orderOptions.filter((w) => w.orderStatusType === 'BUY_CONFIRM').forEach(option => {
            if (orderOptionNoString === '') {
              orderOptionNoString = option.orderOptionNo
            } else {
              orderOptionNoString += ',' + option.orderOptionNo
            }
          })
        })
      }

      if (orderOptionNoString !== '') {
        const resp = await getLBData('/Product/AllowWriteProductReviewList', 'get', { orderProductOptionNoArrayString: orderOptionNoString })
        var strListWriteReviewOptionNo = ''
        if (resp.data && resp.data.length > 0) {
          resp.data.forEach(optionNo => {
            if (strListWriteReviewOptionNo === '') {
              strListWriteReviewOptionNo += optionNo
            } else {
              strListWriteReviewOptionNo += ',' + optionNo
            }
          })
        }

        if (strListWriteReviewOptionNo !== '') {
          state.orders.forEach(order => {
            order.orderOptions.filter((w) => w.orderStatusType === 'BUY_CONFIRM').forEach(option => {
              if (strListWriteReviewOptionNo.indexOf('' + option.orderOptionNo) > -1) {
                option.isPossibleWriteReview = true
              }
            })
          })
        }

        state.isOrdersResponded = true
      } else {
        state.isOrdersResponded = true
      }
    },
    async fetchMore ({ state, dispatch, commit, rootState }, payload) {
      commit('SET_PARAMS', {
        pageNumber: state.fetchListParams.pageNumber + 1
      })
      await dispatch('_getOrderList', { params: state.fetchListParams })
      // 리뷰남기기 가능여부 확인
      await dispatch('getAllowWriteProductReviewList')
      if (state.orders.length >= state.totalCount) {
        state.loading = false
      }
      commit('FORMAT_ORDERLIST', commit)
    },
    async getClaimOrderOption ({ state, commit, dispatch }, { claimParams }) {
      await dispatch('_getClaimOrderOption', { params: { orderOptionNo: claimParams.orderOptionNo, claimType: claimParams.claimType } })
      commit('INIT_MULTIPLE_CLAIM_ESTIMATE')
      commit('CALCULATE_ORDER_OPTION')
    },
    async getCancelEstimate ({ state, commit, dispatch }, claimParams) {
      if (claimParams.claimReasonType !== '') {
        await dispatch('_getClaimEstimate', { params: { orderOptionNo: claimParams.orderOptionNo, claimType: claimParams.claimType, productCnt: claimParams.productCnt, claimReasonType: claimParams.claimReasonType } })
      }
      commit('GET_RESPONSIBLEOBJECTTYPE', claimParams.claimReasonType)
    },
    async getReturnEstimate ({ state, commit, dispatch }, claimParams) {
      if (claimParams.claimReasonType !== '') {
        await dispatch('_getClaimEstimate', { params: { orderOptionNo: claimParams.orderOptionNo, claimType: claimParams.claimType, productCnt: claimParams.productCnt, claimReasonType: claimParams.claimReasonType, returnWayType: 'SELLER_COLLECT' } })
      }
      commit('GET_RESPONSIBLEOBJECTTYPE', claimParams.claimReasonType)
    },
    async multipleClaimEstimate ({ state, commit, dispatch }, claimRequest) {
      if (claimRequest.claimReasonType !== '') {
        await dispatch('_multipleClaimEstimate', { data: claimRequest })
      }
      commit('GET_RESPONSIBLEOBJECTTYPE', claimRequest.claimReasonType)
    },
    multipleClaimReturn ({ state, dispatch, commit, rootState }, claimRequest) {
      return dispatch('_multipleClaimReturn', { data: claimRequest })
    },
    postOrderOptionClaimReturn ({ state, dispatch, commit, rootState }, returntparam) {
      return dispatch('_postOrderOptionClaimReturn', { data: returntparam, params: { orderOptionNo: returntparam.orderOptionNo } })
    },
    postOrderOptionClaimCancel ({ state, dispatch, commit, rootState }, cancelparam) {
      return dispatch('_postOrderOptionClaimCancel', { data: cancelparam, params: { orderOptionNo: cancelparam.orderOptionNo } })
    },
    postOrderClaimCancel ({ state, dispatch, commit, rootState }, cancelparam) {
      let orderNo = cancelparam.orderNo
      delete cancelparam.orderNo
      return dispatch('_postOrderClaimCancel', { data: cancelparam, params: { orderNo: orderNo } })
    },
    putOrderOptionConfirm ({ state, dispatch, commit, rootState }, orderOptionNo) {
      return dispatch('_putOrderOptionConfirm', { params: { orderOptionNo: orderOptionNo } })
    },
    async getOrderDetail ({ state, commit, dispatch }, orderNo) {
      await dispatch('_getOrder', { params: { orderNo } })
      commit('FORMAT_ORDER', commit)
    },
    async putOrderOptionClaimWithdraw ({ state, dispatch, commit, rootState }, withdrawparam) {
      await dispatch('_putOrderOptionClaimWithdraw', { data: withdrawparam, params: { claimNo: withdrawparam.claimNo } })
    },
    async getClaimResult ({ state, dispatch, commit, rootState }, { claimParams }) {
      await dispatch('_getClaimResult', { params: { orderOptionNo: claimParams.orderOptionNo } })
      commit('FORMAT_CLAIM_RESULT')
    },
    async getMultipleClaimResult ({ state, dispatch, commit, rootState }, { claimParams }) {
      await dispatch('_getMultipleClaimResult', { params: { claimNo: claimParams.claimNo } })
      commit('FORMAT_MULTIPLE_CLAIM_RESULT')
    },
    async editDeliveries ({ state, dispatch, commit, rootState }, deliveriesParam) {
      await dispatch('_editDeliveries', { data: deliveriesParam.addressRequest, params: { add: false, orderNo: deliveriesParam.orderNo } })
    },
    // 상품별 구매확정 적립금 저장
    async saveRewardInfo ({ state }, payload) {
      await getLBData(ENDPOINT_SAVEREWARDINFO, 'post', {
        orderNo: payload.orderNo,
        orderOptions: payload.orderOptions, //JSON.stringify(payload.orderOptions),
        memberNo: cookies.get('userMemberNo'),
        mallAccessToken: cookies.get('ncpAccessToken'),
        appType: cookies.get('appType'),
        appVersion: cookies.get('appVersion')
      })

      console.log('' + state.log)
    },
    // 특정 주문의 상품별 구매확정 적립금 반환
    async getSeperatedBuyConfirmRewardInfo (state, orderInfo) {
      console.log('' + state.log)
      // 상품별 구매확정 적립금
      /**
       * 
          {
            "orderNo": "",
            "orderOptions": [{
                "productNo": "",
                "optionNo": "",
                "orderCnt": "",
                "reward": ""
              },
              {
                "productNo": "",
                "optionNo": "",
                "orderCnt": "",
                "reward": ""
              },
              {
                "productNo": "",
                "optionNo": "",
                "orderCnt": "",
                "reward": ""
              }
            ]
          }
       */
      let seperatedReward = {
        orderNo: orderInfo.orderNo,
        orderOptions: []
      }

      // 구매확정 정립금 계산
      let orderItem = orderInfo

      // 구매확정 정립금 계산
      /*
        * 상품판매가 / 즉시할인가 / 추가상품가 / 추가할인가는 상품단위로 값이 있음 
        * 배송비제외된 가격임
        상품갯수 : order.orderOptions[x].orderCnt
        상품판매가 : order.orderOptions[x].price.salePrice
        즉시할인가 : order.orderOptions[x].price.immediateDiscountAmt
        추가상품가(개별 옵션별로 달라지는 추가금액) : order.orderOptions[x].price.addPrice
        추가할인가(추가할인 프로모션으로 인한 할인금액) : order.orderOptions[x].price.additionalDiscountAmt

        *API상에서는 상품쿠폰할인금액에 주문 당 금액으로 반환되고 있음
        * [[[[WARNING]]]] 테스트서버 구축시 확인해봐야함
        * 우선 구매확정은 상품단위로 할 수 있으므로 {상품쿠폰할인금액} * {상품이 주문 총 합산금액에서 차지하는 비율}로 상품별로 분할해서 구매확정시 적립금을 지급해야함
        상품쿠폰할인금액 : order.lastOrderAmt.productCouponDiscountAmt

        *장바구니쿠폰할인금액과 적립금사용금액은 주문 당 금액으로 반환됨
        * 구매확정은 상품단위로 할 수 있으므로 {장바구니쿠폰할인금액} * {상품이 주문 총 합산금액에서 차지하는 비율}과 {적립금사용금액} * {상품이 주문 총 합산금액에서 차지하는 비율}로 상품별로 장바구니쿠폰할인금액과 적립금사용금액을 분할해서 구매확정시 적립금을 지급해야함
        장바구니쿠폰할인금액 : order.lastOrderAmt.cartCouponDiscountAmt
        적립금사용금액 : order.lastOrderAmt.subPayAmt
        
        * 참고
        배송비 : order.lastOrderAmt.deliveryAmt
        배송비할인금액 : order.lastOrderAmt.deliveryCouponDiscountAmt

        구매확정 적립금 = {(상품판매가 + 추가상품가) - (즉시할인가 + 추가할인가) - (상품쿠폰할인금액 * 상품이 주문 총 합산금액에서 차지하는 비율) - (장바구니쿠폰할인금액 * 상품이 주문 총 합산금액에서 차지하는 비율) - (적립금사용금액 * 상품이 주문 총 합산금액에서 차지하는 비율)} * 적립률
      */
      if (orderItem?.orderOptions !== null && orderItem?.orderOptions !== undefined && orderItem?.orderOptions.length > 0) {

        // 상품별 구매확정 적립금
        let reward = 0

        // 주문의 총 합산 금액 SUM(상품별((상품판매가 + 추가상품가) - (즉시할인가 + 추가할인가)))
        let totalOrderPrice = 0
        orderItem.orderOptions.forEach((option) => {
          totalOrderPrice += ((option.price.salePrice + option.price.addPrice) - (option.price.immediateDiscountAmt + option.price.additionalDiscountAmt)) * option.orderCnt
        })

        orderItem.orderOptions.forEach((option) => {
          // 적립금 계산
          reward = calBuyConfirmReward(parseYmdt(orderItem.orderYmdt, isAppleDevice()), totalOrderPrice, option.orderCnt, { 
            salePrice: option.price.salePrice,
            addPrice: option.price.addPrice,
            immediateDiscountAmt: option.price.immediateDiscountAmt,
            additionalDiscountAmt: option.price.additionalDiscountAmt 
          },{ 
            productCouponAmt: orderItem.lastOrderAmt.productCouponDiscountAmt,
            cartCouponAmt: orderItem.lastOrderAmt.cartCouponDiscountAmt,
            accumulationAmt: orderItem.lastOrderAmt.subPayAmt
          }, option.productNo)

          let orderOptionReward = {
            productNo: option.productNo,
            optionNo: option.optionNo,
            orderCnt: option.orderCnt,
            reward: reward
          }

          seperatedReward.orderOptions.push(orderOptionReward)
        })
      }

      return seperatedReward
    },
    // 특정 주문의 상품별 구매확정 적립금 반환 (주문상세페이지에서는 orderOptions가아닌 showOptions가 넘어옴 하...)
    async getSeperatedBuyConfirmRewardInfo2 (state, orderInfo) {
      console.log('' + state.log)
      // 상품별 구매확정 적립금
      /**
       * 
          {
            "orderNo": "",
            "orderOptions": [{
                "productNo": "",
                "optionNo": "",
                "orderCnt": "",
                "reward": ""
              },
              {
                "productNo": "",
                "optionNo": "",
                "orderCnt": "",
                "reward": ""
              },
              {
                "productNo": "",
                "optionNo": "",
                "orderCnt": "",
                "reward": ""
              }
            ]
          }
       */
      let seperatedReward = {
        orderNo: orderInfo.orderNo,
        orderOptions: []
      }

      // 구매확정 정립금 계산
      let orderItem = orderInfo

      // 구매확정 정립금 계산
      /*
        * 상품판매가 / 즉시할인가 / 추가상품가 / 추가할인가는 상품단위로 값이 있음 
        * 배송비제외된 가격임
        상품갯수 : order.orderOptions[x].orderCnt
        상품판매가 : order.orderOptions[x].price.salePrice
        즉시할인가 : order.orderOptions[x].price.immediateDiscountAmt
        추가상품가(개별 옵션별로 달라지는 추가금액) : order.orderOptions[x].price.addPrice
        추가할인가(추가할인 프로모션으로 인한 할인금액) : order.orderOptions[x].price.additionalDiscountAmt

        *API상에서는 상품쿠폰할인금액에 주문 당 금액으로 반환되고 있음
        * [[[[WARNING]]]] 테스트서버 구축시 확인해봐야함
        * 우선 구매확정은 상품단위로 할 수 있으므로 {상품쿠폰할인금액} * {상품이 주문 총 합산금액에서 차지하는 비율}로 상품별로 분할해서 구매확정시 적립금을 지급해야함
        상품쿠폰할인금액 : order.lastOrderAmt.productCouponDiscountAmt

        *장바구니쿠폰할인금액과 적립금사용금액은 주문 당 금액으로 반환됨
        * 구매확정은 상품단위로 할 수 있으므로 {장바구니쿠폰할인금액} * {상품이 주문 총 합산금액에서 차지하는 비율}과 {적립금사용금액} * {상품이 주문 총 합산금액에서 차지하는 비율}로 상품별로 장바구니쿠폰할인금액과 적립금사용금액을 분할해서 구매확정시 적립금을 지급해야함
        장바구니쿠폰할인금액 : order.lastOrderAmt.cartCouponDiscountAmt
        적립금사용금액 : order.lastOrderAmt.subPayAmt
        
        * 참고
        배송비 : order.lastOrderAmt.deliveryAmt
        배송비할인금액 : order.lastOrderAmt.deliveryCouponDiscountAmt

        구매확정 적립금 = {(상품판매가 + 추가상품가) - (즉시할인가 + 추가할인가) - (상품쿠폰할인금액 * 상품이 주문 총 합산금액에서 차지하는 비율) - (장바구니쿠폰할인금액 * 상품이 주문 총 합산금액에서 차지하는 비율) - (적립금사용금액 * 상품이 주문 총 합산금액에서 차지하는 비율)} * 적립률
      */
      if (orderItem?.showOptions !== null && orderItem?.showOptions !== undefined && orderItem?.showOptions.length > 0) {

        // 상품별 구매확정 적립금
        let reward = 0

        // 주문의 총 합산 금액 SUM(상품별((상품판매가 + 추가상품가) - (즉시할인가 + 추가할인가)))
        let totalOrderPrice = 0
        orderItem.showOptions.forEach((option) => {
          totalOrderPrice += ((option.price.salePrice + option.price.addPrice) - (option.price.immediateDiscountAmt + option.price.additionalDiscountAmt)) * option.orderCnt
        })

        orderItem.showOptions.forEach((option) => {
          // 적립금 계산
          reward = calBuyConfirmReward(parseYmdt(orderItem.orderYmdt, isAppleDevice()), totalOrderPrice, option.orderCnt, { 
            salePrice: option.price.salePrice,
            addPrice: option.price.addPrice,
            immediateDiscountAmt: option.price.immediateDiscountAmt,
            additionalDiscountAmt: option.price.additionalDiscountAmt 
          },{ 
            productCouponAmt: orderItem.lastOrderAmount.productCouponDiscountAmt,
            cartCouponAmt: orderItem.lastOrderAmount.cartCouponDiscountAmt,
            accumulationAmt: orderItem.lastOrderAmount.subPayAmt
          }, option.productNo)

          let orderOptionReward = {
            productNo: option.productNo,
            optionNo: option.optionNo,
            orderCnt: option.orderCnt,
            reward: reward
          }

          seperatedReward.orderOptions.push(orderOptionReward)
        })
      }

      return seperatedReward
    }
  },
  mutations: {
    RESET_LIST (state) {
      state.orders = null
      state.totalCount = null
      state.orderTotalCount = null
      state.loading = true
      state.fetchListParams = defaultParams
    },
    SET_LOADING (state, isLoading) {
      state.loading = isLoading
    },
    SET_PARAMS (state, params) {
      state.fetchListParams = {
        ...state.fetchListParams,
        ...params
      }
    },
    CALCULATE_ORDER_OPTION (state) {
      if (state.claimOrderOption) {
        state.claimParams = []
        const option = state.claimOrderOption.originalOption
        const showOptions = getshowOptions(option.optionUsed, option.optionType, option.optionName, option.optionValue, option.inputs, option.price.addPrice, option.orderCnt)
        state.claimOrderOption.originalOption['showOptions'] = showOptions
        // let param = {}
        // param.orderOptionNo = option.orderOptionNo
        // param.orderCnt = option.orderCnt
        // state.claimParams.push(param)

        const multiple = state.claimOrderOption.claimableOptions
        multiple.forEach((opt, index) => {
          const multipleOptions = getshowOptions(opt.optionUsed, opt.optionType, opt.optionName, opt.optionValue, opt.inputs, opt.price.addPrice, opt.orderCnt)
          state.claimOrderOption.claimableOptions[index]['showOptions'] = multipleOptions
          // param = {}
          // param.orderOptionNo = opt.orderOptionNo
          // param.orderCnt = opt.orderCnt
          // state.claimParams.push(param)
        })

        if (state.claimOrderOption.returnAddress) {
          const showReturnAddress = state.claimOrderOption.returnAddress
          let contacts = telnoFormat(state.claimOrderOption.returnAddress.receiverContact1)
          showReturnAddress['contact1_1'] = contacts[0]
          showReturnAddress['contact1_2'] = contacts[1]
          showReturnAddress['contact1_3'] = contacts[2]
          contacts = telnoFormat(state.claimOrderOption.returnAddress.receiverContact2)
          showReturnAddress['contact2_1'] = contacts[0]
          showReturnAddress['contact2_2'] = contacts[1]
          showReturnAddress['contact2_3'] = contacts[2]
          state.claimOrderOption.showReturnAddress = showReturnAddress
        }
        state.responsibleObjectTypelabel = ''
        state.claimEstimate = null
      }
    },
    FORMAT_CLAIM_RESULT (state) {
      if (state.claimResult) {
        const option = state.claimResult.claimedOption
        const showOptions = getshowOptions(option.optionUsed, option.optionType, option.optionName, option.optionValue, option.inputs, option.price.addPrice, option.orderCnt)
        state.claimResult['showOptions'] = showOptions
        if (state.claimResult.returnAddress) {
          const showReturnAddress = state.claimResult.returnAddress
          let contacts = telnoFormat(state.claimResult.returnAddress.contact1)
          showReturnAddress['contact1_1'] = contacts[0]
          showReturnAddress['contact1_2'] = contacts[1]
          showReturnAddress['contact1_3'] = contacts[2]
          contacts = telnoFormat(state.claimResult.returnAddress.contact2)
          showReturnAddress['contact2_1'] = contacts[0]
          showReturnAddress['contact2_2'] = contacts[1]
          showReturnAddress['contact2_3'] = contacts[2]
          state.claimResult.showReturnAddress = showReturnAddress
        }
      }
    },
    FORMAT_MULTIPLE_CLAIM_RESULT (state) {
      if (state.multipleClaimResult) {
        const option = state.multipleClaimResult.claimedOption
        const showOptions = getshowOptions(option.optionUsed, option.optionType, option.optionName, option.optionValue, option.inputs, option.price.addPrice, option.orderCnt)
        state.multipleClaimResult.claimedOption['showOptions'] = showOptions

        const multiple = state.multipleClaimResult.claimedOptions
        multiple.forEach((opt, index) => {
          const multipleOptions = getshowOptions(opt.optionUsed, opt.optionType, opt.optionName, opt.optionValue, opt.inputs, opt.price.addPrice, opt.orderCnt)
          state.multipleClaimResult.claimedOptions[index]['showOptions'] = multipleOptions
        })

        if (state.multipleClaimResult.returnAddress) {
          const showReturnAddress = state.multipleClaimResult.returnAddress
          let contacts = telnoFormat(state.multipleClaimResult.returnAddress.contact1)
          showReturnAddress['contact1_1'] = contacts[0]
          showReturnAddress['contact1_2'] = contacts[1]
          showReturnAddress['contact1_3'] = contacts[2]
          contacts = telnoFormat(state.multipleClaimResult.returnAddress.contact2)
          showReturnAddress['contact2_1'] = contacts[0]
          showReturnAddress['contact2_2'] = contacts[1]
          showReturnAddress['contact2_3'] = contacts[2]
          state.multipleClaimResult.showReturnAddress = showReturnAddress
        }
      }
    },
    FORMAT_ORDER (state, commit) {
      if (state.order) {
        state.order['showYmd'] = getStrDate(state.order.orderYmdt, '.')

        if (state.order.payInfo && state.order.payInfo.cardInfo) {
          const cardInfo = state.order.payInfo.cardInfo
          if (cardInfo.installmentPeriod > 0) {
            if (cardInfo.noInterest) {
              cardInfo.installmentLabel = cardInfo.installmentPeriod + '개월 무이자'
            } else {
              cardInfo.installmentLabel = cardInfo.installmentPeriod + '개월'
            }
          } else {
            cardInfo.installmentLabel = '일시불'
          }
        }

        let showLabelFlg = true
        let showButtonFlg = true

        state.order.showOptions = []
        state.order.orderOptionsGroupByPartner.forEach(partner => {
          partner.orderOptionsGroupByDelivery.forEach(delivery => {
            if (delivery.deliveryPayType === 'PAY_ON_DELIVERY') {
              state.order['payOnDelivery'] = true
            }
            delivery.orderOptions.forEach(option => {
              commit('FORMAT_OPTION', option)
              state.order.showOptions.push(option)

              /*
              기본 주문 상태^||DEPOSIT_WAIT: 입금대기, PAY_DONE: 결제완료, PRODUCT_PREPARE: 상품준비중, DELIVERY_PREPARE: 배송준비중, DELIVERY_ING: 배송중, DELIVERY_DONE: 배송완료, BUY_CONFIRM: 구매확정, CANCEL_DONE: 취소완료, RETURN_DONE: 반품완료, EXCHANGE_DONE: 교환완료, PAY_WAIT: 결제대기, PAY_CANCEL: 결제포기, PAY_FAIL: 결제실패, DELETE: 삭제, EXCHANGE_WAIT: 교환대기, REFUND_DONE: 환불완료)
              */
              if (option.claimStatusType || (option.orderStatusType !== 'PRODUCT_PREPARE' && option.orderStatusType !== 'DELIVERY_PREPARE')) {
                showLabelFlg = false
              }
              /**
               * 22.01.06
               * 입금대기, 결제완료 상태에서는 배송지 정보 변경 버튼을 표시한다. (*상품준비중인 상태에서는 샵바이 API에서 주소변경이 안됨)
               * 배송준비중인 상태에서 배송지 정보를 변경하는 경우 배송지 정보가 꼬일 수 있음.
               * 초롱님도 상품준비중인 상태에서는 배송지 정보를 업체에 통보없이 변경하며, 이를 각 업체들에게 공지했다고함.
               */
              if (option.claimStatusType || (option.orderStatusType !== 'DEPOSIT_WAIT' && option.orderStatusType !== 'PAY_DONE')) {
                showButtonFlg = false
              }
            })
          })
        })
        const firstamt = state.order.firstOrderAmount
        const showPriceInfo = {}
        showPriceInfo.showStandardAmt = formatCurrency(firstamt.standardAmt)
        showPriceInfo.totalDisAmt = formatCurrency(firstamt.additionalDiscountAmt + firstamt.immediateDiscountAmt + firstamt.cartCouponDiscountAmt + firstamt.productCouponDiscountAmt + firstamt.subPayAmt)
        showPriceInfo.subPayAmt = formatCurrency(firstamt.subPayAmt)// 보조결제금액(적립금)
        showPriceInfo.immDisAmt = formatCurrency(firstamt.immediateDiscountAmt)
        showPriceInfo.addDisAmt = formatCurrency(firstamt.additionalDiscountAmt)
        showPriceInfo.proCouDisAmt = formatCurrency(firstamt.productCouponDiscountAmt)
        showPriceInfo.cartCouDisAmt = formatCurrency(firstamt.cartCouponDiscountAmt)
        showPriceInfo.totalDeliveryAmt = formatCurrency((firstamt.deliveryAmt - firstamt.deliveryCouponDiscountAmt) + firstamt.remoteDeliveryAmt)
        showPriceInfo.deliveryAmt = formatCurrency(firstamt.deliveryAmt)
        showPriceInfo.delCouDisAmt = formatCurrency(firstamt.deliveryCouponDiscountAmt)
        showPriceInfo.remDelAmt = formatCurrency(firstamt.remoteDeliveryAmt)
        showPriceInfo.payAmt = formatCurrency(firstamt.payAmt)
        showPriceInfo.chargeAmt = formatCurrency(firstamt.chargeAmt)// 사용자 결제 금액

        if (state.order.refundInfos) {
          const refundInfos = state.order.refundInfos
          let claimProductAmt = 0
          let claimSubtractionAmt = 0
          let claimDeliveryAmt = 0
          let refundPayAmt = 0
          refundInfos.forEach(refundInfo => {
            claimProductAmt += refundInfo.productAmtInfo.totalAmt
            claimSubtractionAmt += refundInfo.subtractionAmtInfo.totalAmt
            claimDeliveryAmt += refundInfo.deliveryAmtInfo.totalAmt
            refundPayAmt += refundInfo.refundPayAmt
          })
          showPriceInfo.claimProductAmt = formatCurrency(claimProductAmt)
          showPriceInfo.claimSubtractionAmt = formatCurrency(claimSubtractionAmt)
          showPriceInfo.claimDeliveryAmt = formatCurrency(claimDeliveryAmt)
          showPriceInfo.refundPayAmt = formatCurrency(refundPayAmt)
        }

        state.order.showPriceInfo = showPriceInfo

        const orderAddress = state.order.shippingAddress
        let contacts = telnoFormat(orderAddress.receiverContact1)
        orderAddress['contact1_1'] = contacts[0]
        orderAddress['contact1_2'] = contacts[1]
        orderAddress['contact1_3'] = contacts[2]
        contacts = telnoFormat(orderAddress.receiverContact2)
        orderAddress['contact2_1'] = contacts[0]
        orderAddress['contact2_2'] = contacts[1]
        orderAddress['contact2_3'] = contacts[2]

        orderAddress.deliveryMemo = state.order.deliveryMemo
        orderAddress.orderNo = state.order.orderNo
        orderAddress.showLabelFlg = showLabelFlg
        orderAddress.showButtonFlg = showButtonFlg

        state.order.orderAddress = orderAddress
      }
    },
    FORMAT_ORDERLIST (state, commit) {
      state.sixMonths = getSixMonths()

      if (state.orders && state.orders.length > 0) {
        state.orders.forEach(order => {
          order['showYmd'] = getStrDate(order.orderYmdt, '.')

          order.orderOptions.forEach(option => {
            commit('FORMAT_OPTION', option)
          })
        })
      }
    },
    FORMAT_OPTION (state, option) {
      option.showOptions = getshowOptions(option.optionUsed, option.optionType, option.optionName, option.optionValue, option.inputs, option.price.addPrice, option.orderCnt)

      option.buttons = []
      if (option.claimStatusType) {
        if (option.claimStatusType === 'CANCEL_REQUEST' || option.claimStatusType === 'CANCEL_PROC_REQUEST_REFUND' || option.claimStatusType === 'CANCEL_PROC_WAITING_REFUND') {
          option.statusLabel = '취소처리중'
          option.buttonCount = 0
          option.nextActions.forEach(nextAction => {
            if (nextAction.nextActionType === 'VIEW_CLAIM') {
              option.buttonCount += 1
              option.buttons.push('CANCEL_VIEW_CLAIM')
            } else if (nextAction.nextActionType === 'WITHDRAW_CANCEL') {
              option.buttonCount += 1
              option.buttons.push('WITHDRAW_CANCEL')
            }
          })
        } else if (option.claimStatusType === 'CANCEL_NO_REFUND' || option.claimStatusType === 'CANCEL_DONE') {
          option.statusLabel = '취소완료'
          option.buttonCount = 1
          option.buttons.push('CANCEL_VIEW_CLAIM')
        } else if (option.claimStatusType === 'RETURN_REQUEST' || option.claimStatusType === 'RETURN_REJECT_REQUEST' || option.claimStatusType === 'RETURN_PROC_BEFORE_RECEIVE' || option.claimStatusType === 'RETURN_PROC_REQUEST_REFUND' || option.claimStatusType === 'RETURN_PROC_WAITING_REFUND') {
          option.statusLabel = '반품처리중'
          option.buttonCount = 0
          option.nextActions.forEach(nextAction => {
            if (nextAction.nextActionType === 'VIEW_CLAIM') {
              option.buttonCount += 1
              option.buttons.push('RETURN_VIEW_CLAIM')
            } else if (nextAction.nextActionType === 'WITHDRAW_RETURN') {
              option.buttonCount += 1
              option.buttons.push('WITHDRAW_RETURN')
            }
          })
        } else if (option.claimStatusType === 'RETURN_DONE') {
          option.statusLabel = '반품완료'
          option.buttonCount = 1
          option.buttons.push('RETURN_VIEW_CLAIM')
        }
      } else {
        if (option.orderStatusType === 'PAY_DONE') {
          option.statusLabel = '결제완료'
          option.buttonCount = 1
          // ncp 서비스 어드민에서 해당상품 등록시 환불불가로 설정돼있어도 주문취소 표시하도록변경
          // if (option.refundable) {
          option.buttons.push('CANCEL')
          // } else {
          //   option.buttons.push('NOT_REFUNDABLE')
          // }
        } else if (option.orderStatusType === 'PRODUCT_PREPARE') {
          option.statusLabel = '상품준비중'
          option.buttonCount = 1
          // 23/03/15 상품준비중 상태에서 주문취소할 수 없도록 변경
          // ncp 서비스 어드민에서 해당상품 등록시 환불불가로 설정돼있어도 주문취소 표시하도록변경
          // if (option.refundable) {
          // option.buttons.push('CANCEL')
          // } else {
          option.buttons.push('NOT_REFUNDABLE')
          // }
        } else if (option.orderStatusType === 'DELIVERY_PREPARE') {
          option.statusLabel = '배송준비중'
          option.buttonCount = 1
          // 배송준비중인 경우 주문취소할 수 없도록
          option.buttons.push('NOT_REFUNDABLE')
        } else if (option.orderStatusType === 'DELIVERY_ING') {
          option.statusLabel = '배송중'
          option.buttonCount = 0

          // 구매확정
          option.buttonCount += 1
          option.buttons.push('PURCHASE_CONFIRM')

          option.nextActions.forEach(nextAction => {
            if (nextAction.nextActionType === 'VIEW_DELIVERY') {
              option.buttonCount += 1
              option.buttons.push('VIEW_DELIVERY')
            } else if (nextAction.nextActionType === 'WRITE_REVIEW') {
              option.buttonCount += 1
              option.buttons.push('WRITE_REVIEW')
            } else if (nextAction.nextActionType === 'RETURN' && option.refundable) {
              option.buttonCount += 1
              option.buttons.push('RETURN')
            }
          })
          if (option.deliverable && !option.refundable) {
            option.buttonCount += 1
            option.buttons.push('NOT_REFUNDABLE')
          }
        } else if (option.orderStatusType === 'DELIVERY_DONE') {
          option.statusLabel = '배송완료'
          option.buttonCount = 0

          // 구매확정
          option.buttonCount += 1
          option.buttons.push('PURCHASE_CONFIRM')

          option.nextActions.forEach(nextAction => {
            if (nextAction.nextActionType === 'VIEW_DELIVERY') {
              option.buttonCount += 1
              option.buttons.push('VIEW_DELIVERY')
            } else if (nextAction.nextActionType === 'WRITE_REVIEW') {
              option.buttonCount += 1
              option.buttons.push('WRITE_REVIEW')
            } else if (nextAction.nextActionType === 'RETURN' && option.refundable) {
              option.buttonCount += 1
              option.buttons.push('RETURN')
            }
          })
          if (option.deliverable && !option.refundable) {
            option.buttonCount += 1
            option.buttons.push('NOT_REFUNDABLE')
          }
        } else if (option.orderStatusType === 'BUY_CONFIRM') {
          option.statusLabel = '구매완료'
          option.buttonCount = 1
          if (option.nextActions.length === 1) {
            option.buttons.push('WRITE_REVIEW')
          }
          if (option.isPossibleWriteReview) {
            option.buttons.push('LB_WRITE_REVIEW')
          }

          // 배송조회
          option.buttonCount += 1
          option.buttons.push('VIEW_DELIVERY')
        } else if (option.orderStatusType === 'DEPOSIT_WAIT') {
          option.statusLabel = '입금대기'
          option.buttonCount = 1
          if (option.nextActions.length === 1) {
            option.buttons.push('CANCEL')
          }
        }
      }
      option['showPrice'] = formatCurrency(option.price.buyAmt)
    },
    GET_RESPONSIBLEOBJECTTYPE (state, claimReasonType) {
      if (state.claimOrderOption && claimReasonType !== '') {
        let claimReasonTypeInfo = {}
        state.claimOrderOption.claimReasonTypes.forEach(ctypeInfo => {
          if (ctypeInfo.claimReasonType === claimReasonType) {
            claimReasonTypeInfo = ctypeInfo
          }
        })
        if (claimReasonTypeInfo) {
          if (claimReasonTypeInfo.responsibleObjectType === 'SELLER') {
            state.responsibleObjectTypelabel = 'CJ프레시웨이 임직원몰 판매자가 배송비를 부담합니다'
          } else if (claimReasonTypeInfo.responsibleObjectType === 'BUYER') {
            state.responsibleObjectTypelabel = '구매자님께서 배송비를 부담합니다'
          }
        }
      } else {
        state.responsibleObjectTypelabel = ''
        state.claimEstimate = null
      }
    },
    INIT_MULTIPLE_CLAIM_ESTIMATE (state) {
      if (state.multipleClaimEstimate !== null) {
        state.multipleClaimEstimate = null
      }
    },
    INIT_ORDERS_RESPONDED_FLAG (state) {
      state.isOrdersResponded = false
    },
    // 구매확정 적립금 저장 필요여부 플래그 활성화
    SET_NEED_SAVE_REWARD_INFO (state) {
      state.isNeedSaveRewardInfo = true
    }
  },
  getters: {
    orderOptionProduct (state, getters, rootState) {
      if (state.order) {
        let orderOptionProductInfo = []
        state.order.orderOptionsGroupByPartner.forEach(partner => {
          partner.orderOptionsGroupByDelivery.forEach(delivery => {
            orderOptionProductInfo.push(...delivery.orderOptions.filter(orderOption => orderOption.orderOptionNo === Number(rootState.route.params.orderOptionNo)))
          })
        })
        const orderOptionProduct = orderOptionProductInfo[0]
        if (orderOptionProduct) {
          orderOptionProduct.showOptions = getshowOptions(orderOptionProduct.optionUsed, orderOptionProduct.optionType, orderOptionProduct.optionName, orderOptionProduct.optionValue, orderOptionProduct.inputs, orderOptionProduct.price.addPrice, orderOptionProduct.orderCnt)
        }
        return orderOptionProduct
      }
    },
    orderOptions (state, getters, rootState) {
      if (state.order) {
        let orderOptions = []
        state.order.orderOptionsGroupByPartner.forEach(partner => {
          partner.orderOptionsGroupByDelivery.forEach(delivery => {
            orderOptions.push(...delivery.orderOptions)
          })
        })
        return orderOptions
      }
    },
    claimNos (state) {
      if (state.orders) {
        const result = []
        let temp = []
        state.orders.forEach(order => {
          order.orderOptions.forEach((option, index) => {
            temp.push(option.claimNo)
            if (index === order.orderOptions.length - 1) {
              temp.push(null)
            }
          })
          result.push(temp)
          temp = []
        })
        return result
      }
    },
    returnInfo (state) {
      if (state.claimOrderOption) {
        const option = state.claimOrderOption.originalOption
        let param = {}
        if (option.orderStatueType !== 'BUY_CONFIRM' && option.refundable) {
          param.orderProductOptionNo = option.orderOptionNo
          param.productCnt = option.orderCnt
        }
        const claimParams = []
        claimParams.push(param)
        const multiple = state.claimOrderOption.claimableOptions
        multiple.forEach((opt, index) => {
          if (opt.orderStatusType !== 'BUY_CONFIRM' && opt.refundable) {
            param = {}
            param.orderProductOptionNo = opt.orderOptionNo
            param.productCnt = 0
            claimParams.push(param)
          }
        })
        return claimParams
      }
    },
    viewableClaimOptions (state) {
      if (state.claimOrderOption) {
        const claimParams = []
        const multiple = state.claimOrderOption.claimableOptions
        multiple.forEach((opt, index) => {
          if (opt.orderStatusType !== 'BUY_CONFIRM' && opt.refundable) {
            claimParams.push(opt)
          }
        })
        return claimParams
      }
    },
    // 조회된 주문 목록에서 받을 수 있는 총 구매확정 적립금 반환
    totalBuyConfirmReward (state) {
      // 총 구매확정 적립금
      let totalReward = 0

      // 구매확정 정립금 계산
      state.orders?.forEach((orderItem) => {
        /*
          * 상품판매가 / 즉시할인가 / 추가상품가 / 추가할인가는 상품단위로 값이 있음 
          * 배송비제외된 가격임
          상품갯수 : order.orderOptions[x].orderCnt
          상품판매가 : order.orderOptions[x].price.salePrice
          즉시할인가 : order.orderOptions[x].price.immediateDiscountAmt
          추가상품가(개별 옵션별로 달라지는 추가금액) : order.orderOptions[x].price.addPrice
          추가할인가(추가할인 프로모션으로 인한 할인금액) : order.orderOptions[x].price.additionalDiscountAmt

          *API상에서는 상품쿠폰할인금액에 주문 당 금액으로 반환되고 있음
          * [[[[WARNING]]]] 테스트서버 구축시 확인해봐야함
          * 우선 구매확정은 상품단위로 할 수 있으므로 {상품쿠폰할인금액} * {상품이 주문 총 합산금액에서 차지하는 비율}로 상품별로 분할해서 구매확정시 적립금을 지급해야함
          상품쿠폰할인금액 : order.lastOrderAmt.productCouponDiscountAmt

          *장바구니쿠폰할인금액과 적립금사용금액은 주문 당 금액으로 반환됨
          * 구매확정은 상품단위로 할 수 있으므로 {장바구니쿠폰할인금액} * {상품이 주문 총 합산금액에서 차지하는 비율}과 {적립금사용금액} * {상품이 주문 총 합산금액에서 차지하는 비율}로 상품별로 장바구니쿠폰할인금액과 적립금사용금액을 분할해서 구매확정시 적립금을 지급해야함
          장바구니쿠폰할인금액 : order.lastOrderAmt.cartCouponDiscountAmt
          적립금사용금액 : order.lastOrderAmt.subPayAmt
          
          * 참고
          배송비 : order.lastOrderAmt.deliveryAmt
          배송비할인금액 : order.lastOrderAmt.deliveryCouponDiscountAmt

          구매확정 적립금 = {(상품판매가 + 추가상품가) - (즉시할인가 + 추가할인가) - (상품쿠폰할인금액 * 상품이 주문 총 합산금액에서 차지하는 비율) - (장바구니쿠폰할인금액 * 상품이 주문 총 합산금액에서 차지하는 비율) - (적립금사용금액 * 상품이 주문 총 합산금액에서 차지하는 비율)} * 적립률
        */
        if (orderItem.orderOptions !== null && orderItem.orderOptions !== undefined && orderItem.orderOptions.length > 0) {

          // 상품별 구매확정 적립금
          let reward = 0

          // 주문의 총 합산 금액 SUM(상품별((상품판매가 + 추가상품가) - (즉시할인가 + 추가할인가)))
          let totalOrderPrice = 0
          orderItem.orderOptions.forEach((option) => {
            totalOrderPrice += ((option.price.salePrice + option.price.addPrice) - (option.price.immediateDiscountAmt + option.price.additionalDiscountAmt)) * option.orderCnt
          })

          orderItem.orderOptions.forEach((option) => {
            if (option.orderStatusType === 'DELIVERY_ING' || option.orderStatusType === 'DELIVERY_DONE') {
              // 적립금 계산
              reward = calBuyConfirmReward(parseYmdt(orderItem.orderYmdt, isAppleDevice()), totalOrderPrice, option.orderCnt, { 
                salePrice: option.price.salePrice,
                addPrice: option.price.addPrice,
                immediateDiscountAmt: option.price.immediateDiscountAmt,
                additionalDiscountAmt: option.price.additionalDiscountAmt 
              },{ 
                productCouponAmt: orderItem.lastOrderAmt.productCouponDiscountAmt,
                cartCouponAmt: orderItem.lastOrderAmt.cartCouponDiscountAmt,
                accumulationAmt: orderItem.lastOrderAmt.subPayAmt
              }, option.productNo)

              totalReward += reward
            }
          })
        }
      })

      return totalReward
    },
    // 조회된 주문 목록에서 받을 수 있는 총 리뷰 작성 적립금
    totalReviewReward (state) {
      // 총 리뷰 작성 적립금
      let totalReward = 0

      // 총 리뷰 작성 적립금 계산
      state.orders?.forEach((orderItem) => {
        if (orderItem.orderOptions !== null && orderItem.orderOptions !== undefined && orderItem.orderOptions.length > 0) {
          orderItem.orderOptions.forEach((option) => {
            if (option.orderStatusType === 'DELIVERY_ING' || option.orderStatusType === 'DELIVERY_DONE') {
                // 리뷰 작성 포인트 누적 계산
                totalReward += getReviewRewardTotalAmt(option.price.buyAmt)
            } else if (option.orderStatusType === 'BUY_CONFIRM' && option.isPossibleWriteReview) {
                // 리뷰 작성 포인트 누적 계산
                totalReward += getReviewRewardTotalAmt(option.price.buyAmt)
            }
          })
        }
      })

      return totalReward
    },
    // 구매확정시 받을 수 있는 상품별 적립금
    seperatedBuyReward (state) {
      // 상품별 구매확정 적립금
      /**
       * 
          {
            "orderNo": "",
            "orderOptions": [{
                "productNo": "",
                "optionNo": "",
                "orderCnt": "",
                "reward": ""
              },
              {
                "productNo": "",
                "optionNo": "",
                "orderCnt": "",
                "reward": ""
              },
              {
                "productNo": "",
                "optionNo": "",
                "orderCnt": "",
                "reward": ""
              }
            ]
          }
       */
      let seperatedReward = {
        orderNo: state.order.orderNo,
        orderOptions: []
      }

      // 구매확정 정립금 계산
      let orderItem = state.order
      /*
        * 상품판매가 / 즉시할인가 / 추가상품가 / 추가할인가는 상품단위로 값이 있음 
        * 배송비제외된 가격임
        상품판매가 : order.orderOptions[x].price.salePrice
        즉시할인가 : order.orderOptions[x].price.immediateDiscountAmt
        추가상품가(개별 옵션별로 달라지는 추가금액) : order.orderOptions[x].price.addPrice
        추가할인가(추가할인 프로모션으로 인한 할인금액) : order.orderOptions[x].price.additionalDiscountAmt

        *API상에서는 상품쿠폰할인금액에 주문 당 금액으로 반환되고 있음
        * [[[[WARNING]]]] 테스트서버 구축시 확인해봐야함
        * 우선 구매확정은 상품단위로 할 수 있으므로 {상품쿠폰할인금액} * {상품이 주문 총 합산금액에서 차지하는 비율}로 상품별로 분할해서 구매확정시 적립금을 지급해야함
        상품쿠폰할인금액 : order.lastOrderAmt.productCouponDiscountAmt

        *장바구니쿠폰할인금액과 적립금사용금액은 주문 당 금액으로 반환됨
        * 구매확정은 상품단위로 할 수 있으므로 {장바구니쿠폰할인금액} * {상품이 주문 총 합산금액에서 차지하는 비율}과 {적립금사용금액} * {상품이 주문 총 합산금액에서 차지하는 비율}로 상품별로 장바구니쿠폰할인금액과 적립금사용금액을 분할해서 구매확정시 적립금을 지급해야함
        장바구니쿠폰할인금액 : order.lastOrderAmt.cartCouponDiscountAmt
        적립금사용금액 : order.lastOrderAmt.subPayAmt
        
        * 참고
        배송비 : order.lastOrderAmt.deliveryAmt
        배송비할인금액 : order.lastOrderAmt.deliveryCouponDiscountAmt

        구매확정 적립금 = {(상품판매가 + 추가상품가) - (즉시할인가 + 추가할인가) - (상품쿠폰할인금액 * 상품이 주문 총 합산금액에서 차지하는 비율) - (장바구니쿠폰할인금액 * 상품이 주문 총 합산금액에서 차지하는 비율) - (적립금사용금액 * 상품이 주문 총 합산금액에서 차지하는 비율)} * 적립률
      */
      if (orderItem.orderOptionsGroupByPartner !== null && orderItem.orderOptionsGroupByPartner !== undefined && orderItem.orderOptionsGroupByPartner.length > 0) {

        // 상품별 구매확정 적립금
        let reward = 0

        // 주문의 총 합산 금액 SUM(상품별((상품판매가 + 추가상품가) - (즉시할인가 + 추가할인가)))
        let totalOrderPrice = 0

        orderItem.orderOptionsGroupByPartner.forEach((orderOptionGroupByPartner) => {
          orderOptionGroupByPartner.orderOptionsGroupByDelivery.forEach((orderOptionGroupByDelivery) => {
            orderOptionGroupByDelivery.orderOptions.forEach((option) => {
              totalOrderPrice += ((option.price.salePrice + option.price.addPrice) - (option.price.immediateDiscountAmt + option.price.additionalDiscountAmt)) * option.orderCnt
            })
          })
        })

        orderItem.orderOptionsGroupByPartner.forEach((orderOptionGroupByPartner) => {
          orderOptionGroupByPartner.orderOptionsGroupByDelivery.forEach((orderOptionGroupByDelivery) => {
            orderOptionGroupByDelivery.orderOptions.forEach((option) => {
              // 적립금 계산
              reward = calBuyConfirmReward(parseYmdt(orderItem.orderYmdt, isAppleDevice()), totalOrderPrice, option.orderCnt, { 
                salePrice: option.price.salePrice,
                addPrice: option.price.addPrice,
                immediateDiscountAmt: option.price.immediateDiscountAmt,
                additionalDiscountAmt: option.price.additionalDiscountAmt 
              },{ 
                productCouponAmt: orderItem.lastOrderAmount.productCouponDiscountAmt,
                cartCouponAmt: orderItem.lastOrderAmount.cartCouponDiscountAmt,
                accumulationAmt: orderItem.lastOrderAmount.subPayAmt
              }, option.productNo)

              let orderOptionReward = {
                productNo: option.productNo,
                optionNo: option.optionNo,
                orderCnt: option.orderCnt,
                reward: reward
              }

              seperatedReward.orderOptions.push(orderOptionReward)
            })
          })
        })
      }

      return seperatedReward
    },
    // 최근 주문자명 조회
    latestOrdererName (state) {
      let ordererName = ''

      if (state.orders && state.orders?.length > 0) {
        let latestOrderInfo = state.orders[0]
        ordererName = latestOrderInfo.orderer.name
      }

      return ordererName
    }
  }
}
