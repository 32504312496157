<template>
  <div id="wrap">
    <router-view name="header"></router-view>
    <router-view id="contents"></router-view>
    <main-pop @closePop="closeMainPop" v-if="!loading && popShowFlg && popShow && getPopupsData" :popupInfo="getPopupsData"></main-pop>
    <router-view name="footer"></router-view>
    <LBInviteDenyPopup v-if="checkShowInviteDenyPopup"></LBInviteDenyPopup>
  </div>
</template>

<script>
import MainPop from '@/components/main/MainPop'
import { mapState, mapGetters } from 'vuex'
import cookies from 'js-cookie'
import { getToday } from '@/utils/dateUtils'
import config from '@/config'
import { historyPage, checkIsFromAdultCert, getQueryValue, setCookieForYear } from '@/utils/utils'
import LBInviteDenyPopup from './components/LBInvite/LBInviteDenyPopup.vue'
import $ from 'jquery'
export default {
  data () {
    return {
      popShow: true
    }
  },
  name: 'app',
  metaInfo: {
    titleTemplate: (titleChunk) => {
      return titleChunk ? `${titleChunk} : 런바디` : '런바디'
    }
  },
  components: {
    MainPop,
    LBInviteDenyPopup
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        if (to.path === '/LBFeed' || to.path === '/LBMarket' || to.path === '/member/autologin' || to.path === '/member/login'
        || to.path === '/etc/dataerror' || to.path === '/tokenerror' || to.path === '/LBCrewApply') {
          this.$nextTick(() => this.$store.commit('lbPage/SET_APP_VIEW_FULL', false))
        } else {
          this.$store.commit('lbPage/SET_APP_VIEW_FULL', true)
        }
      }
    }
  },
  computed: {
    ...mapState('loading', ['loading']),
    ...mapState('lbCertification', ['isAdultProduct']),
    ...mapGetters('display', ['getPopupsData']),
    ...mapState('lbIOImitateOrderModal', ['isDSFreEatenFoodModalOpen', 'isDCFreEatenFoodModalOpen', 'isMDNutritionFactsModalOpen']),
    ...mapState('lbFeed', ['selectedValue']),
    ...mapState('lbMarketing', ['eventCoupon']),
    ...mapState('profile', ['accumulationsSummary']),
    popShowFlg () {
      if (cookies.get('ncpMainPop')) {
        return cookies.get('ncpMainPop') !== getToday()
      }
      return true
    },
    checkShowInviteDenyPopup () {
      let isShowInvitationDenyPopup = this.$cookies.get('isShowInvitationDenyPopup')
      if (isShowInvitationDenyPopup === 'true' && !config.isServiceMaintenance) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    closeMainPop () {
      this.popShow = false
    },
    appCallMoveToBack () { // eslint-disable-line no-unused-vars
      // 따라사기 AOS 뒤로가기 클릭시 모달 닫힘
      if (window.location.href.indexOf('LBImitateOrder/LBIODieterStoryV2') > -1) {
        if (this.isDSFreEatenFoodModalOpen) {
          this.$store.commit('lbIOImitateOrderModal/checkIsDSFreEatenFoodModalStatus', false)
          document.body.style.overflow = ''
          document.body.classList.remove('noscroll_class')
        } else {
          historyPage()
        }
      } else if (window.location.href.indexOf('LBImitateOrder/LBIODieterCategories') > -1) {
        if (this.isDCFreEatenFoodModalOpen) {
          this.$store.commit('lbIOImitateOrderModal/checkIsDCFreEatenFoodModalOpen', false)
          document.body.style.overflow = ''
          document.body.classList.remove('noscroll_class')
        } else {
          historyPage()
        }
      } else if (window.location.href.indexOf('LBImitateOrder/LBIOMealDetail') > -1) {
        if (this.isMDNutritionFactsModalOpen) {
          this.$store.commit('lbIOImitateOrderModal/checkIsMDNutritionFactsModalOpen', false)
          document.body.style.overflow = ''
        } else {
          historyPage()
        }
      } else historyPage()
    },
    CheckVisibility () {
      /**
       * 해당 로그는 추후 각 화면의 Mounted로 옮겨져야함
       * App.vue는 화면전환시 재호출되지 않으므로 다른 페이지 진입 후 돌아오는 경우 노출로그가 안찍히는 현상이 있음.
       * ex) 마켓 홈에서 신상 섹션의 상품별 노출로그가 상품상세 페이지 진입 후 돌아오면 찍히지 않음
       */
      const options = { threshold: 1.0 }
      for (let i = 0; i < document.querySelectorAll('.detectLog-item').length; i++) {
        const bannerBoxDiv = document.querySelectorAll('.detectLog-item')[i]
        const parentPageType = bannerBoxDiv.getAttribute('parentpagetype') === undefined || bannerBoxDiv.getAttribute('parentpagetype') === null ? '' : bannerBoxDiv.getAttribute('parentpagetype')

        if (parentPageType === 'NewHome' ||
        !($('.hotdealPageBanner').length > 0 || $('.newPageBanner').length > 0 ||
        $('.lb-event-popup').length > 0 || $('.search-result-total-market-container').length > 0 || $('.search-product-list.integreted-search').length > 0)) {
          continue
        }

        const callback = (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // observer.unobserve(entry.target)
              let productNum = bannerBoxDiv.getAttribute('num')
              let logCode = 'MarketItemFeedExpose'
              let itemPage = ''

              if ($('.hotdealPageBanner').length > 0) {
                // 마켓 홈 핫딜 탭
                itemPage = 'Hotdeal'
              } else if ($('.newPageBanner').length > 0) {
                // 마켓 홈 신상 탭
                itemPage = 'New'
              } else if ($('.lb-event-popup').length > 0) {
                // 마켓 홈 핫딜 탭 > 브랜드 최저가 핫딜 제품 상세보기
                itemPage = 'HotdealEventPage'
              } else if ($('.search-header').length > 0) {
                // 예전 검색
                itemPage = 'Search'
              } else if ($('.search-result-total-market-container').length > 0) {
                // 통합검색의 통합탭 결과 화면 상품노출로그 2가지 케이스 (1.결과화면 바로 진입 : https://learnbodymarket-slot.azurewebsites.net/LBIntegratedSearch/LBSearchPage?tabValue=Total&name=%EB%8B%AD&result=1, 2.마켓 홈에서 통합검색 진입)
                itemPage = 'Total'
              } else if ($('.search-product-list.integreted-search').length > 0) {
                // 통합검색의 마켓탭 결과 화면 상품노출로그 2가지 케이스 (1.결과화면 바로 진입 : https://learnbodymarket-slot.azurewebsites.net/LBIntegratedSearch/LBSearchPage?tabValue=Market&name=%EB%8B%AD&result=1, 2.마켓 홈에서 통합검색 진입)
                itemPage = 'Market'
              } else {
                itemPage = 'Home'
              }
              let marketingLogData = JSON.stringify({
                  marketLogCode: logCode,
                  MallProductNo: productNum,
                  Page: itemPage
              })
              this.$store.dispatch('lbMarketingLog/setMarketingLog', { marketingLogData: marketingLogData, logCode: logCode })
            }
          })
        }
        const observer = new IntersectionObserver(callback, options)
        observer.observe(
          bannerBoxDiv
        )
      }
    },
    // 인트로 모달 제거로 시작하기 클릭시 수행하던 액션을 해당 함수에서 수행한다.
    checkFirstUseMarket () {
      let marketStart = this.$cookies.get('marketStart')

      // 마켓 진입 이력이 있는 경우
      if (marketStart === 'false') {
        this.$cookies.set('marketStart', false, '180d')
        return
      }

      this.$cookies.set('marketStart', false)

      this.$store.dispatch('lbdata/sendMarketStart')
      setTimeout(() => {
        this.$store.dispatch('profile/fetchAccumulationsSummary')
        this.$store.dispatch('coupon/fetchMyCouponsSummary')
        this.$store.dispatch('validCoupon/getValidCoupons')
        this.$store.dispatch('validCoupon/moreCoupons')
      }, 2000)
    },
    // 메인 홈 롤링배너 목록 호출 (상품상세화면으로 바로 진입하는 경우 쿠폰 다운로드 툴팁이 떠야하므로)
    getHomeRollingBannerList () {
      let userMemberNo = cookies.get('userMemberNo')
      if (userMemberNo === undefined || userMemberNo === null || userMemberNo === '') { userMemberNo = '0' }
      this.$store.dispatch('lbMarketing/getHomeRollingBannerList', { memberNo: userMemberNo })
    },
    pageReload (tabName) {
      if ('MARKET' === tabName) {
        this.$router.replace({
          path: '/LBMarket'
        })
      } else {
        if (!this.selectedValue) {
          this.$store.commit('lbFeed/SET_SELECTED_VALUE', 0)
        }
        this.$router.replace({
          path: '/LBFeed'
        })
      }
    },
    clickRewardPage () {
      cookies.set('clickMypageCash', this.accumulationsSummary.totalAvailableAmt)
    },
    clickIntegratedSearchPage () {
      this.$router.push({
        name: 'NewSearch',
        query: {
          tabValue: 'Total'
        }
      })
    },
    clickProfilePage () {
      this.$router.push({
        name: 'LBProfile',
        query: { 
          targetUId: cookies.get('memberCummunityUID')
      }})
    },
    clickCartPage () {
      this.$router.push({
        name: 'Cart'
      })
    },
    sendFeedAccessLog () {
      let logCode = 'FeedAccessLog'

      let marketingLogData = JSON.stringify({
        UID: cookies.get('memberCummunityUID')
      })
      this.$store.dispatch('lbMarketingLog/setMarketingLog', { marketingLogData: marketingLogData, logCode: logCode, logTableName: 'MarketingLog' })
    },
    sendMarketAccessLog () {
      let logCode = 'MarketAccessLog'

      let marketingLogData = JSON.stringify({
        UID: cookies.get('memberCummunityUID')
      })
      this.$store.dispatch('lbMarketingLog/setMarketingLog', { marketingLogData: marketingLogData, logCode: logCode, logTableName: 'MarketingLog' })
    }
  },
  beforeCreate () {
    // 본인인증 완료인 경우 리턴 (부모뷰의 href를 변경해줌)
    var tempIsFromAdultCert = getQueryValue(window, 'isFromAdultCert')
    if (checkIsFromAdultCert(window, tempIsFromAdultCert)) {
      console.log('checkIsFromAdultCert true')
      // return
    }
  },
  mounted () {

    window.appCallMoveToBack = this.appCallMoveToBack
    window.PageReload = this.pageReload
    window.ClickRewardPage = this.clickRewardPage
    window.ClickIntegratedSearchPage = this.clickIntegratedSearchPage
    window.ClickProfilePage = this.clickProfilePage
    window.ClickCartPage = this.clickCartPage
    window.SendFeedAccessLog = this.sendFeedAccessLog
    window.SendMarketAccessLog = this.sendMarketAccessLog

    this.checkFirstUseMarket()

    setTimeout(() => {
      this.CheckVisibility()
    }, 3000)

    if (cookies.get('ncpAccessToken') !== undefined) {
      this.getHomeRollingBannerList()

      // 앱에 전달하기 위해 장바구니/적립금 조회
      this.$store.dispatch('cart/fetchCartCount')
      this.$store.dispatch('profile/fetchAccumulationsSummary')
    }

    // 피드 메인 화면 첫 로드시 쿼리스트링을 체크했는지 여부 초기화
    setCookieForYear('IsCheckedCategoryTabQueryString','0')

    // 적립금 7% 추가 적립 이벤트 기한 체크
    this.$store.commit('lbMarketing/SET_IS_IN_EVENT_PERIOD')
    // 적립금 7% 추가 적립 이벤트 쿠폰 조회
    let userMemberNo = cookies.get('userMemberNo')
    if (userMemberNo === undefined || userMemberNo === null || userMemberNo === '') { userMemberNo = '0' }
    this.$store.dispatch('lbMarketing/getEventCoupon', { memberNo: userMemberNo }).then(() => {
      // 해당 코드만 띠배너를 위한 값 저장
      if (this.eventCoupon.eventTypeCode === 'EVENT_DOWNLOAD') {
        this.$store.commit('lbMarketing/UPDATE_IS_COUPON_DOWNLOADABLE', this.eventCoupon.isDownloadCompleted)
      }
    })

    console.log('')
  }
}
</script>

<style lang="scss">
@import './styles/normalization.css';
@import './styles/slick.css';
@import './styles/market-layout.css';
</style>
