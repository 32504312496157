
import { getLBData } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_NUTRIENT = 'Product/Nutrient'
const ENDPOINT_AVERAGESCORE = 'Product/AverageScore'
const ENDPOINT_EXPERTREVIEWLIST = 'Product/ExpertReviewList'
const ENDPOINT_EXPERTREVIEWPREVIEWLIST = 'Product/ExpertReviewPreviewList'
const ENDPOINT_NUTRITIONALSTANDARDLIST = 'Product/NutritionalStandardList'

export default {
  namespaced: true,
  state: {
    pageInfo: {
      page: 0,
      pageSize: 0,
      pageCount: 0,
      totalCount: 0
    },
    parameter: {
      orderByTarget: 0,
      displayCategoryNo: 0,
      brandNoArrayString: '',
      page: 1,
      pageSize: 5
    },
    productNutrient: {},
    productAverageScore: {},
    productExpertReviewList: [],
    productExpertReviewPreviewList: [],
    productNutritionalStandardList: []
  },
  actions: {
    async getProductNutrient ({ state, commit, dispatch }, mallProductNo) {
      state.productNutrient = null
      const resp = await getLBData(ENDPOINT_NUTRIENT, 'get', { MallProductNo: mallProductNo })

      //  console.log('getProductNutrient : ' + resp.data)
      state.productNutrient = resp.data
    },
    async getProductAverageScore ({ state, commit, dispatch }, mallProductNo) {
      state.productAverageScore = null
      const resp = await getLBData(ENDPOINT_AVERAGESCORE, 'post', { MallProductNo: mallProductNo, MallAccessToken: cookies.get('ncpAccessToken') })

      //  console.log('getProductAverageScore : ' + resp.data)
      state.productAverageScore = resp.data
    },
    async getProductExpertReviewList ({ state, commit, dispatch }, payload) {
      // state.productExpertReviewList = null

      payload.mallAccessToken = cookies.get('ncpAccessToken')
      payload.appType = cookies.get('appType')
      payload.appVersion = cookies.get('appVersion')

      const resp = await getLBData(ENDPOINT_EXPERTREVIEWLIST, 'post', payload)

      //  console.log('getProductExpertReviewList : ' + resp.data)
      if (payload.page === 1) {
        state.parameter.page = 1
        state.productExpertReviewList = resp.data.reviewList
      } else {
        // console.log(resp.data)
        state.productExpertReviewList.push(...resp.data.reviewList)
        console.log(state.productExpertReviewList)
      }
      state.pageInfo = {
        page: resp.data.pageInfo.page,
        pageSize: resp.data.pageInfo.pageSize,
        pageCount: resp.data.pageInfo.pageCount,
        totalCount: resp.data.pageInfo.totalCount
      }
    },
    async getExpertReviewPreviewList ({ state, commit, dispatch }, mallProductNo) {
      state.productExpertReviewPreviewList = null
      const resp = await getLBData(ENDPOINT_EXPERTREVIEWPREVIEWLIST, 'get', { MallProductNo: mallProductNo, MallAccessToken: cookies.get('ncpAccessToken') })

      //  console.log('getProductExpertReviewList : ' + resp.data)
      //  console.log(resp.data)
      state.productExpertReviewPreviewList = resp.data
    },
    async getProductNutritionalStandardList ({ state, commit, dispatch }, mallProductNo) {
      state.productNutritionalStandardList = null
      const resp = await getLBData(ENDPOINT_NUTRITIONALSTANDARDLIST, 'get', { MallProductNo: mallProductNo })

      //  console.log('getProductNutritionalStandardList : ' + resp.data)
      state.productNutritionalStandardList = resp.data
    }
  }
}
