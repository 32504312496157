import { isAppleDevice } from '@/utils/utils'
import { isEqualOrBeforeYmdt, isEqualOrAfterYmdt } from '@learnbody/date'

/**
 * 상품의 구매확정 적립금 계산
 * @param { Date } orderDate 주문날짜
 * @param { Number } totalOrderPrice 총 상품금액
 * @param { Number } orderCnt 주문 갯수
 * @param { Object } priceInfo 상품 금액 정보
 * @param { Object } additionalDiscountInfo 주문시 적용된 쿠폰/적립금 할인 정보
 * @returns 
 */
export function calBuyConfirmReward(orderDate, totalOrderPrice, orderCnt, priceInfo, additionalDiscountInfo, productNo) {
  let reward = 0

  // 적립률
  let rewardRate = 0.01

  // 7% 이벤트 7/15~9/30
  // 7% 이벤트 시작일 '2023-07-14 23:59:59' 
  const eventStartDate = '2023-07-14 23:59:59'
  // 7% 이벤트 종료일 '2023-09-30 23:59:59'
  const eventEndDate = '2023-09-30 23:59:59'

  // 다이얼의 경우 7/16일까지 핫딜 기간이므로 10% 적립금을 지급한다.
  if (productNo === 102278120
    && isEqualOrBeforeYmdt(orderDate, '2023-07-16 23:59:59', isAppleDevice())) {
      rewardRate = 0.1
  } else {
    // 적립률 기본 1%이고, 23년 9월 30일 이벤트 기간까지는 7%로 한다.
    if (isEqualOrAfterYmdt(orderDate, eventStartDate, isAppleDevice())
    && isEqualOrBeforeYmdt(orderDate, eventEndDate, isAppleDevice())) {
      rewardRate = 0.07
    } else {
      rewardRate = 0.01
    }
  }

  // 실제 상품 결제금액
  let productUnitAmt = ((priceInfo.salePrice + priceInfo.addPrice) - (priceInfo.immediateDiscountAmt + priceInfo.additionalDiscountAmt)) * orderCnt
  
  // 상품이 주문 총 합산금액에서 차지하는 비율
  let productUnitRate = productUnitAmt/totalOrderPrice

  // 상품별 상품쿠폰할인금액
  let productUnitProductCouponDiscountAmt = additionalDiscountInfo.productCouponAmt * productUnitRate

  // 상품별 장바구니쿠폰할인금액
  let productUnitCartCouponDiscountAmt = additionalDiscountInfo.cartCouponAmt * productUnitRate

  // 상품별 적립금사용금액
  let productUnitRewardDiscountAmt = additionalDiscountInfo.accumulationAmt * productUnitRate

  // 상품별 기타종합할인금액 = 상품별 상품쿠폰할인금액 + 상품별 장바구니쿠폰할인금액 + 상품별 적립금사용금액
  let productUnitEtcTotalDiscountAmt = productUnitProductCouponDiscountAmt + productUnitCartCouponDiscountAmt + productUnitRewardDiscountAmt

  // 상품별 구매확정 정립금 계산 = (상품판매가 + 추가상품가) - (즉시할인가 + 추가할인가) - 상품별 기타종합할인금액
  reward = (productUnitAmt - productUnitEtcTotalDiscountAmt) * rewardRate
  // .0000000003인 케이스 처리
  reward = reward.toFixed(2)
  // 소수점 올림 처리
  reward = Math.ceil(reward)
  // 상품별 구매확정 적립금이 마이너스인경우 처리 (배송비까지 적립금을 사용하여 결제한 경우 발생할 수 있음)
  reward = reward < 0 ? 0 : reward

  return reward
}