import { getLBData } from '@/api'
import cookies from 'js-cookie'

const endpoint = 'Invitation/InvitationPageInfo'

export default {
  namespaced: true,
  state: {
    invitationPageInfo: null,
    invitedFriedsList: [],
    totalPurchaseFriendList: '-'
  },
  actions: {
    async getInvitationPageInfo ({ state, commit, dispatch }) {
      let mallAccessToken = cookies.get('ncpAccessToken')
      let appType = cookies.get('appType')
      let appVersion = cookies.get('appVersion')

      const resp = await getLBData(endpoint, 'get', { mallAccessToken: mallAccessToken, appType: appType, appVersion: appVersion })
      state.invitationPageInfo = resp.data

      var pageGroupList = []
      if (state.invitationPageInfo !== null && state.invitationPageInfo.invitationHistoryList.length > 0) {
          // 구매확정한 친구 수
          state.totalPurchaseFriendList = state.invitationPageInfo.invitationHistoryList.filter((w) => w.firstSalesRegisterType !== null && w.firstSalesRegisterType === 'BUY_CONFIRM').length + ''
          // 친구목록 deep copy
          let tempFriendList = JSON.parse(JSON.stringify(state.invitationPageInfo.invitationHistoryList))

          // 친구 페이징목록 생성
          state.invitedFriedsList = []
          var i = 0
          if (tempFriendList.length % 5 === 0) {
              tempFriendList.forEach(element => {
                  // 구매확정 여부 체크
                  if (element.firstSalesRegisterType !== null && element.firstSalesRegisterType === 'BUY_CONFIRM') {
                      element.isActive = true
                  } else {
                      element.isActive = false
                  }

                  pageGroupList.push(element)

                  // 5명씩 한페이지로 묶음
                  if ((i + 1) % 5 === 0) {
                      state.invitedFriedsList.unshift(pageGroupList)
                      pageGroupList = []
                  }
                  i++
              })
          } else {
              tempFriendList.forEach(element => {
                  // 구매확정 여부 체크
                  if (element.firstSalesRegisterType !== null && element.firstSalesRegisterType === 'BUY_CONFIRM') {
                      element.isActive = true
                  } else {
                      element.isActive = false
                  }

                  pageGroupList.push(element)

                  // 5명씩 한페이지로 묶음
                  if ((i + 1) % 5 === 0) {
                      state.invitedFriedsList.unshift(pageGroupList)
                      pageGroupList = []
                  } else if (i === tempFriendList.length - 1) {
                      // 친구를 초대해 보세요. 추가
                      for (var j = 0; j < 5 - (tempFriendList.length % 5); j++) {
                          let numberOffset = tempFriendList.length % 100
                          pageGroupList.push({userNickName: (j + numberOffset + 1) + '', isActive: false, isNeedAdd: true})
                      }
                      state.invitedFriedsList.unshift(pageGroupList)
                      pageGroupList = []
                  }
                  i++
              })
          }
      } else {
          state.totalPurchaseFriendList = '0'

          // 친구 페이징목록 생성
          state.invitedFriedsList = []
          pageGroupList.push({userNickName: '1', isActive: false, isNeedAdd: true})
          pageGroupList.push({userNickName: '2', isActive: false, isNeedAdd: true})
          pageGroupList.push({userNickName: '3', isActive: false, isNeedAdd: true})
          pageGroupList.push({userNickName: '4', isActive: false, isNeedAdd: true})
          pageGroupList.push({userNickName: '5', isActive: false, isNeedAdd: true})
          state.invitedFriedsList.push(pageGroupList)
          pageGroupList = []
      }
    }
  },
  mutations: {
    INIT_FRIENDS_LIST (state) {
        state.invitedFriedsList = []
    }
  }
}
