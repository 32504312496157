export default {
  namespaced: true,
  state: {
    eventShow: false
  },
  mutations: {
    clickEventShow (state) {
      if (state.eventShow === false) {
        state.eventShow = true
      } else {
        state.eventShow = false
      }
    }
  }
}
