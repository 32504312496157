<template>
  <div class="header-title-box detail-header sticky-header">
        <!-- <p class="icon-close btn_home" @click="$router.go(-1)"></p> -->
        <p class="icon-close btn_home" @click="goBack"></p>
        <span class="header-title">주문완료</span>
        <LBLoading></LBLoading>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { historyPage } from '@/utils/utils'
import LBLoading from '../common/LBLoading'

export default {
  computed: {
    isWebView () {
      return process.env.BUILD_TARGET === 'webview'
    },
    ...mapState('cart', ['count']),
    updateBadgeBGColor () {
      if (this.count.count > 0) {
        return 'background-color: rgba(236, 38, 25, 0.86);'
      } else {
        return 'background-color: rgba(148, 157, 164, 0.86);'
      }
    }
  },
  methods: {
    goBack () {
        console.log(this.$store.state.route)
        console.log(window.history)
        let result = this.$store.state.route.query.result.toLowerCase() === 'fail'
        if (result) {
            this.$router.go(-3)
        } else {
            historyPage()
        }
      // this.$nextTick(() => this.$refs.solutionDetails.showCurrent(comm and))
    }
  },
  components: {
    LBLoading
  },
  mounted () {
    document.getElementById('LBloader').style.display = 'flex'
      setTimeout(() => {
        document.getElementById('LBloader').style.display = 'none'
    }, 500)
  }
}
</script>

<style scoped>
#contents.cart_list_con {padding-top: 0;}
.header-title-box .icon-close {
    width: 40px; height: 40px; display: inline-block;
    background: url(../../assets/image/btn_back@3x.png) no-repeat center;
    background-size: contain;
}
</style>
