export default {
  namespaced: true,
  state: {
    // DieterStory: DS
    // DieterCategories: DC
    // MealDetail: MD
    isDSFreEatenFoodModalOpen: false,
    isDCFreEatenFoodModalOpen: false,
    isMDNutritionFactsModalOpen: false
  },
  actions: {
  },
  mutations: {
    checkIsDSFreEatenFoodModalStatus (state, payload) {
      state.isDSFreEatenFoodModalOpen = payload
    },
    checkIsDCFreEatenFoodModalOpen (state, payload) {
      state.isDCFreEatenFoodModalOpen = payload
    },
    checkIsMDNutritionFactsModalOpen (state, payload) {
      state.isMDNutritionFactsModalOpen = payload
    }
  }
}
