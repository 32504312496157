import { getLBData } from '@/api'
import cookies from 'js-cookie'

const endpoint = 'Product/BrandList'

export default {
  namespaced: true,
  state: {
    mallBrandList: [],
    selectedBrandNo: ''
  },
  actions: {
    async setParameterInit ({ state, commit, dispatch }) {
      state.selectedBrandNo = ''
      // selectedBrandNo를 사용하지 않도록 변경하였으므로, 당분간 아래 쿠키 삭제 구문을 남겨두고, 추후 삭제한다. (3/31 삭제)
      cookies.remove('selectedBrandNo')
    },
    async getMallBrandList ({ state, commit, dispatch }) {
      // 1. API를 통해서 MallProduct 리스트를 가져온다
      // 2. 반환값을 받아서 state에 적용
      // api
      //  console.log(state.parameter)
      const resp = await getLBData(endpoint, 'get')
      state.mallBrandList = resp.data
    }
  },
  mutations: {
    SET_SELECTED_BRAND_NO (state, brandNo) {
      state.selectedBrandNo = brandNo

      state.mallBrandList = state.mallBrandList.filter((brand) => {
        if (brand.brandNo + '' === brandNo + '') {
          brand.selected = brand.selected === undefined ? true : !brand.selected
        } else {
          brand.selected = false
        }
        return brand.brandNo !== null && brand.brandNo !== undefined
      })
    },
    SET_MALL_BRAND_LIST (state, brandList) {
      state.mallBrandList = brandList
    }
  }
}
