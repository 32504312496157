import { getLBData } from '@/api'
import cookies from 'js-cookie'

export default {
  namespaced: true,
  state: {
    dietProblemSolutionGuideList: null,
    suggestedLearnBodySeries: null,
    communitySuggestedSearch: null,
    mallSuggestedSearch: null,
    dietMealSuggestedSituationList: null,
    dietMealSuggestedSearch: null,
    dietMealSituationList: null,
    dietMealPropertyList: null,
    mallPopularSearch: null,
    selectedTab: '',
    isResultPage: '', // '', '0', '1'
    storeSearchText: '',
    storeMarketSearchTarget: ''
  },
  actions: {
    async getDietProblemSolutionGuideList ({ state, commit, dispatch }, payload) {
      payload.mallAccessToken = cookies.get('ncpAccessToken')
      const resp = await getLBData('/Search/DietProblemSolutionGuideList', 'get', payload)
      state.dietProblemSolutionGuideList = resp.data
      console.log(state.dietProblemSolutionGuideList)
    },
    async getSuggestedLearnBodySeries ({ state, commit, dispatch }, payload) {
        payload.mallAccessToken = cookies.get('ncpAccessToken')
        const resp = await getLBData('/Search/SuggestedLearnBodySeries', 'get', payload)
        state.suggestedLearnBodySeries = resp.data
    },
    async getCommunitySuggestedSearch ({ state, commit, dispatch }, payload) {
        payload.mallAccessToken = cookies.get('ncpAccessToken')
        const resp = await getLBData('/Search/CommunitySuggestedSearch', 'get', payload)
        state.communitySuggestedSearch = resp.data
    },
    async getMallSuggestedSearch ({ state, commit, dispatch }, payload) {
        payload.mallAccessToken = cookies.get('ncpAccessToken')
        const resp = await getLBData('/Search/MallSuggestedSearch', 'get', payload)
        state.mallSuggestedSearch = resp.data
    },
    async getDietMealSuggestedSituationList ({ state, commit, dispatch }, payload) {
        payload.mallAccessToken = cookies.get('ncpAccessToken')
        const resp = await getLBData('/Search/DietMealSuggestedSituationList', 'get', payload)
        state.dietMealSuggestedSituationList = resp.data
    },
    async getDietMealSuggestedSearch ({ state, commit, dispatch }, payload) {
        payload.mallAccessToken = cookies.get('ncpAccessToken')
        const resp = await getLBData('/Search/DietMealSuggestedSearch', 'get', payload)
        state.dietMealSuggestedSearch = resp.data
    },
    async getDietMealSituationList ({ state, commit, dispatch }, payload) {
        payload.mallAccessToken = cookies.get('ncpAccessToken')
        const resp = await getLBData('/Search/DietMealSituationList', 'get', payload)
        state.dietMealSituationList = resp.data
    },
    async getDietMealPropertyList ({ state, commit, dispatch }, payload) {
        payload.mallAccessToken = cookies.get('ncpAccessToken')
        const resp = await getLBData('/Search/DietMealPropertyList', 'get', payload)
        state.dietMealPropertyList = resp.data
    },
    async getMarketPopularKeyWord ({ state, commit, dispatch }, payload) {
        payload.mallAccessToken = cookies.get('ncpAccessToken')
        const resp = await getLBData('/Search/MallPopularSearch', 'get', payload)
        state.mallPopularSearch = resp.data
    }
  },
  mutations: {
    INIT_ENTER_SUBSTITUTE_FOOD (state) {
      state.enterSubstituteFood = false
    },
    SET_ENTER_SUBSTITUTE_FOOD (state) {
      state.enterSubstituteFood = true
    },
    SET_SELECTED_TAB (state, tab) {
      state.selectedTab = tab
    },
    SET_IS_RESULT_PAGE (state, isResultPage) {
      state.isResultPage = isResultPage
    },
    SET_SEARCH_TEXT (state, searchText) {
      state.storeSearchText = searchText
    },
    SET_MARKET_SEARCH_TARGET (state, marketSearchTarget) {
      state.storeMarketSearchTarget = marketSearchTarget
    },
    INIT_SEARCH_STORE_PARAMS (state) {
      state.selectedTab = ''
      state.isResultPage = ''
      state.storeSearchText = ''
      state.storeMarketSearchTarget = ''
    }
  }
}
