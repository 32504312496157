import { getLBData } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_CAMPAIGN_WORKOUTDETAIL = 'CommunityPost/CampaignWorkoutDetail'
const ENDPOINT_CAMPAIGN_HIGHFIVEREG = 'Feed/HighFiveReg'
const ENDPOINT_CAMPAIGN_FAVORITEREG = 'Feed/FavoriteReg'
const ENDPOINT_UPDATE_FOLLOW = 'Profile/FollowReg'
const ENDPOINT_CONTENTS_NOT_TO_BE_MISSED_FEED = 'Feed/ContentsNotToBeMissedFeed'

const defaultParams = {
  uId: cookies.get('memberCummunityUID'),
  appType: cookies.get('appType'),
  appVersion: cookies.get('appVersion'),
  languageCode: 'ko',
  countryCode: '82',
  whereToUseCode: 'FEED',
  cuid: 0,
  challengeId: 0,
  utcInterval: -540,
  logCode: ''
}

export default {
  namespaced: true,
  state: {
    mainCampaignMeal: {
      feedTypeCode: 'CAMPAIGN_MEAL',
      filterTag: '세모단',
      title: '세상의 모든 단백질 식단을 공유해 보세요.',
      reward: 1000,
      desctiption: '단백질 음식을 꾸준히 챙기고 계시네요!\n‘세상 모든 단백질’ 소개하고 적립금 1,000원 받으세요.',
      buttonTitle: '식단 공유하고 적립금 받기',
      datas: [
        {
          imageUrl: 'https://learnbodykr.blob.core.windows.net/diary-user-photo/meal/2020/1/3309673_NoEqupiMB_20200101070000.jpeg',
          key: '3309673_NoEqupiMB^^MEAL^^20221001^^1',
          executeDate: '2022-10-01'
        },
        {
          imageUrl: 'https://learnbodykr.blob.core.windows.net/diary-user-photo/meal/2020/1/3309673_NoEqupiMB_20200101120000.jpeg',
          key: '3309673_NoEqupiMB^^MEAL^^20221001^^2',
          executeDate: '2022-10-01'
        },
        {
          imageUrl: 'https://learnbodykr.blob.core.windows.net/diary-user-photo/meal/2020/1/3309673_NoEqupiMB_20200101180000.jpeg',
          key: '3309673_NoEqupiMB^^MEAL^^20221001^^3',
          executeDate: '2022-10-01'
        },
        {
          imageUrl: 'https://learnbodykr.blob.core.windows.net/diary-user-photo/meal/2020/1/3309673_NoEqupiMB_20200102120000.jpeg',
          key: '3309673_NoEqupiMB^^MEAL^^20221002^^1',
          executeDate: '2022-10-02'
        },
        {
          imageUrl: 'https://learnbodykr.blob.core.windows.net/diary-user-photo/meal/2020/1/3882148_NoEqupiMB_20200101070000.jpeg',
          key: '3882148_NoEqupiMB^^MEAL^^20221001^^1',
          executeDate: '2022-10-01'
        },
        {
          imageUrl: 'https://learnbodykr.blob.core.windows.net/diary-user-photo/meal/2020/1/3882148_NoEqupiMB_20200101120000.jpeg',
          key: '3882148_NoEqupiMB^^MEAL^^20221001^^2',
          executeDate: '2022-10-01'
        },
        {
          imageUrl: 'https://learnbodykr.blob.core.windows.net/diary-user-photo/meal/2020/1/3882148_NoEqupiMB_20200101180000.jpeg',
          key: '3882148_NoEqupiMB^^MEAL^^20221001^^3',
          executeDate: '2022-10-01'
        },
        {
          imageUrl: 'https://learnbodykr.blob.core.windows.net/diary-user-photo/meal/2020/1/3882148_NoEqupiMB_20200101200000.jpeg',
          key: '3882148_NoEqupiMB^^MEAL^^20221001^^4',
          executeDate: '2022-10-01'
        },
        {
          imageUrl: 'https://learnbodykr.blob.core.windows.net/diary-user-photo/meal/2020/1/3882148_NoEqupiMB_20200102070000.jpeg',
          key: '3882148_NoEqupiMB^^MEAL^^20221002^^1',
          executeDate: '2022-10-02'
        },
        {
          imageUrl: 'https://learnbodykr.blob.core.windows.net/diary-user-photo/meal/2020/1/3882148_NoEqupiMB_20200102120000.jpeg',
          key: '3882148_NoEqupiMB^^MEAL^^20221002^^2',
          executeDate: '2022-10-02'
        },
        {
          imageUrl: 'https://learnbodykr.blob.core.windows.net/diary-user-photo/meal/2020/1/3882148_NoEqupiMB_20200103070000.jpeg',
          key: '3882148_NoEqupiMB^^MEAL^^20221003^^1',
          executeDate: '2022-10-03'
        },
        {
          imageUrl: 'https://learnbodykr.blob.core.windows.net/diary-user-photo/meal/2020/1/3882148_NoEqupiMB_20200103120000.jpeg',
          key: '3882148_NoEqupiMB^^MEAL^^20221003^^2',
          executeDate: '2022-10-03'
        },
        {
          imageUrl: 'https://learnbodykr.blob.core.windows.net/diary-user-photo/meal/2020/1/3223578_NoEqupiMB_20200101080000.jpeg',
          key: '3223578_NoEqupiMB^^MEAL^^20221001^^1',
          executeDate: '2022-10-01'
        },
        {
          imageUrl: 'https://learnbodykr.blob.core.windows.net/diary-user-photo/meal/2020/1/3223578_NoEqupiMB_20200102080000.jpeg',
          key: '3223578_NoEqupiMB^^MEAL^^20221001^^2',
          executeDate: '2022-10-01'
        },
        {
          imageUrl: 'https://learnbodykr.blob.core.windows.net/diary-user-photo/meal/2020/1/3223578_NoEqupiMB_20200101190000.jpeg',
          key: '3223578_NoEqupiMB^^MEAL^^20221001^^5',
          executeDate: '2022-10-01'
        }
      ]
    },
    mainCampaignBanner: {
      feedTypeCode: 'CAMPAIGN_BANNER',
      filterTag: null,
      title: null,
      reward: 0,
      desctiption: null,
      buttonTitle: null,
      datas: [
        {
          imageUrl: 'https://learnbodykr.blob.core.windows.net/market-images/EventPage_NON_FIRST_BUY_230201.png?v=20230131071000',
          key: null,
          executeDate: null
        }
      ]
    },
    campaignDetail: null,
    campaignDetailFollowState: false
  },
  mutations: {},
  actions: {
    async getCampaignDetail({ state, commit, dispatch }, payload) {
      payload = {
        ...defaultParams,
        ...payload
      }
      const resp = await getLBData(ENDPOINT_CAMPAIGN_WORKOUTDETAIL, 'post', payload)
      state.campaignDetail = resp.data
      return resp.data
    },
    async regCampaignHighfive({ state, commit, dispatch }, payload) {
      payload = {
        ...defaultParams,
        ...payload
      }
      const resp = await getLBData(ENDPOINT_CAMPAIGN_HIGHFIVEREG, 'post', payload)
      return resp.data
    },
    async regCampaignFavorite({ state, commit, dispatch }, payload) {
      payload = {
        ...defaultParams,
        ...payload
      }
      const resp = await getLBData(ENDPOINT_CAMPAIGN_FAVORITEREG, 'post', payload)
      return resp.data
    },
    async updateFollow({ state, commit, dispatch }, payload) {
      payload = {
        ...defaultParams,
        ...payload
      }
      const resp = await getLBData(ENDPOINT_UPDATE_FOLLOW, 'post', payload)
      return resp.data
    },
    async getContentsNotToBeMissedFeed({ state }, payload) {
      payload = {
        ...defaultParams,
        ...payload,
      }

      const resp = await getLBData(ENDPOINT_CONTENTS_NOT_TO_BE_MISSED_FEED, 'post', payload)
      return resp.data
    },
  }
};
