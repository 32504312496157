import { createNcpApiStore } from '@/api'
import cookies from 'js-cookie'
import ncpApi from '@/api/ncpApi'
import { logoutRemoveCookie } from '@/utils/utils'

const apiStore = createNcpApiStore([
  {
    action: 'openid',
    property: 'openid',
    path: 'oauth/openid'
  },
  {
    action: '_paycoToken',
    property: 'payToken',
    path: 'openid/token'
  },
  {
    action: 'delToken',
    property: 'result',
    path: 'oauth/token',
    method: 'delete'
  },
  {
    action: '_normalLogin',
    property: 'normalLogin',
    path: 'oauth/token',
    method: 'post'
  },
  {
    action: '_delOnlyBuywow',
    property: 'delOnlyBuywow',
    path: 'profile',
    method: 'delete'
  },
  {
    action: '_getDormancyInfo',
    property: 'dormancyInfo',
    path: 'profile/dormancy',
    method: 'get'
  },
  {
    action: '_sendMailCode',
    path: 'authentications/email',
    method: 'post'
  },
  {
    action: '_confirmMailCode',
    path: 'authentications/email',
    method: 'get'
  },
  {
    action: '_passwordMailCode',
    path: 'authentications/email',
    method: 'get'
  }
])

export default {
  namespaced: true,
  mixins: [apiStore],
  actions: {
    async normalLogin ({ state, commit, dispatch, rootState }, { memberId, password, keepLogin }) {
      return dispatch('_normalLogin', { data: { memberId: memberId, password: password, keepLogin: keepLogin } }).then((res) => {
        if (state.normalLogin.accessToken) {
          const d = new Date()
          if (keepLogin === true) {
            d.setTime(d.getTime() + 1000 * 60 * 60 * 24 * 30)
          } else {
            d.setTime(d.getTime() + 1000 * state.normalLogin.expireIn)
          }
          cookies.set('ncpAccessToken', state.normalLogin.accessToken, { 'expires': d })
          ncpApi.defaults.headers.accessToken = state.normalLogin.accessToken
        }
        return res
      })
    },
    async logout ({ state, commit, dispatch }) {
      await dispatch('delToken')
      logoutRemoveCookie()
      window.location.href = '/'
    },
    async fetchShopAgreement ({ state, commit, dispatch }) {
      await cookies.set('ncpMemberAgree', 'Y')
    },
    async setShopAgreementY ({ state, commit, dispatch }) {
      cookies.set('ncpMemberAgree', 'Y')
    },
    async setShopAgreementN ({ state, commit, dispatch }) {
      cookies.set('ncpMemberAgree', 'N')
    },
    async paycoCode2AccessToken ({ state, commit, dispatch, rootState }, { code, nxturl }) {
      const osType = rootState.osType()
      await dispatch('openid', { params: { code: code, provider: 'payco', platformType: osType } })
      /** save ncp token */
      cookies.set('ncpAccessToken', state.openid.accessToken, { 'max-age': state.openid.expireIn })
      ncpApi.defaults.headers.accessToken = state.openid.accessToken
      if (!state.openid.dormantMemberResponse) {
        await dispatch('_paycoToken')
        cookies.set('buywowAccessToken', state.payToken.accessToken)
      }
    },
    async paycoToken ({ state, commit, dispatch, rootState }) {
      await dispatch('_paycoToken')
      cookies.set('buywowAccessToken', state.payToken.accessToken)
    },
    async paycoToken2AccessToken ({ state, commit, dispatch, rootState }, { accessToken }) {
      const osType = rootState.osType()
      ncpApi.defaults.headers.accessToken = ''
      await dispatch('openid', { params: { openAccessToken: accessToken, provider: 'payco', platformType: osType } })
      cookies.set('ncpAccessToken', state.openid.accessToken, { 'max-age': state.openid.expireIn })
      cookies.set('oldAccessToken', accessToken)
      ncpApi.defaults.headers.accessToken = state.openid.accessToken
    },
    delOnlyBuywow ({ state, commit, dispatch, rootState }, reasonData) {
      return dispatch('_delOnlyBuywow', { params: { reason: reasonData } })
    },
    getDormancyInfo ({ state, commit, dispatch, rootState }) {
      return dispatch('_getDormancyInfo')
    },
    sendMailCode ({ state, commit, dispatch, rootState }, params) {
      return dispatch('_sendMailCode', { data: { email: params.email, uri: params.uri, usage: 'JOIN_URI' } })
    },
    confirmMailCode ({ state, commit, dispatch, rootState }, params) {
      return dispatch('_confirmMailCode', { params: { email: params.email, certificatedNumber: params.cn, usage: 'JOIN_URI' } }).catch(e => {
        return e.data.code
      })
    },
    passwordMailCode ({ state, commit, dispatch, rootState }, params) {
      return dispatch('_passwordMailCode', { params: { email: params.email, certificatedNumber: params.cn, usage: 'FIND_PASSWORD' } }).catch(e => {
        return e.data.code
      })
    }
  },
  mutations: {
  }
}
