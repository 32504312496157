import { getLBDiaryData } from '@/api'
import cookies from 'js-cookie'

const ENDPOINT_MEALDETAIL = 'V1/MealDetail'
const ENDPOINT_MEALHIGHFIVE = 'V1/RecordHighFiveReg'
const ENDPOINT_MEALFAVORITE = 'V1/RecordFavoriteReg'

export default {
  namespaced: true,
  state: {
    mealRecordDetail: null
  },
  actions: {
    async getImitateOrderMealDetail ({ state, commit, dispatch }, payload) {
      // 토큰 조회
      payload.uId = cookies.get('memberCummunityUID')
      payload.appType = cookies.get('appType')
      payload.appVersion = cookies.get('appVersion')
      payload.languageCode = 'ko'
      payload.countryCode = '82'
      payload.whereToUseCode = 'DIARY'
      payload.cuid = 0
      payload.challengeId = 0
      payload.utcInterval = -540

      const resp = await getLBDiaryData(ENDPOINT_MEALDETAIL, 'post', payload)
      state.mealRecordDetail = resp.data
      return resp.data
    },
    async setRecordHighFive ({ state, commit, dispatch }, payload) {
      // 토큰 조회
      payload.uId = cookies.get('memberCummunityUID')
      payload.appType = cookies.get('appType')
      payload.appVersion = cookies.get('appVersion')
      payload.languageCode = 'ko'
      payload.countryCode = '82'
      payload.whereToUseCode = 'DIARY'
      payload.cuid = 0
      payload.challengeId = 0
      payload.utcInterval = -540
      const resp = await getLBDiaryData(ENDPOINT_MEALHIGHFIVE, 'post', payload)
      return resp.data
    },
    async setRecordFavorite ({ state, commit, dispatch }, payload) {
      // 토큰 조회
      payload.uId = cookies.get('memberCummunityUID')
      payload.appType = cookies.get('appType')
      payload.appVersion = cookies.get('appVersion')
      payload.languageCode = 'ko'
      payload.countryCode = '82'
      payload.whereToUseCode = 'DIARY'
      payload.cuid = 0
      payload.challengeId = 0
      payload.utcInterval = -540

      const resp = await getLBDiaryData(ENDPOINT_MEALFAVORITE, 'post', payload)
      return resp.data
    }
  }
}
